// @flow

import React, { createRef, useRef, useEffect } from 'react';
import { SpanParagraphs } from '../textComponents/TextComponents';
import { MarkdownSyntaxRemoverFunc, hyphenReplacerFunc } from '../../../lib/utils';
import xIcon from '../../../shared_images/x-icon.png';
import './InfoBox.scss';

type InfoBoxProps = {
  isActive: boolean,
  iconName: string,
  iconNameEnglish: string,
  iconExplanation: string,
  clickHandler: * => void,
  positionStr: string,
  isSpan: boolean,
  style: null | {},
  closeButton: string,
  categoryName: null | string,
};

const InfoBox = ({
  isActive,
  iconName,
  iconNameEnglish,
  iconExplanation,
  clickHandler,
  positionStr,
  isSpan = false,
  style = null,
  closeButton,
  categoryName,
}: InfoBoxProps): React.Element<'div'> => {
  const ref = useRef(createRef());

  useEffect(() => {
    if (isActive) ref.current.focus();
  }, [isActive]);

  const clickHandlerWithPreventDefault = (e: React.SyntheticEvent<HTMLButtonElement>) => { clickHandler(); e.stopPropagation(); };

  return !isSpan ? (
    <div
      className={`info-box ${iconNameEnglish} ${isActive ? positionStr : 'no-display'}`}
      style={style}
      role="dialog"
      aria-hidden={!isActive}
      aria-labelledby={`${categoryName}-${iconNameEnglish}-heading`}
    >
      <div className="info-box-up-arrow" />
      <div className="info-box-head" id={`${categoryName}-${iconNameEnglish}-heading`}>
        {iconName}
      </div>
      <div className="info-box-body" ref={ref} tabIndex="-1">
        <SpanParagraphs paragraphArr={[iconExplanation]} />
      </div>
      <div className="info-box-down-arrow" />
      <button
        onClick={clickHandler}
        type="button"
        aria-label={closeButton}
      >
        <img src={xIcon} alt="" />
      </button>
    </div>
  ) : (
    <span
      className={`info-box ${iconNameEnglish} ${isActive ? positionStr : 'no-display'}`}
      style={style}
      role="dialog"
      aria-hidden={!isActive}
      aria-labelledby={`${categoryName}-${iconNameEnglish}-heading`}
    >
      <span className="info-box-up-arrow" />
      <span className="info-box-head" id={`${categoryName}-${iconNameEnglish}-heading`}>
        {iconName}
      </span>
      <span
        aria-label={MarkdownSyntaxRemoverFunc(hyphenReplacerFunc(`${iconName}: ${iconExplanation}`))}
        className="info-box-body"
        ref={ref}
        tabIndex="-1"
      >
        <SpanParagraphs paragraphArr={[iconExplanation]} />
      </span>
      <span className="info-box-down-arrow" />
      <button
        onClick={clickHandlerWithPreventDefault}
        type="button"
        aria-label={closeButton}
        tabIndex="0"
      >
        <img src={xIcon} alt="" />
      </button>
    </span>
  );
};

export default InfoBox;
