// @flow

import * as React from 'react';
import Animal from './Animal';
import './StudyIdImage.scss';

type studyIdImageProps = {
  userId: string,
  size: string,
};

const StudyIdImage = ({ userId, size }: studyIdImageProps): React.Element<*> => {
  const [color, animal] = userId.split('-');
  const className = size ? `study-id-${size}` : 'study-id-image';

  return (
    <div className={className}>
      <div className={color}>
        <h1>{userId}</h1>
        <Animal name={animal} />
      </div>
    </div>
  );
};

export default StudyIdImage;
