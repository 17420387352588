// @flow

export default {
  setModalInvisible: (): {} => ({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: false }),
  setModalVisible: (): {} => ({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: true }),
  modalButtonLog: (label: string, path: string): { type: string, label: string, path: string} => ({
    type: 'MODAL_BUTTON_CLICK',
    label,
    path,
  }),
};
