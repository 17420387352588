// @flow

import React from 'react';

type Props = {
  title: string,
  emptyText: string,
  redFlags: Array<string>,
};

const RedFlags = (props: Props): React.Element<'div'> => {
  const { redFlags, title, emptyText } = props;
  const body = (redFlags && redFlags.length > 0) ? redFlags.join(', ') : emptyText;

  return (
    <div className="item red-flags">
      <h2>{title}</h2>
      <div className="body">{body}</div>
    </div>
  );
};

RedFlags.displayName = 'RedFlags';

export default RedFlags;
