// @flow

import { questionConfig1 } from '../surveyMBC/Survey1MBC';
import { questionConfig2 } from '../surveyMBC/Survey2MBC';
import { questionConfig3 } from '../surveyMBC/Survey3MBC';
import { questionConfig4 } from '../surveyMBC/Survey4MBC';
import type { TableData } from '../../../lib/types';
import { isMyPath, isSuperDev } from '../../../lib/appMode';

type StateAndContent = {
  state: Object,
  content: Object,
};

export const checkTables = ({ state: { surveyStateMBC }, content }: Object): Array<TableData> => {
  const buildRows = ({ possibleValues, rowLabels, data }: Object, isFiltering: boolean = false, filterValue: string = ''): Array<Object> => (
    !isFiltering ? Object.values(data).map((value: string, i: number): Object => (
      {
        category: rowLabels && rowLabels[i],
        answer: possibleValues.indexOf(value)
      }
    )) : Object.values(data).reduce((accu: [{}], value: string, i: number): Object => (
      value === filterValue
        ? accu : [
          ...accu,
          {
            category: rowLabels && rowLabels[i],
            answer: possibleValues.indexOf(value)
          }
        ]
    ), [])
  );

  // "penile" key is unused and we need to remove it before displaying
  // the responses to this question.  It is only there to satisfy the
  // mybc_recs engine.
  const { penile: _, ...adminEnthusiasms } = surveyStateMBC.adminEnthusiasms;

  return [
    {
      caption: content.howUsedPast,
      headings: content.survey4MbcQuestions[0].choices.slice(0, -1),
      rows: buildRows({
        possibleValues: questionConfig4[0].choiceKeys,
        rowLabels: content.survey4MbcQuestions[0].categories,
        data: surveyStateMBC.feelAboutMethods
      }, true, 'never')
    },
    {
      caption: content.howMethodUsed,
      headings: content.survey2MbcQuestions[1].choices,
      rows: buildRows({
        possibleValues: questionConfig2[1].choiceKeys,
        rowLabels: content.survey2MbcQuestions[1].categories,
        data: adminEnthusiasms
      })
    },
    {
      caption: content.howOftedUsed,
      headings: content.survey1MbcQuestions[3].choices,
      rows: buildRows({
        possibleValues: questionConfig1[3].choiceKeys,
        rowLabels: content.survey1MbcQuestions[3].categories,
        data: surveyStateMBC.frequencyEnthusiasms
      })
    },
    {
      caption: content.howSideEffects,
      headings: content.survey3MbcQuestions[0].choices,
      rows: buildRows({
        possibleValues: questionConfig3[0].choiceKeys,
        rowLabels: content.survey3MbcQuestions[0].categories,
        data: surveyStateMBC.sideEffectBothers,
      })
    },
    {
      caption: content.howBenefits,
      headings: content.survey3MbcQuestions[1].choices,
      rows: buildRows({
        possibleValues: questionConfig3[1].choiceKeys,
        rowLabels: content.survey3MbcQuestions[1].categories,
        data: surveyStateMBC.benefitEnthusiasms
      }),
    }
  ];
};

const whenPregnantAnswer = ({ state: { surveyStateMBC }, content }: StateAndContent): string => {
  const timingIndex = questionConfig1[0].choiceKeys.indexOf(surveyStateMBC.timing);
  return content.survey1MbcQuestions[0].choices[timingIndex];
};

const thoughtsOnPregnancy = ({ state, content }: StateAndContent): Object => {
  if (!(isMyPath || isSuperDev)) {
    return {
      title: content.thoughtsSectionTitle,
      rows: [
        null,
        null,
        { category: content.whenPregnant, value: whenPregnantAnswer({ state, content }) },
      ]
    };
  }
  const { mypathSurveyQuestions } = content;
  const { surveyStateMP, currentMethods } = state;
  const currentMethodNames = Array.from(currentMethods).map((name: string): string => (content.methodNames[name]));

  return {
    title: content.thoughtsSectionTitle,
    rows: [
      { category: content.thoughtsOnChildren, value: mypathSurveyQuestions[1].reportAnswers[surveyStateMP[1]] },
      { category: content.thoughtsOnPregnancy, value: mypathSurveyQuestions[2].choices[surveyStateMP[2]] },
      { category: content.whenPregnant, value: whenPregnantAnswer({ state, content }) },
      { category: content.howImportantAvoidingPregnancy, value: mypathSurveyQuestions[4].choices[surveyStateMP[4]] },
      { category: content.howHappyIfPregnant, value: mypathSurveyQuestions[5].choices[surveyStateMP[5]] },
      { category: content.howUpsetIfPregnant, value: mypathSurveyQuestions[6].choices[surveyStateMP[6]] },
      { category: content.currentMethods, value: currentMethodNames.join(', ') },
      { category: content.currentMethodSatisfaction, value: mypathSurveyQuestions[7].choices[surveyStateMP[7]] },
    ],
  };
};

const notesProps = ({ state: { notesReducer: { notes } }, content: { myQuestions, noQuestions } }: StateAndContent): Object => ({
  rows: notes.split('\n\n'),
  title: myQuestions,
  emptyText: noQuestions,
});

const methodsToDiscuss = ({ state: { activeRecs }, content }: StateAndContent): Object => ({
  methods: [...activeRecs],
  title: content.methodsTitle,
  noMethods: content.methodsNone,
  methodNames: content.methodNames,
});

const importantThings = ({ state: { surveyStateMBC }, content }: StateAndContent): Object => ({
  title: content.importantThings,
  rows: [
    { category: content.effectiveness, answer: surveyStateMBC.effectiveness === 'very' },
    { category: content.howUsed, answer: surveyStateMBC.admin === 'positive' },
    { category: content.howOften, answer: surveyStateMBC.frequency === 'positive' },
  ]
});

const redFlags = ({ state: { surveyStateMBC: { concerns, migraine } }, content }: StateAndContent): Array<string> => {
  const outputSet = new Set();
  const sharedObj = {
    title: content.redFlags,
    emptyText: content.none
  };

  if (!concerns) { return { ...sharedObj, rows: Array.from(outputSet) }; }

  // Blood clots, high blood pressure show up if selected.
  if (concerns.includes('bloodclots')) outputSet.add('bloodclots');
  if (concerns.includes('bloodPressure')) outputSet.add('bloodPressure');

  // Smoking and over 35 both show if both are selected.
  if (concerns.includes('over35') && concerns.includes('smoking')) {
    outputSet.add('over35');
    outputSet.add('smoking');
  }

  // Migraines and Bright Lights selected mean show "Migraines with Aura"
  if (concerns.includes('over35') && migraine.includes('brightlights')) outputSet.add('migraineWithAura');

  return {
    ...sharedObj,
    rows: Array.from(outputSet).map((name: string): string => (content.concerns[name])),
  };
};

const sideEffectsBenefits = ({ state: { surveyStateMBC }, content }: StateAndContent): Object => {
  const sideEffectsBenefitsKeys = Object.keys(surveyStateMBC.sideEffectBothers).concat(Object.keys(surveyStateMBC.benefitEnthusiasms));

  const doWant = sideEffectsBenefitsKeys
    .filter((key: string): Array<string> => (surveyStateMBC.sideEffectBothers[key] === 'positive' || surveyStateMBC.benefitEnthusiasms[key] === 'positive'))
    .map((key: string): Array<string> => content.sideEffectNames[key])
    .filter((v: string, i: number, a: [string]): boolean => a.indexOf(v) === i)
    .join(', ');

  const dontWant = sideEffectsBenefitsKeys
    .filter((key: string): Array<string> => (surveyStateMBC.sideEffectBothers[key] === 'negative' || surveyStateMBC.benefitEnthusiasms[key] === 'negative'))
    .map((key: string): Array<string> => content.sideEffectNames[key])
    .filter((v: string, i: number, a: [string]): boolean => a.indexOf(v) === i)
    .join(', ');

  return {
    title: content.sideEffectsBenefits,
    rows: [
      { category: content.doWant, value: doWant },
      { category: content.dontWant, value: dontWant },
    ]
  };
};

const chosenTopics = ({ state: { topicReducer: { topics } }, content }: StateAndContent): Object => {
  if (!(isMyPath || isSuperDev)) return null;

  return ({
    rows: topics,
    title: content.topicsTitle,
    emptyText: content.topicsEmptyText,
  });
};

export default ({ state, content }: StateAndContent): Object => ({
  topics: chosenTopics({ state, content }),
  methodsToDiscuss: methodsToDiscuss({ state, content }),
  notes: notesProps({ state, content }),
  thoughtsOnPregnancy: thoughtsOnPregnancy({ state, content }),
  importantThings: importantThings({ state, content }),
  redFlags: redFlags({ state, content }),
  sideEffectsBenefits: sideEffectsBenefits({ state, content }),
  checkTables: checkTables({ state, content }),
});
