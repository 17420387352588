// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import MenuItem from '../../shared/menuItem/MenuItem';
import FertilityByAge from './FertilityByAge';
import './FertilityMenu.scss';
import MenstrualCycleImage from './menstrual-cycle@2x.png';
import type { PageProps } from '../../../lib/types';

type FertilityMenuProps = PageProps & {
  contents: Object,
  visitedItems: Array<string>,
};

type State = {
  visibleModal: string,
};

class FertilityMenu extends React.Component<FertilityMenuProps, State> {
  constructor(props: FertilityMenuProps) {
    super(props);
    this.state = { visibleModal: null };
  }

  showModal = (item: string) => {
    const { onModalShow } = this.props;
    this.setState({ visibleModal: item });
    if (onModalShow) { onModalShow(item); }
  };

  hideModal = () => {
    const { onModalHide } = this.props;
    this.setState({ visibleModal: null });
    if (onModalHide) { onModalHide(); }
  }

  render() {
    const { content, locale, visitedItems, isAnyModalVisible } = this.props;
    const { visibleModal } = this.state;

    const itemProps = (item: string): {item: string, question: string, modalCopy: string} => ({
      question: content[`${item}_question`],
      modalCopy: content[`${item}_modal_copy`],
      modalVisible: visibleModal === item,
      ariaHidden: isAnyModalVisible,
      onModalShow: (): void => this.showModal(item),
      onModalHide: this.hideModal,
      visited: visitedItems.includes(item),
      buttonText: 'view answer',
      content,
      locale,
    });

    return (
      <>
        <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
        <div className="menu-container">
          <MenuItem {...itemProps('menstrual')} topics={['Menstrual cycle']} className="first" isMyPath modalLabel="Answer">
            <p>{content.menstrual_extra_text}</p>
            <figure>
              <figcaption id="menstrual-cycle-caption" className="screen-reader-only">
                {content.menstrual_image_alt}
              </figcaption>
              <img
                id="menstrual-cycle-image"
                src={MenstrualCycleImage}
                alt="menstrual cycle diagram"
                aria-describedby="menstrual-cycle-caption"
              />
            </figure>
          </MenuItem>
          <MenuItem {...itemProps('skip')} topics={['Irregular Cycle']} className="second" isMyPath modalLabel="Answer" />
          <MenuItem {...itemProps('period')} topics={['Fertility']} className="third" isMyPath modalLabel="Answer" />
          <MenuItem {...itemProps('age')} topics={['Fertility changes with age']} className="fourth" isMyPath modalLabel="Answer">
            <FertilityByAge content={content.chart} />
          </MenuItem>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: StateType): State => (
  { visitedItems: state.fertilityMenuReducer.visited }
);

const mapDispatchToProps = (dispatch): Object => ({
  onModalShow: (item: string): null => {
    dispatch({ type: 'SET_FERTILITY_ITEM_VISITED', item });
    dispatch({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: true });
  },
  onModalHide: (): null => {
    dispatch({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: false });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FertilityMenu);
