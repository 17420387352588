// @flow

import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { addBreadcrumb } from '@sentry/browser';
import rootReducer from '../reducers/index';
import type { Store } from './types';

const persistConfig = {
  key: 'root',
  stateReconciler: autoMergeLevel2,
  blacklist: ['isAnyModalVisible', 'isShowingRecs'],
  storage,
};

// Sentry middleware
const sentryReporter = (): any => (next: any): any => (action: any): any => {
  const data = {};
  for (const [key, value] of Object.entries(action)) {
    if (key !== 'type') {
      data[key] = JSON.stringify(value);
    }
  }

  addBreadcrumb({
    message: action.type,
    category: 'redux action',
    level: 'info',
    data,
  });

  return next(action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, createLogger(), sentryReporter];
}

export const store: Store = createStore(persistedReducer, {}, applyMiddleware(...middleware));
export const persistor = persistStore(store);
