// @flow

import * as React from 'react';
import './EggArray.scss';

type Props = {
  num: number,
};

const arrOf100: Array<number> = Array(100).fill().map((v: any, i: number): number => i + 1);

const EggArray = ({ num }: Props): React.Element<*> => (
  <div className="egg-array-container" aria-hidden>
    {arrOf100.map((i: number): React.Element<'div'> => (
      <div className={`egg-icon ${i > num ? 'safe' : 'fertilized'}`} key={`ia-icon-${i}`} />
    ))}
  </div>
);

export default EggArray;
