// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SingleCheckboxSurveyQuestion from '../../shared/survey/SingleCheckboxSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './Survey5MBC.scss';

export const questionConfig5 = [
  { type: 'plural', questionKey: 'concerns', choiceKeys: ['bloodclots', 'bloodPressure', 'over35', 'smoking', 'migraine', 'none'] },
  { type: 'plural', questionKey: 'migraine', choiceKeys: ['brightlights', 'spots', 'numbness', 'language', 'weakness', 'none'] },
];

const Survey5MBC = ({
  locale, navLinks, surveyStateMBC, answerSurveyPluralMBC, isAnyModalVisible,
  content: { headings, paragraphs, notes, navigation, survey5MbcQuestions }
}: SurveyMBCProps): React.Node<*> => {
  const [isHidingMigraineQuestion, setIsHidingMigraineQuestion] = useState(!isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig5[0].questionKey]));

  const customAnswerSurveyPluralMBC = (questionKey: string, value: string) => {
    // console.log('questionKey', questionKey, 'value', value)
    if (questionKey === 'concerns' && value === 'migraine') {
      if (surveyStateMBC.concerns === null || !surveyStateMBC.concerns.includes('migraine')) setIsHidingMigraineQuestion(false);
      else setIsHidingMigraineQuestion(true);
    }
    if (questionKey === 'concerns' && value === 'none' && !isHidingMigraineQuestion) {
      setIsHidingMigraineQuestion(true);
    }
    answerSurveyPluralMBC(questionKey, value);
  };

  const filteredQuestionConfig5 = isHidingMigraineQuestion ? questionConfig5.slice(0, -1) : questionConfig5;
  const isNextButtonHidden = !filteredQuestionConfig5.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));

  return (
    <>
      <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
      <div className="survey-questions-container">
        <SingleCheckboxSurveyQuestion
          texts={survey5MbcQuestions[0].texts}
          choices={survey5MbcQuestions[0].choices}
          ariaHidden={isAnyModalVisible}
          selectedChoiceArr={
            // eslint-disable-next-line no-nested-ternary
            !surveyStateMBC.concerns
              ? [false, false, false, false, false, false]
              : surveyStateMBC.concerns.length > 0
                ? ['bloodclots', 'bloodPressure', 'over35', 'smoking', 'migraine', 'none'].map((concern: string): boolean => surveyStateMBC.concerns.includes(concern))
                : [false, false, false, false, false, true]
          }
          clickHandler={(choiceIndex: number) => {
            customAnswerSurveyPluralMBC('concerns', ['bloodclots', 'bloodPressure', 'over35', 'smoking', 'migraine', 'none'][choiceIndex]);
          }}
        />
        {
          (isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig5[0].questionKey]) && !isHidingMigraineQuestion) && (
            <SingleCheckboxSurveyQuestion
              texts={survey5MbcQuestions[1].texts}
              choices={survey5MbcQuestions[1].choices}
              ariaHidden={isAnyModalVisible}
              selectedChoiceArr={
                // eslint-disable-next-line no-nested-ternary
                !surveyStateMBC.migraine
                  ? [false, false, false, false, false, false]
                  : surveyStateMBC.migraine.length > 0
                    ? ['brightlights', 'spots', 'numbness', 'language', 'weakness', 'none'].map((concern: string): boolean => surveyStateMBC.migraine.includes(concern))
                    : [false, false, false, false, false, true]
              }
              clickHandler={(choiceIndex: number) => {
                customAnswerSurveyPluralMBC('migraine', ['brightlights', 'spots', 'numbness', 'language', 'weakness', 'none'][choiceIndex]);
              }}
            />
          )
        }
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        notesContent={notes}
        navigationContent={navigation}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMBC }: State): Object => ({ surveyStateMBC });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey5MBC);
