/* eslint-disable */
(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.mybc_recs = f()}})(function(){var define,module,exports;return (function(){function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s}return e})()({1:[function(require,module,exports){
// Generated by purs bundle 0.11.7
var PS = {};
(function(exports) {
    "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }
      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Semigroupoid = function (compose) {
      this.compose = compose;
  };
  var semigroupoidFn = new Semigroupoid(function (f) {
      return function (g) {
          return function (x) {
              return f(g(x));
          };
      };
  });
  var compose = function (dict) {
      return dict.compose;
  };
  exports["compose"] = compose;
  exports["Semigroupoid"] = Semigroupoid;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS["Control.Semigroupoid"] = PS["Control.Semigroupoid"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Category = function (Semigroupoid0, id) {
      this.Semigroupoid0 = Semigroupoid0;
      this.id = id;
  };
  var id = function (dict) {
      return dict.id;
  };
  var categoryFn = new Category(function () {
      return Control_Semigroupoid.semigroupoidFn;
  }, function (x) {
      return x;
  });
  exports["Category"] = Category;
  exports["id"] = id;
  exports["categoryFn"] = categoryFn;
})(PS["Control.Category"] = PS["Control.Category"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS["Data.Boolean"] = PS["Data.Boolean"] || {});
(function(exports) {
    "use strict";

  exports.refEq = function (r1) {
    return function (r2) {
      return r1 === r2;
    };
  };

  exports.eqArrayImpl = function (f) {
    return function (xs) {
      return function (ys) {
        if (xs.length !== ys.length) return false;
        for (var i = 0; i < xs.length; i++) {
          if (!f(xs[i])(ys[i])) return false;
        }
        return true;
      };
    };
  };
})(PS["Data.Eq"] = PS["Data.Eq"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Eq"];
  var Data_Unit = PS["Data.Unit"];
  var Data_Void = PS["Data.Void"];
  var Eq = function (eq) {
      this.eq = eq;
  };
  var eqInt = new Eq($foreign.refEq);
  var eqBoolean = new Eq($foreign.refEq);
  var eq = function (dict) {
      return dict.eq;
  };
  var eqArray = function (dictEq) {
      return new Eq($foreign.eqArrayImpl(eq(dictEq)));
  };
  var notEq = function (dictEq) {
      return function (x) {
          return function (y) {
              return eq(eqBoolean)(eq(dictEq)(x)(y))(false);
          };
      };
  };
  exports["Eq"] = Eq;
  exports["eq"] = eq;
  exports["notEq"] = notEq;
  exports["eqBoolean"] = eqBoolean;
  exports["eqInt"] = eqInt;
  exports["eqArray"] = eqArray;
})(PS["Data.Eq"] = PS["Data.Eq"] || {});
(function(exports) {
    "use strict";

  exports.unsafeCompareImpl = function (lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };
})(PS["Data.Ord.Unsafe"] = PS["Data.Ord.Unsafe"] || {});
(function(exports) {
    "use strict";

  exports.concatArray = function (xs) {
    return function (ys) {
      if (xs.length === 0) return ys;
      if (ys.length === 0) return xs;
      return xs.concat(ys);
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Semigroup"];
  var Data_Unit = PS["Data.Unit"];
  var Data_Void = PS["Data.Void"];
  var Semigroup = function (append) {
      this.append = append;
  };
  var semigroupArray = new Semigroup($foreign.concatArray);
  var append = function (dict) {
      return dict.append;
  };
  exports["Semigroup"] = Semigroup;
  exports["append"] = append;
  exports["semigroupArray"] = semigroupArray;
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});
(function(exports) {
    "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showStringImpl = function (s) {
    var l = s.length;
    return "\"" + s.replace(
      /[\0-\x1F\x7F"\\]/g, // eslint-disable-line no-control-regex
      function (c, i) {
        switch (c) {
          case "\"":
          case "\\":
            return "\\" + c;
          case "\x07": return "\\a";
          case "\b": return "\\b";
          case "\f": return "\\f";
          case "\n": return "\\n";
          case "\r": return "\\r";
          case "\t": return "\\t";
          case "\v": return "\\v";
        }
        var k = i + 1;
        var empty = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
        return "\\" + c.charCodeAt(0).toString(10) + empty;
      }
    ) + "\"";
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Show"];
  var Show = function (show) {
      this.show = show;
  };
  var showString = new Show($foreign.showStringImpl);
  var showInt = new Show($foreign.showIntImpl);
  var show = function (dict) {
      return dict.show;
  };
  exports["Show"] = Show;
  exports["show"] = show;
  exports["showInt"] = showInt;
  exports["showString"] = showString;
})(PS["Data.Show"] = PS["Data.Show"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Eq = PS["Data.Eq"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var LT = (function () {
      function LT() {

      };
      LT.value = new LT();
      return LT;
  })();
  var GT = (function () {
      function GT() {

      };
      GT.value = new GT();
      return GT;
  })();
  var EQ = (function () {
      function EQ() {

      };
      EQ.value = new EQ();
      return EQ;
  })();
  var invert = function (v) {
      if (v instanceof GT) {
          return LT.value;
      };
      if (v instanceof EQ) {
          return EQ.value;
      };
      if (v instanceof LT) {
          return GT.value;
      };
      throw new Error("Failed pattern match at Data.Ordering line 33, column 1 - line 33, column 31: " + [ v.constructor.name ]);
  };
  var eqOrdering = new Data_Eq.Eq(function (v) {
      return function (v1) {
          if (v instanceof LT && v1 instanceof LT) {
              return true;
          };
          if (v instanceof GT && v1 instanceof GT) {
              return true;
          };
          if (v instanceof EQ && v1 instanceof EQ) {
              return true;
          };
          return false;
      };
  });
  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
  exports["invert"] = invert;
  exports["eqOrdering"] = eqOrdering;
})(PS["Data.Ordering"] = PS["Data.Ordering"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Ord.Unsafe"];
  var Data_Ordering = PS["Data.Ordering"];
  var unsafeCompare = $foreign.unsafeCompareImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value);
  exports["unsafeCompare"] = unsafeCompare;
})(PS["Data.Ord.Unsafe"] = PS["Data.Ord.Unsafe"] || {});
(function(exports) {
    "use strict";

  exports.intAdd = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x + y | 0;
    };
  };

  exports.intMul = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x * y | 0;
    };
  };
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Semiring"];
  var Data_Unit = PS["Data.Unit"];
  var Semiring = function (add, mul, one, zero) {
      this.add = add;
      this.mul = mul;
      this.one = one;
      this.zero = zero;
  };
  var zero = function (dict) {
      return dict.zero;
  };
  var semiringInt = new Semiring($foreign.intAdd, $foreign.intMul, 1, 0);
  var one = function (dict) {
      return dict.one;
  };
  var mul = function (dict) {
      return dict.mul;
  };
  var add = function (dict) {
      return dict.add;
  };
  exports["Semiring"] = Semiring;
  exports["add"] = add;
  exports["zero"] = zero;
  exports["mul"] = mul;
  exports["one"] = one;
  exports["semiringInt"] = semiringInt;
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Ord"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Ord_Unsafe = PS["Data.Ord.Unsafe"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Unit = PS["Data.Unit"];
  var Data_Void = PS["Data.Void"];
  var Ord = function (Eq0, compare) {
      this.Eq0 = Eq0;
      this.compare = compare;
  };
  var ordInt = new Ord(function () {
      return Data_Eq.eqInt;
  }, Data_Ord_Unsafe.unsafeCompare);
  var compare = function (dict) {
      return dict.compare;
  };
  var comparing = function (dictOrd) {
      return function (f) {
          return function (x) {
              return function (y) {
                  return compare(dictOrd)(f(x))(f(y));
              };
          };
      };
  };
  var max = function (dictOrd) {
      return function (x) {
          return function (y) {
              var v = compare(dictOrd)(x)(y);
              if (v instanceof Data_Ordering.LT) {
                  return y;
              };
              if (v instanceof Data_Ordering.EQ) {
                  return x;
              };
              if (v instanceof Data_Ordering.GT) {
                  return x;
              };
              throw new Error("Failed pattern match at Data.Ord line 122, column 3 - line 125, column 12: " + [ v.constructor.name ]);
          };
      };
  };
  exports["Ord"] = Ord;
  exports["compare"] = compare;
  exports["comparing"] = comparing;
  exports["max"] = max;
  exports["ordInt"] = ordInt;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Category = PS["Control.Category"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var flip = function (f) {
      return function (b) {
          return function (a) {
              return f(a)(b);
          };
      };
  };
  var $$const = function (a) {
      return function (v) {
          return a;
      };
  };
  exports["flip"] = flip;
  exports["const"] = $$const;
})(PS["Data.Function"] = PS["Data.Function"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Functor"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Unit = PS["Data.Unit"];
  var Functor = function (map) {
      this.map = map;
  };
  var map = function (dict) {
      return dict.map;
  };
  var functorFn = new Functor(Control_Semigroupoid.compose(Control_Semigroupoid.semigroupoidFn));
  var functorArray = new Functor($foreign.arrayMap);
  exports["Functor"] = Functor;
  exports["map"] = map;
  exports["functorFn"] = functorFn;
  exports["functorArray"] = functorArray;
})(PS["Data.Functor"] = PS["Data.Functor"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Functor = PS["Data.Functor"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Alt = function (Functor0, alt) {
      this.Functor0 = Functor0;
      this.alt = alt;
  };
  var alt = function (dict) {
      return dict.alt;
  };
  exports["Alt"] = Alt;
  exports["alt"] = alt;
})(PS["Control.Alt"] = PS["Control.Alt"] || {});
(function(exports) {
    "use strict";

  exports.arrayApply = function (fs) {
    return function (xs) {
      var l = fs.length;
      var k = xs.length;
      var result = new Array(l*k);
      var n = 0;
      for (var i = 0; i < l; i++) {
        var f = fs[i];
        for (var j = 0; j < k; j++) {
          result[n++] = f(xs[j]);
        }
      }
      return result;
    };
  };
})(PS["Control.Apply"] = PS["Control.Apply"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Apply = function (Functor0, apply) {
      this.Functor0 = Functor0;
      this.apply = apply;
  };
  var applyArray = new Apply(function () {
      return Data_Functor.functorArray;
  }, $foreign.arrayApply);
  var apply = function (dict) {
      return dict.apply;
  };
  exports["Apply"] = Apply;
  exports["apply"] = apply;
  exports["applyArray"] = applyArray;
})(PS["Control.Apply"] = PS["Control.Apply"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Apply = PS["Control.Apply"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];
  var Applicative = function (Apply0, pure) {
      this.Apply0 = Apply0;
      this.pure = pure;
  };
  var pure = function (dict) {
      return dict.pure;
  };
  var applicativeArray = new Applicative(function () {
      return Control_Apply.applyArray;
  }, function (x) {
      return [ x ];
  });
  exports["Applicative"] = Applicative;
  exports["pure"] = pure;
  exports["applicativeArray"] = applicativeArray;
})(PS["Control.Applicative"] = PS["Control.Applicative"] || {});
(function(exports) {
    "use strict";

  exports.arrayBind = function (arr) {
    return function (f) {
      var result = [];
      for (var i = 0, l = arr.length; i < l; i++) {
        Array.prototype.push.apply(result, f(arr[i]));
      }
      return result;
    };
  };
})(PS["Control.Bind"] = PS["Control.Bind"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Control.Bind"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];
  var Bind = function (Apply0, bind) {
      this.Apply0 = Apply0;
      this.bind = bind;
  };
  var bindArray = new Bind(function () {
      return Control_Apply.applyArray;
  }, $foreign.arrayBind);
  var bind = function (dict) {
      return dict.bind;
  };
  var bindFlipped = function (dictBind) {
      return Data_Function.flip(bind(dictBind));
  };
  var composeKleisliFlipped = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bindFlipped(dictBind)(f)(g(a));
              };
          };
      };
  };
  var join = function (dictBind) {
      return function (m) {
          return bind(dictBind)(m)(Control_Category.id(Control_Category.categoryFn));
      };
  };
  exports["Bind"] = Bind;
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["join"] = join;
  exports["composeKleisliFlipped"] = composeKleisliFlipped;
  exports["bindArray"] = bindArray;
})(PS["Control.Bind"] = PS["Control.Bind"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];
  var Monad = function (Applicative0, Bind1) {
      this.Applicative0 = Applicative0;
      this.Bind1 = Bind1;
  };
  var ap = function (dictMonad) {
      return function (f) {
          return function (a) {
              return Control_Bind.bind(dictMonad.Bind1())(f)(function (v) {
                  return Control_Bind.bind(dictMonad.Bind1())(a)(function (v1) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(v(v1));
                  });
              });
          };
      };
  };
  exports["Monad"] = Monad;
  exports["ap"] = ap;
})(PS["Control.Monad"] = PS["Control.Monad"] || {});
(function(exports) {
    "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }
        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }
        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Data_Functor = PS["Data.Functor"];
  var Plus = function (Alt0, empty) {
      this.Alt0 = Alt0;
      this.empty = empty;
  };
  var empty = function (dict) {
      return dict.empty;
  };
  exports["Plus"] = Plus;
  exports["empty"] = empty;
})(PS["Control.Plus"] = PS["Control.Plus"] || {});
(function(exports) {
    "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.HeytingAlgebra"];
  var Data_Unit = PS["Data.Unit"];
  var HeytingAlgebra = function (conj, disj, ff, implies, not, tt) {
      this.conj = conj;
      this.disj = disj;
      this.ff = ff;
      this.implies = implies;
      this.not = not;
      this.tt = tt;
  };
  var tt = function (dict) {
      return dict.tt;
  };
  var not = function (dict) {
      return dict.not;
  };
  var implies = function (dict) {
      return dict.implies;
  };
  var ff = function (dict) {
      return dict.ff;
  };
  var disj = function (dict) {
      return dict.disj;
  };
  var heytingAlgebraBoolean = new HeytingAlgebra($foreign.boolConj, $foreign.boolDisj, false, function (a) {
      return function (b) {
          return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
      };
  }, $foreign.boolNot, true);
  var conj = function (dict) {
      return dict.conj;
  };
  var heytingAlgebraFunction = function (dictHeytingAlgebra) {
      return new HeytingAlgebra(function (f) {
          return function (g) {
              return function (a) {
                  return conj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (g) {
              return function (a) {
                  return disj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (v) {
          return ff(dictHeytingAlgebra);
      }, function (f) {
          return function (g) {
              return function (a) {
                  return implies(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (a) {
              return not(dictHeytingAlgebra)(f(a));
          };
      }, function (v) {
          return tt(dictHeytingAlgebra);
      });
  };
  exports["HeytingAlgebra"] = HeytingAlgebra;
  exports["tt"] = tt;
  exports["ff"] = ff;
  exports["implies"] = implies;
  exports["conj"] = conj;
  exports["disj"] = disj;
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
  exports["heytingAlgebraFunction"] = heytingAlgebraFunction;
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Function = PS["Data.Function"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var Monoid = function (Semigroup0, mempty) {
      this.Semigroup0 = Semigroup0;
      this.mempty = mempty;
  };
  var mempty = function (dict) {
      return dict.mempty;
  };
  exports["Monoid"] = Monoid;
  exports["mempty"] = mempty;
})(PS["Data.Monoid"] = PS["Data.Monoid"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var Nothing = (function () {
      function Nothing() {

      };
      Nothing.value = new Nothing();
      return Nothing;
  })();
  var Just = (function () {
      function Just(value0) {
          this.value0 = value0;
      };
      Just.create = function (value0) {
          return new Just(value0);
      };
      return Just;
  })();
  var maybe = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Nothing) {
                  return v;
              };
              if (v2 instanceof Just) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Maybe line 219, column 1 - line 219, column 51: " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Just) {
              return new Just(v(v1.value0));
          };
          return Nothing.value;
      };
  });
  var fromMaybe = function (a) {
      return maybe(a)(Control_Category.id(Control_Category.categoryFn));
  };
  var fromJust = function (dictPartial) {
      return function (v) {
          var __unused = function (dictPartial1) {
              return function ($dollar35) {
                  return $dollar35;
              };
          };
          return __unused(dictPartial)((function () {
              if (v instanceof Just) {
                  return v.value0;
              };
              throw new Error("Failed pattern match at Data.Maybe line 270, column 1 - line 270, column 46: " + [ v.constructor.name ]);
          })());
      };
  };
  var applyMaybe = new Control_Apply.Apply(function () {
      return functorMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return Data_Functor.map(functorMaybe)(v.value0)(v1);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe line 68, column 1 - line 68, column 35: " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var applicativeMaybe = new Control_Applicative.Applicative(function () {
      return applyMaybe;
  }, Just.create);
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["isJust"] = isJust;
  exports["fromJust"] = fromJust;
  exports["functorMaybe"] = functorMaybe;
  exports["applyMaybe"] = applyMaybe;
  exports["applicativeMaybe"] = applicativeMaybe;
})(PS["Data.Maybe"] = PS["Data.Maybe"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Prelude = PS["Prelude"];
  var Newtype = function (unwrap, wrap) {
      this.unwrap = unwrap;
      this.wrap = wrap;
  };
  var wrap = function (dict) {
      return dict.wrap;
  };
  var unwrap = function (dict) {
      return dict.unwrap;
  };
  var over = function (dictNewtype) {
      return function (dictNewtype1) {
          return function (v) {
              return function (f) {
                  return function ($62) {
                      return wrap(dictNewtype1)(f(unwrap(dictNewtype)($62)));
                  };
              };
          };
      };
  };
  var alaF = function (dictFunctor) {
      return function (dictFunctor1) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (v) {
                      return function (f) {
                          return function ($64) {
                              return Data_Functor.map(dictFunctor1)(unwrap(dictNewtype1))(f(Data_Functor.map(dictFunctor)(wrap(dictNewtype))($64)));
                          };
                      };
                  };
              };
          };
      };
  };
  exports["unwrap"] = unwrap;
  exports["wrap"] = wrap;
  exports["Newtype"] = Newtype;
  exports["alaF"] = alaF;
  exports["over"] = over;
})(PS["Data.Newtype"] = PS["Data.Newtype"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Comonad = PS["Control.Comonad"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];
  var Disj = function (x) {
      return x;
  };
  var semigroupDisj = function (dictHeytingAlgebra) {
      return new Data_Semigroup.Semigroup(function (v) {
          return function (v1) {
              return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
          };
      });
  };
  var newtypeDisj = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Disj);
  var monoidDisj = function (dictHeytingAlgebra) {
      return new Data_Monoid.Monoid(function () {
          return semigroupDisj(dictHeytingAlgebra);
      }, Data_HeytingAlgebra.ff(dictHeytingAlgebra));
  };
  exports["Disj"] = Disj;
  exports["newtypeDisj"] = newtypeDisj;
  exports["semigroupDisj"] = semigroupDisj;
  exports["monoidDisj"] = monoidDisj;
})(PS["Data.Monoid.Disj"] = PS["Data.Monoid.Disj"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Foldable"];
  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Maybe_First = PS["Data.Maybe.First"];
  var Data_Maybe_Last = PS["Data.Maybe.Last"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Monoid_Additive = PS["Data.Monoid.Additive"];
  var Data_Monoid_Conj = PS["Data.Monoid.Conj"];
  var Data_Monoid_Disj = PS["Data.Monoid.Disj"];
  var Data_Monoid_Dual = PS["Data.Monoid.Dual"];
  var Data_Monoid_Endo = PS["Data.Monoid.Endo"];
  var Data_Monoid_Multiplicative = PS["Data.Monoid.Multiplicative"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var Foldable = function (foldMap, foldl, foldr) {
      this.foldMap = foldMap;
      this.foldl = foldl;
      this.foldr = foldr;
  };
  var foldr = function (dict) {
      return dict.foldr;
  };
  var foldl = function (dict) {
      return dict.foldl;
  };
  var sum = function (dictFoldable) {
      return function (dictSemiring) {
          return foldl(dictFoldable)(Data_Semiring.add(dictSemiring))(Data_Semiring.zero(dictSemiring));
      };
  };
  var foldMapDefaultR = function (dictFoldable) {
      return function (dictMonoid) {
          return function (f) {
              return foldr(dictFoldable)(function (x) {
                  return function (acc) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableArray = new Foldable(function (dictMonoid) {
      return foldMapDefaultR(foldableArray)(dictMonoid);
  }, $foreign.foldlArray, $foreign.foldrArray);
  var foldMap = function (dict) {
      return dict.foldMap;
  };
  var any = function (dictFoldable) {
      return function (dictHeytingAlgebra) {
          return Data_Newtype.alaF(Data_Functor.functorFn)(Data_Functor.functorFn)(Data_Monoid_Disj.newtypeDisj)(Data_Monoid_Disj.newtypeDisj)(Data_Monoid_Disj.Disj)(foldMap(dictFoldable)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra)));
      };
  };
  var elem = function (dictFoldable) {
      return function (dictEq) {
          return function ($199) {
              return any(dictFoldable)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Eq.eq(dictEq)($199));
          };
      };
  };
  exports["Foldable"] = Foldable;
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldMap"] = foldMap;
  exports["foldMapDefaultR"] = foldMapDefaultR;
  exports["any"] = any;
  exports["sum"] = sum;
  exports["elem"] = elem;
  exports["foldableArray"] = foldableArray;
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});
(function(exports) {
    "use strict";

  // jshint maxparams: 3

  exports.traverseArrayImpl = function () {
    function Cont(fn) {
      this.fn = fn;
    }

    var emptyList = {};

    var ConsCell = function (head, tail) {
      this.head = head;
      this.tail = tail;
    };

    function consList(x) {
      return function (xs) {
        return new ConsCell(x, xs);
      };
    }

    function listToArray(list) {
      var arr = [];
      var xs = list;
      while (xs !== emptyList) {
        arr.push(xs.head);
        xs = xs.tail;
      }
      return arr;
    }

    return function (apply) {
      return function (map) {
        return function (pure) {
          return function (f) {
            var buildFrom = function (x, ys) {
              return apply(map(consList)(f(x)))(ys);
            };

            var go = function (acc, currentLen, xs) {
              if (currentLen === 0) {
                return acc;
              } else {
                var last = xs[currentLen - 1];
                return new Cont(function () {
                  return go(buildFrom(last, acc), currentLen - 1, xs);
                });
              }
            };

            return function (array) {
              var result = go(pure(emptyList), array.length, array);
              while (result instanceof Cont) {
                result = result.fn();
              }

              return map(listToArray)(result);
            };
          };
        };
      };
    };
  }();
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Traversable"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Maybe_First = PS["Data.Maybe.First"];
  var Data_Maybe_Last = PS["Data.Maybe.Last"];
  var Data_Monoid_Additive = PS["Data.Monoid.Additive"];
  var Data_Monoid_Conj = PS["Data.Monoid.Conj"];
  var Data_Monoid_Disj = PS["Data.Monoid.Disj"];
  var Data_Monoid_Dual = PS["Data.Monoid.Dual"];
  var Data_Monoid_Multiplicative = PS["Data.Monoid.Multiplicative"];
  var Data_Traversable_Accum = PS["Data.Traversable.Accum"];
  var Data_Traversable_Accum_Internal = PS["Data.Traversable.Accum.Internal"];
  var Prelude = PS["Prelude"];
  var Traversable = function (Foldable1, Functor0, sequence, traverse) {
      this.Foldable1 = Foldable1;
      this.Functor0 = Functor0;
      this.sequence = sequence;
      this.traverse = traverse;
  };
  var traverse = function (dict) {
      return dict.traverse;
  };
  var sequenceDefault = function (dictTraversable) {
      return function (dictApplicative) {
          return traverse(dictTraversable)(dictApplicative)(Control_Category.id(Control_Category.categoryFn));
      };
  };
  var traversableArray = new Traversable(function () {
      return Data_Foldable.foldableArray;
  }, function () {
      return Data_Functor.functorArray;
  }, function (dictApplicative) {
      return sequenceDefault(traversableArray)(dictApplicative);
  }, function (dictApplicative) {
      return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map((dictApplicative.Apply0()).Functor0()))(Control_Applicative.pure(dictApplicative));
  });
  var sequence = function (dict) {
      return dict.sequence;
  };
  exports["Traversable"] = Traversable;
  exports["traverse"] = traverse;
  exports["sequence"] = sequence;
  exports["sequenceDefault"] = sequenceDefault;
  exports["traversableArray"] = traversableArray;
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Bifoldable = PS["Data.Bifoldable"];
  var Data_Bifunctor = PS["Data.Bifunctor"];
  var Data_Bitraversable = PS["Data.Bitraversable"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Prelude = PS["Prelude"];
  var Left = (function () {
      function Left(value0) {
          this.value0 = value0;
      };
      Left.create = function (value0) {
          return new Left(value0);
      };
      return Left;
  })();
  var Right = (function () {
      function Right(value0) {
          this.value0 = value0;
      };
      Right.create = function (value0) {
          return new Right(value0);
      };
      return Right;
  })();
  var functorEither = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Left) {
              return new Left(v1.value0);
          };
          if (v1 instanceof Right) {
              return new Right(v(v1.value0));
          };
          throw new Error("Failed pattern match at Data.Either line 36, column 1 - line 36, column 45: " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var either = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return v(v2.value0);
              };
              if (v2 instanceof Right) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Either line 229, column 1 - line 229, column 64: " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["functorEither"] = functorEither;
})(PS["Data.Either"] = PS["Data.Either"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var MonadThrow = function (Monad0, throwError) {
      this.Monad0 = Monad0;
      this.throwError = throwError;
  };
  var throwError = function (dict) {
      return dict.throwError;
  };
  exports["throwError"] = throwError;
  exports["MonadThrow"] = MonadThrow;
})(PS["Control.Monad.Error.Class"] = PS["Control.Monad.Error.Class"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Monad = PS["Control.Monad"];
  var Control_Monad_Cont_Class = PS["Control.Monad.Cont.Class"];
  var Control_Monad_Eff_Class = PS["Control.Monad.Eff.Class"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Reader_Class = PS["Control.Monad.Reader.Class"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Monad_State_Class = PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = PS["Control.Monad.Trans.Class"];
  var Control_Monad_Writer_Class = PS["Control.Monad.Writer.Class"];
  var Control_MonadPlus = PS["Control.MonadPlus"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Tuple = PS["Data.Tuple"];
  var Prelude = PS["Prelude"];
  var ExceptT = function (x) {
      return x;
  };
  var withExceptT = function (dictFunctor) {
      return function (f) {
          return function (v) {
              var mapLeft = function (v1) {
                  return function (v2) {
                      if (v2 instanceof Data_Either.Right) {
                          return new Data_Either.Right(v2.value0);
                      };
                      if (v2 instanceof Data_Either.Left) {
                          return new Data_Either.Left(v1(v2.value0));
                      };
                      throw new Error("Failed pattern match at Control.Monad.Except.Trans line 44, column 3 - line 44, column 32: " + [ v1.constructor.name, v2.constructor.name ]);
                  };
              };
              return ExceptT(Data_Functor.map(dictFunctor)(mapLeft(f))(v));
          };
      };
  };
  var runExceptT = function (v) {
      return v;
  };
  var mapExceptT = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var functorExceptT = function (dictFunctor) {
      return new Data_Functor.Functor(function (f) {
          return mapExceptT(Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Either.functorEither)(f)));
      });
  };
  var monadExceptT = function (dictMonad) {
      return new Control_Monad.Monad(function () {
          return applicativeExceptT(dictMonad);
      }, function () {
          return bindExceptT(dictMonad);
      });
  };
  var bindExceptT = function (dictMonad) {
      return new Control_Bind.Bind(function () {
          return applyExceptT(dictMonad);
      }, function (v) {
          return function (k) {
              return Control_Bind.bind(dictMonad.Bind1())(v)(Data_Either.either(function ($97) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Left.create($97));
              })(function (a) {
                  var v1 = k(a);
                  return v1;
              }));
          };
      });
  };
  var applyExceptT = function (dictMonad) {
      return new Control_Apply.Apply(function () {
          return functorExceptT(((dictMonad.Bind1()).Apply0()).Functor0());
      }, Control_Monad.ap(monadExceptT(dictMonad)));
  };
  var applicativeExceptT = function (dictMonad) {
      return new Control_Applicative.Applicative(function () {
          return applyExceptT(dictMonad);
      }, function ($98) {
          return ExceptT(Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Right.create($98)));
      });
  };
  var monadThrowExceptT = function (dictMonad) {
      return new Control_Monad_Error_Class.MonadThrow(function () {
          return monadExceptT(dictMonad);
      }, function ($102) {
          return ExceptT(Control_Applicative.pure(dictMonad.Applicative0())(Data_Either.Left.create($102)));
      });
  };
  exports["ExceptT"] = ExceptT;
  exports["runExceptT"] = runExceptT;
  exports["withExceptT"] = withExceptT;
  exports["mapExceptT"] = mapExceptT;
  exports["functorExceptT"] = functorExceptT;
  exports["applyExceptT"] = applyExceptT;
  exports["applicativeExceptT"] = applicativeExceptT;
  exports["bindExceptT"] = bindExceptT;
  exports["monadExceptT"] = monadExceptT;
  exports["monadThrowExceptT"] = monadThrowExceptT;
})(PS["Control.Monad.Except.Trans"] = PS["Control.Monad.Except.Trans"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Comonad = PS["Control.Comonad"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad = PS["Control.Monad"];
  var Data_BooleanAlgebra = PS["Data.BooleanAlgebra"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_CommutativeRing = PS["Data.CommutativeRing"];
  var Data_Eq = PS["Data.Eq"];
  var Data_EuclideanRing = PS["Data.EuclideanRing"];
  var Data_Field = PS["Data.Field"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Prelude = PS["Prelude"];
  var Identity = function (x) {
      return x;
  };
  var newtypeIdentity = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Identity);
  var functorIdentity = new Data_Functor.Functor(function (f) {
      return function (v) {
          return f(v);
      };
  });
  var applyIdentity = new Control_Apply.Apply(function () {
      return functorIdentity;
  }, function (v) {
      return function (v1) {
          return v(v1);
      };
  });
  var bindIdentity = new Control_Bind.Bind(function () {
      return applyIdentity;
  }, function (v) {
      return function (f) {
          return f(v);
      };
  });
  var applicativeIdentity = new Control_Applicative.Applicative(function () {
      return applyIdentity;
  }, Identity);
  var monadIdentity = new Control_Monad.Monad(function () {
      return applicativeIdentity;
  }, function () {
      return bindIdentity;
  });
  exports["Identity"] = Identity;
  exports["newtypeIdentity"] = newtypeIdentity;
  exports["functorIdentity"] = functorIdentity;
  exports["applyIdentity"] = applyIdentity;
  exports["applicativeIdentity"] = applicativeIdentity;
  exports["bindIdentity"] = bindIdentity;
  exports["monadIdentity"] = monadIdentity;
})(PS["Data.Identity"] = PS["Data.Identity"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Newtype = PS["Data.Newtype"];
  var Prelude = PS["Prelude"];
  var withExcept = Control_Monad_Except_Trans.withExceptT(Data_Identity.functorIdentity);
  var runExcept = function ($0) {
      return Data_Newtype.unwrap(Data_Identity.newtypeIdentity)(Control_Monad_Except_Trans.runExceptT($0));
  };
  exports["runExcept"] = runExcept;
  exports["withExcept"] = withExcept;
})(PS["Control.Monad.Except"] = PS["Control.Monad.Except"] || {});
(function(exports) {
    "use strict";

  exports.fromFoldableImpl = (function () {
    function Cons(head, tail) {
      this.head = head;
      this.tail = tail;
    }
    var emptyList = {};

    function curryCons(head) {
      return function (tail) {
        return new Cons(head, tail);
      };
    }

    function listToArray(list) {
      var result = [];
      var count = 0;
      var xs = list;
      while (xs !== emptyList) {
        result[count++] = xs.head;
        xs = xs.tail;
      }
      return result;
    }

    return function (foldr) {
      return function (xs) {
        return listToArray(foldr(curryCons)(emptyList)(xs));
      };
    };
  })();

  //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.length = function (xs) {
    return xs.length;
  };

  //------------------------------------------------------------------------------
  // Extending arrays ------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.cons = function (e) {
    return function (l) {
      return [e].concat(l);
    };
  };

  //------------------------------------------------------------------------------
  // Non-indexed reads -----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports["uncons'"] = function (empty) {
    return function (next) {
      return function (xs) {
        return xs.length === 0 ? empty({}) : next(xs[0])(xs.slice(1));
      };
    };
  };

  //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing :  just(xs[i]);
        };
      };
    };
  };

  exports.findIndexImpl = function (just) {
    return function (nothing) {
      return function (f) {
        return function (xs) {
          for (var i = 0, l = xs.length; i < l; i++) {
            if (f(xs[i])) return just(i);
          }
          return nothing;
        };
      };
    };
  };

  exports._deleteAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (l) {
          if (i < 0 || i >= l.length) return nothing;
          var l1 = l.slice();
          l1.splice(i, 1);
          return just(l1);
        };
      };
    };
  };

  exports.concat = function (xss) {
    if (xss.length <= 10000) {
      // This method is faster, but it crashes on big arrays.
      // So we use it when can and fallback to simple variant otherwise.
      return Array.prototype.concat.apply([], xss);
    }

    var result = [];
    for (var i = 0, l = xss.length; i < l; i++) {
      var xs = xss[i];
      for (var j = 0, m = xs.length; j < m; j++) {
        result.push(xs[j]);
      }
    }
    return result;
  };

  exports.filter = function (f) {
    return function (xs) {
      return xs.filter(f);
    };
  };

  //------------------------------------------------------------------------------
  // Sorting ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.sortImpl = function (f) {
    return function (l) {
      return l.slice().sort(function (x, y) {
        return f(x)(y);
      });
    };
  };

  //------------------------------------------------------------------------------
  // Subarrays -------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.slice = function (s) {
    return function (e) {
      return function (l) {
        return l.slice(s, e);
      };
    };
  };

  exports.take = function (n) {
    return function (l) {
      return n < 1 ? [] : l.slice(0, n);
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Data_Unfoldable1 = PS["Data.Unfoldable1"];
  var Prelude = PS["Prelude"];
  var NonEmpty = (function () {
      function NonEmpty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      NonEmpty.create = function (value0) {
          return function (value1) {
              return new NonEmpty(value0, value1);
          };
      };
      return NonEmpty;
  })();
  var singleton = function (dictPlus) {
      return function (a) {
          return new NonEmpty(a, Control_Plus.empty(dictPlus));
      };
  };
  var functorNonEmpty = function (dictFunctor) {
      return new Data_Functor.Functor(function (f) {
          return function (v) {
              return new NonEmpty(f(v.value0), Data_Functor.map(dictFunctor)(f)(v.value1));
          };
      });
  };
  var foldableNonEmpty = function (dictFoldable) {
      return new Data_Foldable.Foldable(function (dictMonoid) {
          return function (f) {
              return function (v) {
                  return Data_Semigroup.append(dictMonoid.Semigroup0())(f(v.value0))(Data_Foldable.foldMap(dictFoldable)(dictMonoid)(f)(v.value1));
              };
          };
      }, function (f) {
          return function (b) {
              return function (v) {
                  return Data_Foldable.foldl(dictFoldable)(f)(f(b)(v.value0))(v.value1);
              };
          };
      }, function (f) {
          return function (b) {
              return function (v) {
                  return f(v.value0)(Data_Foldable.foldr(dictFoldable)(f)(b)(v.value1));
              };
          };
      });
  };
  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
  exports["functorNonEmpty"] = functorNonEmpty;
  exports["foldableNonEmpty"] = foldableNonEmpty;
})(PS["Data.NonEmpty"] = PS["Data.NonEmpty"] || {});
(function(exports) {
    "use strict";

  // module Partial.Unsafe

  exports.unsafePartial = function (f) {
    return f();
  };
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});
(function(exports) {
    "use strict";

  // module Partial

  exports.crashWith = function () {
    return function (msg) {
      throw new Error(msg);
    };
  };
})(PS["Partial"] = PS["Partial"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Partial"];
  exports["crashWith"] = $foreign.crashWith;
})(PS["Partial"] = PS["Partial"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Partial.Unsafe"];
  var Partial = PS["Partial"];
  var unsafeCrashWith = function (msg) {
      return $foreign.unsafePartial(function (dictPartial) {
          return Partial.crashWith(dictPartial)(msg);
      });
  };
  exports["unsafeCrashWith"] = unsafeCrashWith;
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Array"];
  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad_Eff = PS["Control.Monad.Eff"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Monad_ST = PS["Control.Monad.ST"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array_ST = PS["Data.Array.ST"];
  var Data_Array_ST_Iterator = PS["Data.Array.ST.Iterator"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var uncons = $foreign["uncons'"](Data_Function["const"](Data_Maybe.Nothing.value))(function (x) {
      return function (xs) {
          return new Data_Maybe.Just({
              head: x,
              tail: xs
          });
      };
  });
  var tail = $foreign["uncons'"](Data_Function["const"](Data_Maybe.Nothing.value))(function (v) {
      return function (xs) {
          return new Data_Maybe.Just(xs);
      };
  });
  var sortBy = function (comp) {
      return function (xs) {
          var comp$prime = function (x) {
              return function (y) {
                  var v = comp(x)(y);
                  if (v instanceof Data_Ordering.GT) {
                      return 1;
                  };
                  if (v instanceof Data_Ordering.EQ) {
                      return 0;
                  };
                  if (v instanceof Data_Ordering.LT) {
                      return -1 | 0;
                  };
                  throw new Error("Failed pattern match at Data.Array line 698, column 15 - line 703, column 1: " + [ v.constructor.name ]);
              };
          };
          return $foreign.sortImpl(comp$prime)(xs);
      };
  };
  var sortWith = function (dictOrd) {
      return function (f) {
          return sortBy(Data_Ord.comparing(dictOrd)(f));
      };
  };
  var nubBy = function (eq) {
      return function (xs) {
          var v = uncons(xs);
          if (v instanceof Data_Maybe.Just) {
              return $foreign.cons(v.value0.head)(nubBy(eq)($foreign.filter(function (y) {
                  return !eq(v.value0.head)(y);
              })(v.value0.tail)));
          };
          if (v instanceof Data_Maybe.Nothing) {
              return [  ];
          };
          throw new Error("Failed pattern match at Data.Array line 888, column 3 - line 890, column 18: " + [ v.constructor.name ]);
      };
  };
  var nub = function (dictEq) {
      return nubBy(Data_Eq.eq(dictEq));
  };
  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var head = function (xs) {
      return index(xs)(0);
  };
  var fromFoldable = function (dictFoldable) {
      return $foreign.fromFoldableImpl(Data_Foldable.foldr(dictFoldable));
  };
  var findIndex = $foreign.findIndexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var intersectBy = function (eq) {
      return function (xs) {
          return function (ys) {
              return $foreign.filter(function (x) {
                  return Data_Maybe.isJust(findIndex(eq(x))(ys));
              })(xs);
          };
      };
  };
  var intersect = function (dictEq) {
      return intersectBy(Data_Eq.eq(dictEq));
  };
  var deleteAt = $foreign._deleteAt(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var deleteBy = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2.length === 0) {
                  return [  ];
              };
              return Data_Maybe.maybe(v2)(function (i) {
                  return Data_Maybe.fromJust()(deleteAt(i)(v2));
              })(findIndex(v(v1))(v2));
          };
      };
  };
  var $$delete = function (dictEq) {
      return deleteBy(Data_Eq.eq(dictEq));
  };
  var difference = function (dictEq) {
      return Data_Foldable.foldr(Data_Foldable.foldableArray)($$delete(dictEq));
  };
  exports["fromFoldable"] = fromFoldable;
  exports["head"] = head;
  exports["tail"] = tail;
  exports["uncons"] = uncons;
  exports["index"] = index;
  exports["findIndex"] = findIndex;
  exports["deleteAt"] = deleteAt;
  exports["sortBy"] = sortBy;
  exports["sortWith"] = sortWith;
  exports["nub"] = nub;
  exports["nubBy"] = nubBy;
  exports["deleteBy"] = deleteBy;
  exports["difference"] = difference;
  exports["intersect"] = intersect;
  exports["intersectBy"] = intersectBy;
  exports["length"] = $foreign.length;
  exports["filter"] = $foreign.filter;
  exports["take"] = $foreign.take;
})(PS["Data.Array"] = PS["Data.Array"] || {});
(function(exports) {
    "use strict";

  exports.toForeign = function (value) {
    return value;
  };

  exports.unsafeFromForeign = function (value) {
    return value;
  };

  exports.typeOf = function (value) {
    return typeof value;
  };

  exports.tagOf = function (value) {
    return Object.prototype.toString.call(value).slice(8, -1);
  };

  exports.isArray = Array.isArray || function (value) {
    return Object.prototype.toString.call(value) === "[object Array]";
  };
})(PS["Data.Foreign"] = PS["Data.Foreign"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Comonad = PS["Control.Comonad"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Control_MonadPlus = PS["Control.MonadPlus"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_FoldableWithIndex = PS["Data.FoldableWithIndex"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Semigroup_Traversable = PS["Data.Semigroup.Traversable"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_TraversableWithIndex = PS["Data.TraversableWithIndex"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Prelude = PS["Prelude"];
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var NonEmptyList = function (x) {
      return x;
  };
  var toList = function (v) {
      return new Cons(v.value0, v.value1);
  };
  var newtypeNonEmptyList = new Data_Newtype.Newtype(function (n) {
      return n;
  }, NonEmptyList);
  var nelCons = function (a) {
      return function (v) {
          return new Data_NonEmpty.NonEmpty(a, new Cons(v.value0, v.value1));
      };
  };
  var foldableList = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return Data_Foldable.foldl(foldableList)(function (acc) {
              return function ($158) {
                  return Data_Semigroup.append(dictMonoid.Semigroup0())(acc)(f($158));
              };
          })(Data_Monoid.mempty(dictMonoid));
      };
  }, function (f) {
      var go = function ($copy_b) {
          return function ($copy_v) {
              var $tco_var_b = $copy_b;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(b, v) {
                  if (v instanceof Nil) {
                      $tco_done = true;
                      return b;
                  };
                  if (v instanceof Cons) {
                      $tco_var_b = f(b)(v.value0);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List.Types line 81, column 12 - line 83, column 30: " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_b, $copy_v);
              };
              return $tco_result;
          };
      };
      return go;
  }, function (f) {
      return function (b) {
          var rev = Data_Foldable.foldl(foldableList)(Data_Function.flip(Cons.create))(Nil.value);
          return function ($159) {
              return Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b)(rev($159));
          };
      };
  });
  var foldableNonEmptyList = Data_NonEmpty.foldableNonEmpty(foldableList);
  var functorList = new Data_Functor.Functor(function (f) {
      return Data_Foldable.foldr(foldableList)(function (x) {
          return function (acc) {
              return new Cons(f(x), acc);
          };
      })(Nil.value);
  });
  var functorNonEmptyList = Data_NonEmpty.functorNonEmpty(functorList);
  var semigroupList = new Data_Semigroup.Semigroup(function (xs) {
      return function (ys) {
          return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
      };
  });
  var semigroupNonEmptyList = new Data_Semigroup.Semigroup(function (v) {
      return function (as$prime) {
          return new Data_NonEmpty.NonEmpty(v.value0, Data_Semigroup.append(semigroupList)(v.value1)(toList(as$prime)));
      };
  });
  var altList = new Control_Alt.Alt(function () {
      return functorList;
  }, Data_Semigroup.append(semigroupList));
  var plusList = new Control_Plus.Plus(function () {
      return altList;
  }, Nil.value);
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["NonEmptyList"] = NonEmptyList;
  exports["toList"] = toList;
  exports["nelCons"] = nelCons;
  exports["semigroupList"] = semigroupList;
  exports["functorList"] = functorList;
  exports["foldableList"] = foldableList;
  exports["altList"] = altList;
  exports["plusList"] = plusList;
  exports["newtypeNonEmptyList"] = newtypeNonEmptyList;
  exports["functorNonEmptyList"] = functorNonEmptyList;
  exports["semigroupNonEmptyList"] = semigroupNonEmptyList;
  exports["foldableNonEmptyList"] = foldableNonEmptyList;
})(PS["Data.List.Types"] = PS["Data.List.Types"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Lazy = PS["Control.Lazy"];
  var Control_Monad_Rec_Class = PS["Control.Monad.Rec.Class"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Bifunctor = PS["Data.Bifunctor"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_FunctorWithIndex = PS["Data.FunctorWithIndex"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];
  var span = function (v) {
      return function (v1) {
          if (v1 instanceof Data_List_Types.Cons && v(v1.value0)) {
              var v2 = span(v)(v1.value1);
              return {
                  init: new Data_List_Types.Cons(v1.value0, v2.init),
                  rest: v2.rest
              };
          };
          return {
              init: Data_List_Types.Nil.value,
              rest: v1
          };
      };
  };
  var singleton = function (a) {
      return new Data_List_Types.Cons(a, Data_List_Types.Nil.value);
  };
  var sortBy = function (cmp) {
      var merge = function (v) {
          return function (v1) {
              if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                  if (Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(v.value0)(v1.value0))(Data_Ordering.GT.value)) {
                      return new Data_List_Types.Cons(v1.value0, merge(v)(v1.value1));
                  };
                  if (Data_Boolean.otherwise) {
                      return new Data_List_Types.Cons(v.value0, merge(v.value1)(v1));
                  };
              };
              if (v instanceof Data_List_Types.Nil) {
                  return v1;
              };
              if (v1 instanceof Data_List_Types.Nil) {
                  return v;
              };
              throw new Error("Failed pattern match at Data.List line 473, column 3 - line 473, column 38: " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
      var mergePairs = function (v) {
          if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Cons) {
              return new Data_List_Types.Cons(merge(v.value0)(v.value1.value0), mergePairs(v.value1.value1));
          };
          return v;
      };
      var mergeAll = function ($copy_v) {
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(v) {
              if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
                  $tco_done = true;
                  return v.value0;
              };
              $copy_v = mergePairs(v);
              return;
          };
          while (!$tco_done) {
              $tco_result = $tco_loop($copy_v);
          };
          return $tco_result;
      };
      var sequences = function (v) {
          if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Cons) {
              if (Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(v.value0)(v.value1.value0))(Data_Ordering.GT.value)) {
                  return descending(v.value1.value0)(singleton(v.value0))(v.value1.value1);
              };
              if (Data_Boolean.otherwise) {
                  return ascending(v.value1.value0)(function (v1) {
                      return new Data_List_Types.Cons(v.value0, v1);
                  })(v.value1.value1);
              };
          };
          return singleton(v);
      };
      var descending = function ($copy_a) {
          return function ($copy_as) {
              return function ($copy_v) {
                  var $tco_var_a = $copy_a;
                  var $tco_var_as = $copy_as;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(a, as, v) {
                      if (v instanceof Data_List_Types.Cons && Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(a)(v.value0))(Data_Ordering.GT.value)) {
                          $tco_var_a = v.value0;
                          $tco_var_as = new Data_List_Types.Cons(a, as);
                          $copy_v = v.value1;
                          return;
                      };
                      $tco_done = true;
                      return new Data_List_Types.Cons(new Data_List_Types.Cons(a, as), sequences(v));
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_a, $tco_var_as, $copy_v);
                  };
                  return $tco_result;
              };
          };
      };
      var ascending = function ($copy_a) {
          return function ($copy_as) {
              return function ($copy_v) {
                  var $tco_var_a = $copy_a;
                  var $tco_var_as = $copy_as;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(a, as, v) {
                      if (v instanceof Data_List_Types.Cons && Data_Eq.notEq(Data_Ordering.eqOrdering)(cmp(a)(v.value0))(Data_Ordering.GT.value)) {
                          $tco_var_a = v.value0;
                          $tco_var_as = function (ys) {
                              return as(new Data_List_Types.Cons(a, ys));
                          };
                          $copy_v = v.value1;
                          return;
                      };
                      $tco_done = true;
                      return new Data_List_Types.Cons(as(singleton(a)), sequences(v));
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_a, $tco_var_as, $copy_v);
                  };
                  return $tco_result;
              };
          };
      };
      return function ($333) {
          return mergeAll(sequences($333));
      };
  };
  var reverse = (function () {
      var go = function ($copy_acc) {
          return function ($copy_v) {
              var $tco_var_acc = $copy_acc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(acc, v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return acc;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List line 368, column 3 - line 368, column 19: " + [ acc.constructor.name, v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_acc, $copy_v);
              };
              return $tco_result;
          };
      };
      return go(Data_List_Types.Nil.value);
  })();
  var unsnoc = function (lst) {
      var go = function ($copy_v) {
          return function ($copy_acc) {
              var $tco_var_v = $copy_v;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v, acc) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return new Data_Maybe.Just({
                          revInit: acc,
                          last: v.value0
                      });
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      $tco_var_v = v.value1;
                      $copy_acc = new Data_List_Types.Cons(v.value0, acc);
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List line 270, column 3 - line 270, column 23: " + [ v.constructor.name, acc.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_v, $copy_acc);
              };
              return $tco_result;
          };
      };
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (h) {
          return {
              init: reverse(h.revInit),
              last: h.last
          };
      })(go(lst)(Data_List_Types.Nil.value));
  };
  var length = Data_Foldable.foldl(Data_List_Types.foldableList)(function (acc) {
      return function (v) {
          return acc + 1 | 0;
      };
  })(0);
  var last = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return new Data_Maybe.Just(v.value0);
          };
          if (v instanceof Data_List_Types.Cons) {
              $copy_v = v.value1;
              return;
          };
          $tco_done = true;
          return Data_Maybe.Nothing.value;
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var init = function (lst) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
          return v.init;
      })(unsnoc(lst));
  };
  var groupBy = function (v) {
      return function (v1) {
          if (v1 instanceof Data_List_Types.Nil) {
              return Data_List_Types.Nil.value;
          };
          if (v1 instanceof Data_List_Types.Cons) {
              var v2 = span(v(v1.value0))(v1.value1);
              return new Data_List_Types.Cons(new Data_NonEmpty.NonEmpty(v1.value0, v2.init), groupBy(v)(v2.rest));
          };
          throw new Error("Failed pattern match at Data.List line 605, column 1 - line 605, column 80: " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var fromFoldable = function (dictFoldable) {
      return Data_Foldable.foldr(dictFoldable)(Data_List_Types.Cons.create)(Data_List_Types.Nil.value);
  };
  var filter = function (p) {
      var go = function ($copy_acc) {
          return function ($copy_v) {
              var $tco_var_acc = $copy_acc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(acc, v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return reverse(acc);
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (p(v.value0)) {
                          $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                          $copy_v = v.value1;
                          return;
                      };
                      if (Data_Boolean.otherwise) {
                          $tco_var_acc = acc;
                          $copy_v = v.value1;
                          return;
                      };
                  };
                  throw new Error("Failed pattern match at Data.List line 390, column 3 - line 390, column 27: " + [ acc.constructor.name, v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_acc, $copy_v);
              };
              return $tco_result;
          };
      };
      return go(Data_List_Types.Nil.value);
  };
  exports["fromFoldable"] = fromFoldable;
  exports["singleton"] = singleton;
  exports["length"] = length;
  exports["last"] = last;
  exports["init"] = init;
  exports["unsnoc"] = unsnoc;
  exports["reverse"] = reverse;
  exports["filter"] = filter;
  exports["sortBy"] = sortBy;
  exports["span"] = span;
  exports["groupBy"] = groupBy;
})(PS["Data.List"] = PS["Data.List"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_List = PS["Data.List"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semigroup_Foldable = PS["Data.Semigroup.Foldable"];
  var Data_Semigroup_Traversable = PS["Data.Semigroup.Traversable"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Tuple = PS["Data.Tuple"];
  var Data_Unfoldable = PS["Data.Unfoldable"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var wrappedOperation = function (name) {
      return function (f) {
          return function (v) {
              var v1 = f(new Data_List_Types.Cons(v.value0, v.value1));
              if (v1 instanceof Data_List_Types.Cons) {
                  return new Data_NonEmpty.NonEmpty(v1.value0, v1.value1);
              };
              if (v1 instanceof Data_List_Types.Nil) {
                  return Partial_Unsafe.unsafeCrashWith("Impossible: empty list in NonEmptyList " + name);
              };
              throw new Error("Failed pattern match at Data.List.NonEmpty line 88, column 3 - line 90, column 81: " + [ v1.constructor.name ]);
          };
      };
  };
  var tail = function (v) {
      return v.value1;
  };
  var sortBy = function ($159) {
      return wrappedOperation("sortBy")(Data_List.sortBy($159));
  };
  var sort = function (dictOrd) {
      return function (xs) {
          return sortBy(Data_Ord.compare(dictOrd))(xs);
      };
  };
  var singleton = function ($160) {
      return Data_List_Types.NonEmptyList(Data_NonEmpty.singleton(Data_List_Types.plusList)($160));
  };
  var lift = function (f) {
      return function (v) {
          return f(new Data_List_Types.Cons(v.value0, v.value1));
      };
  };
  var length = function (v) {
      return 1 + Data_List.length(v.value1) | 0;
  };
  var head = function (v) {
      return v.value0;
  };
  var groupBy = function ($171) {
      return wrappedOperation("groupBy")(Data_List.groupBy($171));
  };
  var fromList = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof Data_List_Types.Cons) {
          return new Data_Maybe.Just(new Data_NonEmpty.NonEmpty(v.value0, v.value1));
      };
      throw new Error("Failed pattern match at Data.List.NonEmpty line 117, column 1 - line 117, column 57: " + [ v.constructor.name ]);
  };
  var fromFoldable = function (dictFoldable) {
      return function ($172) {
          return fromList(Data_List.fromFoldable(dictFoldable)($172));
      };
  };
  var filter = function ($174) {
      return lift(Data_List.filter($174));
  };
  exports["fromFoldable"] = fromFoldable;
  exports["fromList"] = fromList;
  exports["singleton"] = singleton;
  exports["length"] = length;
  exports["head"] = head;
  exports["tail"] = tail;
  exports["filter"] = filter;
  exports["sort"] = sort;
  exports["sortBy"] = sortBy;
  exports["groupBy"] = groupBy;
})(PS["Data.List.NonEmpty"] = PS["Data.List.NonEmpty"] || {});
(function(exports) {
    "use strict";

  exports.toLower = function (s) {
    return s.toLowerCase();
  };

  exports.joinWith = function (s) {
    return function (xs) {
      return xs.join(s);
    };
  };
})(PS["Data.String"] = PS["Data.String"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.String"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_String_Unsafe = PS["Data.String.Unsafe"];
  var Prelude = PS["Prelude"];
  exports["toLower"] = $foreign.toLower;
  exports["joinWith"] = $foreign.joinWith;
})(PS["Data.String"] = PS["Data.String"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Foreign"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Monad_Error_Class = PS["Control.Monad.Error.Class"];
  var Control_Monad_Except = PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Boolean = PS["Data.Boolean"];
  var Data_Either = PS["Data.Either"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Int = PS["Data.Int"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Prelude = PS["Prelude"];
  var ForeignError = (function () {
      function ForeignError(value0) {
          this.value0 = value0;
      };
      ForeignError.create = function (value0) {
          return new ForeignError(value0);
      };
      return ForeignError;
  })();
  var TypeMismatch = (function () {
      function TypeMismatch(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      TypeMismatch.create = function (value0) {
          return function (value1) {
              return new TypeMismatch(value0, value1);
          };
      };
      return TypeMismatch;
  })();
  var ErrorAtIndex = (function () {
      function ErrorAtIndex(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ErrorAtIndex.create = function (value0) {
          return function (value1) {
              return new ErrorAtIndex(value0, value1);
          };
      };
      return ErrorAtIndex;
  })();
  var ErrorAtProperty = (function () {
      function ErrorAtProperty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ErrorAtProperty.create = function (value0) {
          return function (value1) {
              return new ErrorAtProperty(value0, value1);
          };
      };
      return ErrorAtProperty;
  })();
  var JSONError = (function () {
      function JSONError(value0) {
          this.value0 = value0;
      };
      JSONError.create = function (value0) {
          return new JSONError(value0);
      };
      return JSONError;
  })();
  var showForeignError = new Data_Show.Show(function (v) {
      if (v instanceof ForeignError) {
          return "(ForeignError " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
      };
      if (v instanceof ErrorAtIndex) {
          return "(ErrorAtIndex " + (Data_Show.show(Data_Show.showInt)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
      };
      if (v instanceof ErrorAtProperty) {
          return "(ErrorAtProperty " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
      };
      if (v instanceof JSONError) {
          return "(JSONError " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
      };
      if (v instanceof TypeMismatch) {
          return "(TypeMismatch " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Data_Show.showString)(v.value1) + ")")));
      };
      throw new Error("Failed pattern match at Data.Foreign line 64, column 1 - line 64, column 47: " + [ v.constructor.name ]);
  });
  var fail = function ($121) {
      return Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity))(Data_List_NonEmpty.singleton($121));
  };
  var readArray = function (value) {
      if ($foreign.isArray(value)) {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))($foreign.unsafeFromForeign(value));
      };
      if (Data_Boolean.otherwise) {
          return fail(new TypeMismatch("array", $foreign.tagOf(value)));
      };
      throw new Error("Failed pattern match at Data.Foreign line 145, column 1 - line 145, column 42: " + [ value.constructor.name ]);
  };
  var unsafeReadTagged = function (tag) {
      return function (value) {
          if ($foreign.tagOf(value) === tag) {
              return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))($foreign.unsafeFromForeign(value));
          };
          if (Data_Boolean.otherwise) {
              return fail(new TypeMismatch(tag, $foreign.tagOf(value)));
          };
          throw new Error("Failed pattern match at Data.Foreign line 104, column 1 - line 104, column 55: " + [ tag.constructor.name, value.constructor.name ]);
      };
  };
  var readString = unsafeReadTagged("String");
  exports["ForeignError"] = ForeignError;
  exports["TypeMismatch"] = TypeMismatch;
  exports["ErrorAtIndex"] = ErrorAtIndex;
  exports["ErrorAtProperty"] = ErrorAtProperty;
  exports["JSONError"] = JSONError;
  exports["unsafeReadTagged"] = unsafeReadTagged;
  exports["readString"] = readString;
  exports["readArray"] = readArray;
  exports["fail"] = fail;
  exports["showForeignError"] = showForeignError;
  exports["toForeign"] = $foreign.toForeign;
  exports["typeOf"] = $foreign.typeOf;
})(PS["Data.Foreign"] = PS["Data.Foreign"] || {});
(function(exports) {
    "use strict";

  exports.unsafeReadPropImpl = function (f, s, key, value) {
    return value == null ? f : s(value[key]);
  };
})(PS["Data.Foreign.Index"] = PS["Data.Foreign.Index"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Foreign.Index"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Identity = PS["Data.Identity"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Prelude = PS["Prelude"];
  var unsafeReadProp = function (k) {
      return function (value) {
          return $foreign.unsafeReadPropImpl(Data_Foreign.fail(new Data_Foreign.TypeMismatch("object", Data_Foreign.typeOf(value))), Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)), k, value);
      };
  };
  var readProp = unsafeReadProp;
  exports["readProp"] = readProp;
})(PS["Data.Foreign.Index"] = PS["Data.Foreign.Index"] || {});
(function(exports) {
    "use strict";

  exports.unsafeGetFn = function(label, rec) {
    return rec[label];
  };
})(PS["Data.Record.Unsafe"] = PS["Data.Record.Unsafe"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Record.Unsafe"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  exports["unsafeGetFn"] = $foreign.unsafeGetFn;
})(PS["Data.Record.Unsafe"] = PS["Data.Record.Unsafe"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Semigroup = PS["Data.Semigroup"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var SProxy = (function () {
      function SProxy() {

      };
      SProxy.value = new SProxy();
      return SProxy;
  })();
  var IsSymbol = function (reflectSymbol) {
      this.reflectSymbol = reflectSymbol;
  };
  var reflectSymbol = function (dict) {
      return dict.reflectSymbol;
  };
  exports["IsSymbol"] = IsSymbol;
  exports["reflectSymbol"] = reflectSymbol;
  exports["SProxy"] = SProxy;
})(PS["Data.Symbol"] = PS["Data.Symbol"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Type_Data_Boolean = PS["Type.Data.Boolean"];
  var Type_Data_Symbol = PS["Type.Data.Symbol"];
  var Type_Equality = PS["Type.Equality"];
  var RLProxy = (function () {
      function RLProxy() {

      };
      RLProxy.value = new RLProxy();
      return RLProxy;
  })();
  var RowLacking = {};
  var RowLacks = {};
  var rowLacks = function (dictRowCons) {
      return function (dictUnion) {
          return function (dictRowCons1) {
              return function (dictRowLacking) {
                  return RowLacks;
              };
          };
      };
  };
  var rowLacking = RowLacking;
  exports["RowLacks"] = RowLacks;
  exports["RowLacking"] = RowLacking;
  exports["RLProxy"] = RLProxy;
  exports["rowLacking"] = rowLacking;
  exports["rowLacks"] = rowLacks;
})(PS["Type.Row"] = PS["Type.Row"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Eq = PS["Data.Eq"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Record_Unsafe = PS["Data.Record.Unsafe"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];
  var Type_Row = PS["Type.Row"];
  var get = function (dictIsSymbol) {
      return function (dictRowCons) {
          return function (l) {
              return function (r) {
                  return Data_Record_Unsafe.unsafeGetFn(Data_Symbol.reflectSymbol(dictIsSymbol)(l), r);
              };
          };
      };
  };
  exports["get"] = get;
})(PS["Data.Record"] = PS["Data.Record"] || {});
(function(exports) {
    "use strict";

  exports.copyRecord = function(rec) {
    var copy = {};
    for (var key in rec) {
      if ({}.hasOwnProperty.call(rec, key)) {
        copy[key] = rec[key];
      }
    }
    return copy;
  };

  exports.unsafeInsert = function(l) {
    return function(a) {
      return function(rec) {
        rec[l] = a;
        return rec;
      };
    };
  };
})(PS["Data.Record.Builder"] = PS["Data.Record.Builder"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var $foreign = PS["Data.Record.Builder"];
  var Control_Category = PS["Control.Category"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Symbol = PS["Data.Symbol"];
  var Prelude = PS["Prelude"];
  var Type_Row = PS["Type.Row"];
  var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
  var insert = function (dictRowCons) {
      return function (dictRowLacks) {
          return function (dictIsSymbol) {
              return function (l) {
                  return function (a) {
                      return function (r1) {
                          return $foreign.unsafeInsert(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r1);
                      };
                  };
              };
          };
      };
  };
  var categoryBuilder = Control_Category.categoryFn;
  var build = function (v) {
      return function (r1) {
          return v($foreign.copyRecord(r1));
      };
  };
  exports["build"] = build;
  exports["insert"] = insert;
  exports["semigroupoidBuilder"] = semigroupoidBuilder;
  exports["categoryBuilder"] = categoryBuilder;
})(PS["Data.Record.Builder"] = PS["Data.Record.Builder"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Alt = PS["Control.Alt"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Monad_Except = PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Either = PS["Data.Either"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Foreign_Index = PS["Data.Foreign.Index"];
  var Data_Foreign_Internal = PS["Data.Foreign.Internal"];
  var Data_Foreign_JSON = PS["Data.Foreign.JSON"];
  var Data_Foreign_NullOrUndefined = PS["Data.Foreign.NullOrUndefined"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Identity = PS["Data.Identity"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Data_Record = PS["Data.Record"];
  var Data_Record_Builder = PS["Data.Record.Builder"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_StrMap = PS["Data.StrMap"];
  var Data_Symbol = PS["Data.Symbol"];
  var Data_Traversable = PS["Data.Traversable"];
  var Data_Variant = PS["Data.Variant"];
  var Global_Unsafe = PS["Global.Unsafe"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var Type_Row = PS["Type.Row"];
  var ReadForeign = function (readImpl) {
      this.readImpl = readImpl;
  };
  var ReadForeignFields = function (getFields) {
      this.getFields = getFields;
  };
  var WriteForeign = function (writeImpl) {
      this.writeImpl = writeImpl;
  };
  var WriteForeignFields = function (writeImplFields) {
      this.writeImplFields = writeImplFields;
  };
  var writeImplFields = function (dict) {
      return dict.writeImplFields;
  };
  var writeImpl = function (dict) {
      return dict.writeImpl;
  };
  var writeForeignString = new WriteForeign(Data_Foreign.toForeign);
  var writeForeignNonEmptyList = function (dictWriteForeign) {
      return new WriteForeign(function (xs) {
          return Data_Foreign.toForeign(Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)(Data_Functor.map(Data_List_Types.functorNonEmptyList)(writeImpl(dictWriteForeign))(xs)));
      });
  };
  var writeForeignForeignError = new WriteForeign(function (e) {
      return Data_Foreign.toForeign(Data_Show.show(Data_Foreign.showForeignError)(e));
  });
  var writeForeignArray = function (dictWriteForeign) {
      return new WriteForeign(function (xs) {
          return Data_Foreign.toForeign(Data_Functor.map(Data_Functor.functorArray)(writeImpl(dictWriteForeign))(xs));
      });
  };
  var write = function (dictWriteForeign) {
      return writeImpl(dictWriteForeign);
  };
  var recordWriteForeign = function (dictRowToList) {
      return function (dictWriteForeignFields) {
          return new WriteForeign(function (rec) {
              var steps = writeImplFields(dictWriteForeignFields)(Type_Row.RLProxy.value)(rec);
              return Data_Foreign.toForeign(Data_Record_Builder.build(steps)({}));
          });
      };
  };
  var readImpl = function (dict) {
      return dict.readImpl;
  };
  var readFieldsNil = new ReadForeignFields(function (v) {
      return function (v1) {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Category.id(Data_Record_Builder.categoryBuilder));
      };
  });
  var readArray = function (dictReadForeign) {
      return new ReadForeign(Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_Traversable.traverse(Data_Traversable.traversableArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign)))(Data_Foreign.readArray));
  };
  var read = function (dictReadForeign) {
      return function ($81) {
          return Control_Monad_Except.runExcept(readImpl(dictReadForeign)($81));
      };
  };
  var nilWriteForeignFields = new WriteForeignFields(function (v) {
      return function (v1) {
          return Control_Category.id(Data_Record_Builder.categoryBuilder);
      };
  });
  var getFields = function (dict) {
      return dict.getFields;
  };
  var readFieldsCons = function (dictIsSymbol) {
      return function (dictReadForeign) {
          return function (dictReadForeignFields) {
              return function (dictRowLacks) {
                  return function (dictRowCons) {
                      return new ReadForeignFields(function (v) {
                          return function (obj) {
                              var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                              var withExcept$prime = Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Data_Foreign.ErrorAtProperty.create(name)));
                              return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(withExcept$prime(Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictReadForeign))(Data_Foreign_Index.readProp(name)(obj))))(function (v1) {
                                  return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(getFields(dictReadForeignFields)(Type_Row.RLProxy.value)(obj))(function (v2) {
                                      var first = Data_Record_Builder.insert(dictRowCons)(dictRowLacks)(dictIsSymbol)(Data_Symbol.SProxy.value)(v1);
                                      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Semigroupoid.compose(Data_Record_Builder.semigroupoidBuilder)(first)(v2));
                                  });
                              });
                          };
                      });
                  };
              };
          };
      };
  };
  var readRecord = function (dictRowToList) {
      return function (dictReadForeignFields) {
          return new ReadForeign(function (o) {
              return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(getFields(dictReadForeignFields)(Type_Row.RLProxy.value)(o))(function (v) {
                  return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Record_Builder.build(v)({}));
              });
          });
      };
  };
  var consWriteForeignFields = function (dictIsSymbol) {
      return function (dictWriteForeign) {
          return function (dictWriteForeignFields) {
              return function (dictRowCons) {
                  return function (dictRowLacks) {
                      return function (dictRowCons1) {
                          return new WriteForeignFields(function (v) {
                              return function (rec) {
                                  var rest = writeImplFields(dictWriteForeignFields)(Type_Row.RLProxy.value)(rec);
                                  var value = writeImpl(dictWriteForeign)(Data_Record.get(dictIsSymbol)(dictRowCons)(Data_Symbol.SProxy.value)(rec));
                                  var result = Control_Semigroupoid.compose(Data_Record_Builder.semigroupoidBuilder)(Data_Record_Builder.insert(dictRowCons1)(dictRowLacks)(dictIsSymbol)(Data_Symbol.SProxy.value)(value))(rest);
                                  return result;
                              };
                          });
                      };
                  };
              };
          };
      };
  };
  exports["write"] = write;
  exports["read"] = read;
  exports["ReadForeign"] = ReadForeign;
  exports["readImpl"] = readImpl;
  exports["ReadForeignFields"] = ReadForeignFields;
  exports["getFields"] = getFields;
  exports["WriteForeign"] = WriteForeign;
  exports["writeImpl"] = writeImpl;
  exports["WriteForeignFields"] = WriteForeignFields;
  exports["writeImplFields"] = writeImplFields;
  exports["readArray"] = readArray;
  exports["readRecord"] = readRecord;
  exports["readFieldsCons"] = readFieldsCons;
  exports["readFieldsNil"] = readFieldsNil;
  exports["writeForeignString"] = writeForeignString;
  exports["writeForeignArray"] = writeForeignArray;
  exports["writeForeignNonEmptyList"] = writeForeignNonEmptyList;
  exports["writeForeignForeignError"] = writeForeignForeignError;
  exports["recordWriteForeign"] = recordWriteForeign;
  exports["consWriteForeignFields"] = consWriteForeignFields;
  exports["nilWriteForeignFields"] = nilWriteForeignFields;
})(PS["Simple.JSON"] = PS["Simple.JSON"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Partial_Unsafe = PS["Partial.Unsafe"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Male = (function () {
      function Male() {

      };
      Male.value = new Male();
      return Male;
  })();
  var Female = (function () {
      function Female() {

      };
      Female.value = new Female();
      return Female;
  })();
  var Copper = (function () {
      function Copper() {

      };
      Copper.value = new Copper();
      return Copper;
  })();
  var Hormonal = (function () {
      function Hormonal() {

      };
      Hormonal.value = new Hormonal();
      return Hormonal;
  })();
  var Estrogen = (function () {
      function Estrogen() {

      };
      Estrogen.value = new Estrogen();
      return Estrogen;
  })();
  var Progestin = (function () {
      function Progestin() {

      };
      Progestin.value = new Progestin();
      return Progestin;
  })();
  var IUD = (function () {
      function IUD(value0) {
          this.value0 = value0;
      };
      IUD.create = function (value0) {
          return new IUD(value0);
      };
      return IUD;
  })();
  var Implant = (function () {
      function Implant() {

      };
      Implant.value = new Implant();
      return Implant;
  })();
  var Diaphragm = (function () {
      function Diaphragm() {

      };
      Diaphragm.value = new Diaphragm();
      return Diaphragm;
  })();
  var Sterilization = (function () {
      function Sterilization() {

      };
      Sterilization.value = new Sterilization();
      return Sterilization;
  })();
  var Condom = (function () {
      function Condom(value0) {
          this.value0 = value0;
      };
      Condom.create = function (value0) {
          return new Condom(value0);
      };
      return Condom;
  })();
  var Pill = (function () {
      function Pill(value0) {
          this.value0 = value0;
      };
      Pill.create = function (value0) {
          return new Pill(value0);
      };
      return Pill;
  })();
  var Patch = (function () {
      function Patch() {

      };
      Patch.value = new Patch();
      return Patch;
  })();
  var Ring = (function () {
      function Ring() {

      };
      Ring.value = new Ring();
      return Ring;
  })();
  var Shot = (function () {
      function Shot() {

      };
      Shot.value = new Shot();
      return Shot;
  })();
  var Effectiveness = function (x) {
      return x;
  };
  var toNel = function (dictFoldable) {
      return function ($68) {
          return Data_Maybe.fromJust()(Data_List_NonEmpty.fromFoldable(dictFoldable)($68));
      };
  };
  var showSex = new Data_Show.Show(function (v) {
      if (v instanceof Male) {
          return "Male";
      };
      if (v instanceof Female) {
          return "Female";
      };
      throw new Error("Failed pattern match at MyBc.Recs.Method line 20, column 1 - line 20, column 29: " + [ v.constructor.name ]);
  });
  var showIudMaterial = new Data_Show.Show(function (v) {
      if (v instanceof Copper) {
          return "Copper";
      };
      if (v instanceof Hormonal) {
          return "Hormonal";
      };
      throw new Error("Failed pattern match at MyBc.Recs.Method line 26, column 1 - line 26, column 45: " + [ v.constructor.name ]);
  });
  var showHormone = new Data_Show.Show(function (v) {
      if (v instanceof Estrogen) {
          return "Estrogen";
      };
      if (v instanceof Progestin) {
          return "Progestin";
      };
      throw new Error("Failed pattern match at MyBc.Recs.Method line 32, column 1 - line 32, column 37: " + [ v.constructor.name ]);
  });
  var showMethod = new Data_Show.Show(function (v) {
      if (v instanceof IUD) {
          return Data_String.joinWith(" ")([ "IUD", Data_Show.show(showIudMaterial)(v.value0) ]);
      };
      if (v instanceof Implant) {
          return "Implant";
      };
      if (v instanceof Diaphragm) {
          return "Diaphragm";
      };
      if (v instanceof Sterilization) {
          return "Sterilization";
      };
      if (v instanceof Condom) {
          return Data_String.joinWith(" ")([ "Condom", Data_Show.show(showSex)(v.value0) ]);
      };
      if (v instanceof Pill) {
          return Data_String.joinWith(" ")([ "Pill", Data_Show.show(showHormone)(v.value0) ]);
      };
      if (v instanceof Patch) {
          return "Patch";
      };
      if (v instanceof Ring) {
          return "Ring";
      };
      if (v instanceof Shot) {
          return "Shot";
      };
      throw new Error("Failed pattern match at MyBc.Recs.Method line 42, column 10 - line 54, column 1: " + [ v.constructor.name ]);
  });
  var writeForeign = new Simple_JSON.WriteForeign(function ($69) {
      return Simple_JSON.writeImpl(Simple_JSON.writeForeignString)(Data_String.toLower(Data_Show.show(showMethod)($69)));
  });
  var newtypeEffectiveness = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Effectiveness);
  var isPermanent = function (v) {
      if (v instanceof Sterilization) {
          return true;
      };
      return false;
  };
  var isEstrogenic = function (v) {
      if (v instanceof Pill && v.value0 instanceof Estrogen) {
          return true;
      };
      if (v instanceof Patch) {
          return true;
      };
      if (v instanceof Ring) {
          return true;
      };
      return false;
  };
  var eqSex = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Male && y instanceof Male) {
              return true;
          };
          if (x instanceof Female && y instanceof Female) {
              return true;
          };
          return false;
      };
  });
  var eqIudMaterial = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Copper && y instanceof Copper) {
              return true;
          };
          if (x instanceof Hormonal && y instanceof Hormonal) {
              return true;
          };
          return false;
      };
  });
  var eqHormone = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Estrogen && y instanceof Estrogen) {
              return true;
          };
          if (x instanceof Progestin && y instanceof Progestin) {
              return true;
          };
          return false;
      };
  });
  var eqMethod = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof IUD && y instanceof IUD) {
              return Data_Eq.eq(eqIudMaterial)(x.value0)(y.value0);
          };
          if (x instanceof Implant && y instanceof Implant) {
              return true;
          };
          if (x instanceof Diaphragm && y instanceof Diaphragm) {
              return true;
          };
          if (x instanceof Sterilization && y instanceof Sterilization) {
              return true;
          };
          if (x instanceof Condom && y instanceof Condom) {
              return Data_Eq.eq(eqSex)(x.value0)(y.value0);
          };
          if (x instanceof Pill && y instanceof Pill) {
              return Data_Eq.eq(eqHormone)(x.value0)(y.value0);
          };
          if (x instanceof Patch && y instanceof Patch) {
              return true;
          };
          if (x instanceof Ring && y instanceof Ring) {
              return true;
          };
          if (x instanceof Shot && y instanceof Shot) {
              return true;
          };
          return false;
      };
  });
  var eqEffectiveness = new Data_Eq.Eq(function (x) {
      return function (y) {
          return Data_Eq.eq(eqMethod)(x)(y);
      };
  });
  var effectiveness = function (v) {
      if (v instanceof IUD) {
          return 1;
      };
      if (v instanceof Implant) {
          return 1;
      };
      if (v instanceof Sterilization) {
          return 1;
      };
      if (v instanceof Shot) {
          return 6;
      };
      if (v instanceof Pill) {
          return 9;
      };
      if (v instanceof Ring) {
          return 9;
      };
      if (v instanceof Patch) {
          return 9;
      };
      if (v instanceof Diaphragm) {
          return 12;
      };
      if (v instanceof Condom && v.value0 instanceof Male) {
          return 18;
      };
      if (v instanceof Condom && v.value0 instanceof Female) {
          return 21;
      };
      throw new Error("Failed pattern match at MyBc.Recs.Method line 62, column 17 - line 75, column 1: " + [ v.constructor.name ]);
  };
  var ordEffectiveness = new Data_Ord.Ord(function () {
      return eqEffectiveness;
  }, function (a) {
      return function (b) {
          return Data_Ord.compare(Data_Ord.ordInt)(effectiveness(Data_Newtype.unwrap(newtypeEffectiveness)(a)))(effectiveness(Data_Newtype.unwrap(newtypeEffectiveness)(b)));
      };
  });
  var allMethodsBare = [ Diaphragm.value, new Condom(Male.value), new Condom(Female.value), Sterilization.value, new IUD(Copper.value), new IUD(Hormonal.value), Implant.value, new Pill(Estrogen.value), Patch.value, Ring.value, Shot.value ];
  var allMethods = toNel(Data_Foldable.foldableArray)(allMethodsBare);
  exports["Male"] = Male;
  exports["Female"] = Female;
  exports["Copper"] = Copper;
  exports["Hormonal"] = Hormonal;
  exports["Estrogen"] = Estrogen;
  exports["Progestin"] = Progestin;
  exports["IUD"] = IUD;
  exports["Implant"] = Implant;
  exports["Diaphragm"] = Diaphragm;
  exports["Sterilization"] = Sterilization;
  exports["Condom"] = Condom;
  exports["Pill"] = Pill;
  exports["Patch"] = Patch;
  exports["Ring"] = Ring;
  exports["Shot"] = Shot;
  exports["Effectiveness"] = Effectiveness;
  exports["effectiveness"] = effectiveness;
  exports["isPermanent"] = isPermanent;
  exports["isEstrogenic"] = isEstrogenic;
  exports["allMethodsBare"] = allMethodsBare;
  exports["allMethods"] = allMethods;
  exports["toNel"] = toNel;
  exports["eqSex"] = eqSex;
  exports["showSex"] = showSex;
  exports["eqIudMaterial"] = eqIudMaterial;
  exports["showIudMaterial"] = showIudMaterial;
  exports["eqHormone"] = eqHormone;
  exports["showHormone"] = showHormone;
  exports["eqMethod"] = eqMethod;
  exports["writeForeign"] = writeForeign;
  exports["showMethod"] = showMethod;
  exports["newtypeEffectiveness"] = newtypeEffectiveness;
  exports["eqEffectiveness"] = eqEffectiveness;
  exports["ordEffectiveness"] = ordEffectiveness;
})(PS["MyBc.Recs.Method"] = PS["MyBc.Recs.Method"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Eq = PS["Data.Eq"];
  var Data_Show = PS["Data.Show"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var Prelude = PS["Prelude"];
  var DecreasedAcne = (function () {
      function DecreasedAcne() {

      };
      DecreasedAcne.value = new DecreasedAcne();
      return DecreasedAcne;
  })();
  var NoPeriod = (function () {
      function NoPeriod() {

      };
      NoPeriod.value = new NoPeriod();
      return NoPeriod;
  })();
  var DecreasedCramping = (function () {
      function DecreasedCramping() {

      };
      DecreasedCramping.value = new DecreasedCramping();
      return DecreasedCramping;
  })();
  var DecreasedPeriod = (function () {
      function DecreasedPeriod() {

      };
      DecreasedPeriod.value = new DecreasedPeriod();
      return DecreasedPeriod;
  })();
  var methodBenefits = function (v) {
      if (v instanceof MyBc_Recs_Method.Pill) {
          return [ DecreasedAcne.value, DecreasedCramping.value, DecreasedPeriod.value ];
      };
      if (v instanceof MyBc_Recs_Method.Patch) {
          return [ DecreasedAcne.value, DecreasedCramping.value, DecreasedPeriod.value ];
      };
      if (v instanceof MyBc_Recs_Method.Ring) {
          return [ DecreasedCramping.value, DecreasedPeriod.value ];
      };
      if (v instanceof MyBc_Recs_Method.IUD && v.value0 instanceof MyBc_Recs_Method.Hormonal) {
          return [ NoPeriod.value, DecreasedCramping.value, DecreasedPeriod.value ];
      };
      if (v instanceof MyBc_Recs_Method.Shot) {
          return [ NoPeriod.value, DecreasedCramping.value, DecreasedPeriod.value ];
      };
      return [  ];
  };
  exports["DecreasedAcne"] = DecreasedAcne;
  exports["NoPeriod"] = NoPeriod;
  exports["DecreasedCramping"] = DecreasedCramping;
  exports["DecreasedPeriod"] = DecreasedPeriod;
  exports["methodBenefits"] = methodBenefits;
})(PS["MyBc.Recs.Benefit"] = PS["MyBc.Recs.Benefit"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Array = PS["Data.Array"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var Prelude = PS["Prelude"];
  var cohort = function (ms) {
      var v = Data_Array.length(Data_Array.intersect(MyBc_Recs_Method.eqMethod)(Data_Array.take(4)(ms))([ new MyBc_Recs_Method.Pill(MyBc_Recs_Method.Estrogen.value), MyBc_Recs_Method.Patch.value, MyBc_Recs_Method.Ring.value ]));
      if (v === 3) {
          return ms;
      };
      return Data_Array.difference(MyBc_Recs_Method.eqMethod)(ms)([ new MyBc_Recs_Method.Pill(MyBc_Recs_Method.Estrogen.value), MyBc_Recs_Method.Patch.value, MyBc_Recs_Method.Ring.value ]);
  };
  exports["cohort"] = cohort;
})(PS["MyBc.Recs.Cohort"] = PS["MyBc.Recs.Cohort"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Data_Array = PS["Data.Array"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var BrightLights = (function () {
      function BrightLights() {

      };
      BrightLights.value = new BrightLights();
      return BrightLights;
  })();
  var Spots = (function () {
      function Spots() {

      };
      Spots.value = new Spots();
      return Spots;
  })();
  var Numbness = (function () {
      function Numbness() {

      };
      Numbness.value = new Numbness();
      return Numbness;
  })();
  var Language = (function () {
      function Language() {

      };
      Language.value = new Language();
      return Language;
  })();
  var Weakness = (function () {
      function Weakness() {

      };
      Weakness.value = new Weakness();
      return Weakness;
  })();
  var Migraine = (function () {
      function Migraine() {

      };
      Migraine.value = new Migraine();
      return Migraine;
  })();
  var Over35 = (function () {
      function Over35() {

      };
      Over35.value = new Over35();
      return Over35;
  })();
  var Smoking = (function () {
      function Smoking() {

      };
      Smoking.value = new Smoking();
      return Smoking;
  })();
  var BloodClots = (function () {
      function BloodClots() {

      };
      BloodClots.value = new BloodClots();
      return BloodClots;
  })();
  var BloodPressure = (function () {
      function BloodPressure() {

      };
      BloodPressure.value = new BloodPressure();
      return BloodPressure;
  })();
  var symptomMigraine = function (s) {
      var v = Data_String.toLower(s);
      if (v === "brightlights") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(BrightLights.value);
      };
      if (v === "spots") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Spots.value);
      };
      if (v === "numbness") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Numbness.value);
      };
      if (v === "language") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Language.value);
      };
      if (v === "weakness") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Weakness.value);
      };
      return Data_Foreign.fail(Data_Foreign.ForeignError.create("Did not recognize" + (s + " as a value for Timing. Expecting [ \"brightlights\" | \"spots\" | \"numbness\" | \"language\" | \"weakness\" ]")));
  };
  var rfSymptomMigraine = new Simple_JSON.ReadForeign(Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(symptomMigraine)(Data_Foreign.readString));
  var eqSymptomMigraine = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof BrightLights && y instanceof BrightLights) {
              return true;
          };
          if (x instanceof Spots && y instanceof Spots) {
              return true;
          };
          if (x instanceof Numbness && y instanceof Numbness) {
              return true;
          };
          if (x instanceof Language && y instanceof Language) {
              return true;
          };
          if (x instanceof Weakness && y instanceof Weakness) {
              return true;
          };
          return false;
      };
  });
  var eqConcern = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Migraine && y instanceof Migraine) {
              return true;
          };
          if (x instanceof Over35 && y instanceof Over35) {
              return true;
          };
          if (x instanceof Smoking && y instanceof Smoking) {
              return true;
          };
          if (x instanceof BloodClots && y instanceof BloodClots) {
              return true;
          };
          if (x instanceof BloodPressure && y instanceof BloodPressure) {
              return true;
          };
          return false;
      };
  });
  var estrogen = function (cs) {
      return function (sms) {
          var elems = function (inner) {
              return function (outer) {
                  return Data_Foldable.foldl(Data_Foldable.foldableArray)(Data_HeytingAlgebra.conj(Data_HeytingAlgebra.heytingAlgebraBoolean))(true)(Control_Apply.apply(Control_Apply.applyArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Foldable.elem(Data_Foldable.foldableArray)(eqConcern))(inner))([ outer ]));
              };
          };
          return elems([ Migraine.value, Over35.value ])(cs) || (elems([ Smoking.value, Over35.value ])(cs) || (Data_Foldable.elem(Data_Foldable.foldableArray)(eqConcern)(BloodClots.value)(cs) || (Data_Foldable.elem(Data_Foldable.foldableArray)(eqConcern)(Migraine.value)(cs) && Data_Eq.notEq(Data_Eq.eqArray(eqSymptomMigraine))(sms)([  ]) || Data_Foldable.elem(Data_Foldable.foldableArray)(eqConcern)(BloodPressure.value)(cs))));
      };
  };
  var concernsMethodVeto = function (cs) {
      return function (sms) {
          return function (m) {
              return estrogen(cs)(sms) && MyBc_Recs_Method.isEstrogenic(m);
          };
      };
  };
  var concern = function (s) {
      var v = Data_String.toLower(s);
      if (v === "migraine") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Migraine.value);
      };
      if (v === "over35") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Over35.value);
      };
      if (v === "smoking") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Smoking.value);
      };
      if (v === "bloodclots") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(BloodClots.value);
      };
      if (v === "bloodpressure") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(BloodPressure.value);
      };
      return Data_Foreign.fail(Data_Foreign.ForeignError.create("Did not recognize" + (s + " as a value for Timing. Expecting [ \"migraine\" | \"over35\" | \"smoking\" | \"bloodclots\" | \"bloodpressure\" ]")));
  };
  var rfConcern = new Simple_JSON.ReadForeign(Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(concern)(Data_Foreign.readString));
  exports["Migraine"] = Migraine;
  exports["Over35"] = Over35;
  exports["Smoking"] = Smoking;
  exports["BloodClots"] = BloodClots;
  exports["BloodPressure"] = BloodPressure;
  exports["concern"] = concern;
  exports["BrightLights"] = BrightLights;
  exports["Spots"] = Spots;
  exports["Numbness"] = Numbness;
  exports["Language"] = Language;
  exports["Weakness"] = Weakness;
  exports["symptomMigraine"] = symptomMigraine;
  exports["estrogen"] = estrogen;
  exports["concernsMethodVeto"] = concernsMethodVeto;
  exports["eqConcern"] = eqConcern;
  exports["rfConcern"] = rfConcern;
  exports["eqSymptomMigraine"] = eqSymptomMigraine;
  exports["rfSymptomMigraine"] = rfSymptomMigraine;
})(PS["MyBc.Recs.Concern"] = PS["MyBc.Recs.Concern"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Foldable = PS["Data.Foldable"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Prelude = PS["Prelude"];
  var pickAtleast = function (limit) {
      return function (aas) {
          var limitAppend = function (min) {
              return function (b) {
                  return function (a) {
                      var v = Data_List_NonEmpty.length(b) >= min;
                      if (v) {
                          return b;
                      };
                      if (!v) {
                          return Data_Semigroup.append(Data_List_Types.semigroupNonEmptyList)(b)(a);
                      };
                      throw new Error("Failed pattern match at MyBc.Recs.Pick line 24, column 27 - line 26, column 21: " + [ v.constructor.name ]);
                  };
              };
          };
          return Data_Foldable.foldl(Data_List_Types.foldableList)(limitAppend(limit))(Data_List_NonEmpty.head(aas))(Data_List_NonEmpty.tail(aas));
      };
  };
  exports["pickAtleast"] = pickAtleast;
})(PS["MyBc.Recs.Pick"] = PS["MyBc.Recs.Pick"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];
  var Ranked = function (x) {
      return x;
  };
  var toMethod = function (dictNewtype) {
      return function ($13) {
          return (function (v) {
              return v.method;
          })(Data_Newtype.unwrap(dictNewtype)($13));
      };
  };
  var rank = function (dictOrd) {
      return function ($14) {
          return Ranked(Data_List_NonEmpty.groupBy(Data_Eq.eq(dictOrd.Eq0()))(Data_List_NonEmpty.sort(dictOrd)($14)));
      };
  };
  var newtypeRanked = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Ranked);
  exports["Ranked"] = Ranked;
  exports["rank"] = rank;
  exports["toMethod"] = toMethod;
  exports["newtypeRanked"] = newtypeRanked;
})(PS["MyBc.Recs.Rank"] = PS["MyBc.Recs.Rank"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Eq = PS["Data.Eq"];
  var Data_Show = PS["Data.Show"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var Prelude = PS["Prelude"];
  var Spotting = (function () {
      function Spotting() {

      };
      Spotting.value = new Spotting();
      return Spotting;
  })();
  var NoPeriod = (function () {
      function NoPeriod() {

      };
      NoPeriod.value = new NoPeriod();
      return NoPeriod;
  })();
  var HeavierPeriod = (function () {
      function HeavierPeriod() {

      };
      HeavierPeriod.value = new HeavierPeriod();
      return HeavierPeriod;
  })();
  var WeightGain = (function () {
      function WeightGain() {

      };
      WeightGain.value = new WeightGain();
      return WeightGain;
  })();
  var methodSideEffects = function (v) {
      if (v instanceof MyBc_Recs_Method.Shot) {
          return [ Spotting.value, NoPeriod.value, WeightGain.value ];
      };
      if (v instanceof MyBc_Recs_Method.Implant) {
          return [ Spotting.value ];
      };
      if (v instanceof MyBc_Recs_Method.IUD && v.value0 instanceof MyBc_Recs_Method.Hormonal) {
          return [ Spotting.value, NoPeriod.value ];
      };
      if (v instanceof MyBc_Recs_Method.IUD && v.value0 instanceof MyBc_Recs_Method.Copper) {
          return [ HeavierPeriod.value ];
      };
      return [  ];
  };
  exports["Spotting"] = Spotting;
  exports["NoPeriod"] = NoPeriod;
  exports["HeavierPeriod"] = HeavierPeriod;
  exports["WeightGain"] = WeightGain;
  exports["methodSideEffects"] = methodSideEffects;
})(PS["MyBc.Recs.SideEffect"] = PS["MyBc.Recs.SideEffect"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Like = (function () {
      function Like() {

      };
      Like.value = new Like();
      return Like;
  })();
  var Neutral = (function () {
      function Neutral() {

      };
      Neutral.value = new Neutral();
      return Neutral;
  })();
  var NegativeWeak = (function () {
      function NegativeWeak() {

      };
      NegativeWeak.value = new NegativeWeak();
      return NegativeWeak;
  })();
  var Negative = (function () {
      function Negative() {

      };
      Negative.value = new Negative();
      return Negative;
  })();
  var skewBother = function (s) {
      var v = Data_String.toLower(s);
      if (v === "positive") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Like.value);
      };
      if (v === "neutral") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Neutral.value);
      };
      if (v === "negativeweak") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(NegativeWeak.value);
      };
      if (v === "negative") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Negative.value);
      };
      return Data_Foreign.fail(Data_Foreign.ForeignError.create("Did not recognize" + (s + " as a value for SkewEnthusiasm. Expecting [  \"positive\" | \"neutral\" | \"negativeweak\" | \"negative\" ]")));
  };
  var rfSkewBother = new Simple_JSON.ReadForeign(Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(skewBother)(Data_Foreign.readString));
  var eqSkewBother = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Like && y instanceof Like) {
              return true;
          };
          if (x instanceof Neutral && y instanceof Neutral) {
              return true;
          };
          if (x instanceof NegativeWeak && y instanceof NegativeWeak) {
              return true;
          };
          if (x instanceof Negative && y instanceof Negative) {
              return true;
          };
          return false;
      };
  });
  exports["Like"] = Like;
  exports["Neutral"] = Neutral;
  exports["NegativeWeak"] = NegativeWeak;
  exports["Negative"] = Negative;
  exports["skewBother"] = skewBother;
  exports["eqSkewBother"] = eqSkewBother;
  exports["rfSkewBother"] = rfSkewBother;
})(PS["MyBc.Recs.SkewBother"] = PS["MyBc.Recs.SkewBother"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Dislike = (function () {
      function Dislike() {

      };
      Dislike.value = new Dislike();
      return Dislike;
  })();
  var Neutral = (function () {
      function Neutral() {

      };
      Neutral.value = new Neutral();
      return Neutral;
  })();
  var PositiveWeak = (function () {
      function PositiveWeak() {

      };
      PositiveWeak.value = new PositiveWeak();
      return PositiveWeak;
  })();
  var Positive = (function () {
      function Positive() {

      };
      Positive.value = new Positive();
      return Positive;
  })();
  var skewEnthusiasm = function (s) {
      var v = Data_String.toLower(s);
      if (v === "negative") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Dislike.value);
      };
      if (v === "neutral") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Neutral.value);
      };
      if (v === "positiveweak") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(PositiveWeak.value);
      };
      if (v === "positive") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(PositiveWeak.value);
      };
      return Data_Foreign.fail(Data_Foreign.ForeignError.create("Did not recognize" + (s + " as a value for SkewEnthusiasm. Expecting [  \"negative\" | \"neutral\" | \"positiveweak\" | \"positive\" ]")));
  };
  var rfSkewEnthusiasm = new Simple_JSON.ReadForeign(Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(skewEnthusiasm)(Data_Foreign.readString));
  var eqSkewEnthusiasm = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Dislike && y instanceof Dislike) {
              return true;
          };
          if (x instanceof Neutral && y instanceof Neutral) {
              return true;
          };
          if (x instanceof PositiveWeak && y instanceof PositiveWeak) {
              return true;
          };
          if (x instanceof Positive && y instanceof Positive) {
              return true;
          };
          return false;
      };
  });
  exports["Dislike"] = Dislike;
  exports["Neutral"] = Neutral;
  exports["PositiveWeak"] = PositiveWeak;
  exports["Positive"] = Positive;
  exports["skewEnthusiasm"] = skewEnthusiasm;
  exports["eqSkewEnthusiasm"] = eqSkewEnthusiasm;
  exports["rfSkewEnthusiasm"] = rfSkewEnthusiasm;
})(PS["MyBc.Recs.SkewEnthusiasm"] = PS["MyBc.Recs.SkewEnthusiasm"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Never = (function () {
      function Never() {

      };
      Never.value = new Never();
      return Never;
  })();
  var SuperYear = (function () {
      function SuperYear() {

      };
      SuperYear.value = new SuperYear();
      return SuperYear;
  })();
  var SubYear = (function () {
      function SubYear() {

      };
      SubYear.value = new SubYear();
      return SubYear;
  })();
  var timing = function (s) {
      var v = Data_String.toLower(s);
      if (v === "never") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Never.value);
      };
      if (v === "superyear") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(SuperYear.value);
      };
      if (v === "subyear") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(SubYear.value);
      };
      return Data_Foreign.fail(Data_Foreign.ForeignError.create("Did not recognize" + (s + " as a value for Timing. Expecting [  \"never\" | \"superyear\" | \"subyear\" ]")));
  };
  var rfTiming = new Simple_JSON.ReadForeign(Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(timing)(Data_Foreign.readString));
  var eqTiming = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Never && y instanceof Never) {
              return true;
          };
          if (x instanceof SuperYear && y instanceof SuperYear) {
              return true;
          };
          if (x instanceof SubYear && y instanceof SubYear) {
              return true;
          };
          return false;
      };
  });
  exports["Never"] = Never;
  exports["SuperYear"] = SuperYear;
  exports["SubYear"] = SubYear;
  exports["timing"] = timing;
  exports["eqTiming"] = eqTiming;
  exports["rfTiming"] = rfTiming;
})(PS["MyBc.Recs.Timing"] = PS["MyBc.Recs.Timing"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var MyBc_Recs_Benefit = PS["MyBc.Recs.Benefit"];
  var MyBc_Recs_Cohort = PS["MyBc.Recs.Cohort"];
  var MyBc_Recs_Concern = PS["MyBc.Recs.Concern"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var MyBc_Recs_Pick = PS["MyBc.Recs.Pick"];
  var MyBc_Recs_Rank = PS["MyBc.Recs.Rank"];
  var MyBc_Recs_SideEffect = PS["MyBc.Recs.SideEffect"];
  var MyBc_Recs_SkewBother = PS["MyBc.Recs.SkewBother"];
  var MyBc_Recs_SkewEnthusiasm = PS["MyBc.Recs.SkewEnthusiasm"];
  var MyBc_Recs_Timing = PS["MyBc.Recs.Timing"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Sb = function (x) {
      return x;
  };
  var Be = function (x) {
      return x;
  };
  var Bsm = function (x) {
      return x;
  };
  var select = function ($62) {
      return Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)(MyBc_Recs_Pick.pickAtleast(3)(Data_Newtype.unwrap(MyBc_Recs_Rank.newtypeRanked)($62)));
  };
  var sb = function (sideEffect) {
      return function (bother) {
          return {
              sideEffect: sideEffect,
              bother: bother
          };
      };
  };
  var rfBe = MyBc_Recs_SkewEnthusiasm.rfSkewEnthusiasm;
  var notperm = function ($63) {
      return !MyBc_Recs_Method.isPermanent($63);
  };
  var notcontra = function (cs) {
      return function (ms) {
          return function ($64) {
              return !MyBc_Recs_Concern.concernsMethodVeto(cs)(ms)($64);
          };
      };
  };
  var newtypeSb = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Sb);
  var sideEffectWeight = function ($65) {
      return (function (v) {
          if (v.sideEffect instanceof MyBc_Recs_SideEffect.Spotting && v.bother instanceof MyBc_Recs_SkewBother.Like) {
              return 3;
          };
          if (v.sideEffect instanceof MyBc_Recs_SideEffect.NoPeriod && v.bother instanceof MyBc_Recs_SkewBother.Like) {
              return 3;
          };
          if (v.sideEffect instanceof MyBc_Recs_SideEffect.WeightGain && v.bother instanceof MyBc_Recs_SkewBother.Like) {
              return 3;
          };
          if (v.sideEffect instanceof MyBc_Recs_SideEffect.HeavierPeriod) {
              return 0;
          };
          if (v.bother instanceof MyBc_Recs_SkewBother.Neutral) {
              return 0;
          };
          if (v.bother instanceof MyBc_Recs_SkewBother.NegativeWeak) {
              return -1 | 0;
          };
          if (v.bother instanceof MyBc_Recs_SkewBother.Negative) {
              return -3 | 0;
          };
          throw new Error("Failed pattern match at MyBc.Recs.BenefitSideEffect line 162, column 31 - line 170, column 48: " + [ v.constructor.name ]);
      })(Data_Newtype.unwrap(newtypeSb)($65));
  };
  var newtypeBsm = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Bsm);
  var newtypeBe = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Be);
  var methods = function (cs) {
      return function (ms) {
          return function (v) {
              if (v instanceof MyBc_Recs_Timing.Never) {
                  return Data_List_NonEmpty.fromList(Data_List_NonEmpty.filter(notcontra(cs)(ms))(MyBc_Recs_Method.allMethods));
              };
              return Data_List_NonEmpty.fromList(Data_List_NonEmpty.filter(Data_HeytingAlgebra.conj(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(notcontra(cs)(ms))(notperm))(MyBc_Recs_Method.allMethods));
          };
      };
  };
  var getSkewBother = function (v) {
      if (v instanceof MyBc_Recs_SideEffect.Spotting) {
          return function (v1) {
              return v1.spotting;
          };
      };
      if (v instanceof MyBc_Recs_SideEffect.NoPeriod) {
          return function (v1) {
              return v1.noPeriod;
          };
      };
      if (v instanceof MyBc_Recs_SideEffect.HeavierPeriod) {
          return function (v1) {
              return v1.heavierPeriod;
          };
      };
      if (v instanceof MyBc_Recs_SideEffect.WeightGain) {
          return function (v1) {
              return v1.weightGain;
          };
      };
      throw new Error("Failed pattern match at MyBc.Recs.BenefitSideEffect line 81, column 17 - line 85, column 32: " + [ v.constructor.name ]);
  };
  var getSb = function (se) {
      return function ($66) {
          return sb(se)(getSkewBother(se)($66));
      };
  };
  var getBe = function (b) {
      if (b instanceof MyBc_Recs_Benefit.DecreasedAcne) {
          return function (v) {
              return v.decreasedAcne;
          };
      };
      if (b instanceof MyBc_Recs_Benefit.NoPeriod) {
          return function (v) {
              return v.noPeriod;
          };
      };
      if (b instanceof MyBc_Recs_Benefit.DecreasedCramping) {
          return function (v) {
              return v.decreasedCramping;
          };
      };
      if (b instanceof MyBc_Recs_Benefit.DecreasedPeriod) {
          return function (v) {
              return v.decreasedPeriod;
          };
      };
      throw new Error("Failed pattern match at MyBc.Recs.BenefitSideEffect line 65, column 11 - line 69, column 42: " + [ b.constructor.name ]);
  };
  var eqBe = new Data_Eq.Eq(function (x) {
      return function (y) {
          return Data_Eq.eq(MyBc_Recs_SkewEnthusiasm.eqSkewEnthusiasm)(x)(y);
      };
  });
  var bsm = function (benefits) {
      return function (sideEffects) {
          return function (method) {
              return {
                  method: method,
                  benefits: benefits,
                  sideEffects: sideEffects
              };
          };
      };
  };
  var getBsm = function (bes) {
      return function (sebs) {
          return function (m) {
              var getSbs = function (sebs1) {
                  return function (m1) {
                      return Control_Apply.apply(Control_Apply.applyArray)(Data_Functor.map(Data_Functor.functorArray)(getSb)(MyBc_Recs_SideEffect.methodSideEffects(m1)))(Control_Applicative.pure(Control_Applicative.applicativeArray)(sebs1));
                  };
              };
              var getBes = function (bes1) {
                  return function (m1) {
                      return Control_Apply.apply(Control_Apply.applyArray)(Data_Functor.map(Data_Functor.functorArray)(getBe)(MyBc_Recs_Benefit.methodBenefits(m1)))(Control_Applicative.pure(Control_Applicative.applicativeArray)(bes1));
                  };
              };
              return bsm(getBes(bes)(m))(getSbs(sebs)(m))(m);
          };
      };
  };
  var benefitWeight = function ($67) {
      return (function (v) {
          if (v instanceof MyBc_Recs_SkewEnthusiasm.Dislike) {
              return -3 | 0;
          };
          if (v instanceof MyBc_Recs_SkewEnthusiasm.Neutral) {
              return 0;
          };
          if (v instanceof MyBc_Recs_SkewEnthusiasm.PositiveWeak) {
              return 1;
          };
          if (v instanceof MyBc_Recs_SkewEnthusiasm.Positive) {
              return 3;
          };
          throw new Error("Failed pattern match at MyBc.Recs.BenefitSideEffect line 141, column 28 - line 145, column 21: " + [ v.constructor.name ]);
      })(Data_Newtype.unwrap(newtypeBe)($67));
  };
  var bsmWeight = function (v) {
      var sideEffectsWeight = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(Data_Functor.map(Data_Functor.functorArray)(sideEffectWeight)(v.sideEffects));
      var benefitsWeight = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(Data_Functor.map(Data_Functor.functorArray)(benefitWeight)(v.benefits));
      return benefitsWeight + sideEffectsWeight | 0;
  };
  var eqBsm = new Data_Eq.Eq(function (l) {
      return function (r) {
          return bsmWeight(l) === bsmWeight(r);
      };
  });
  var ordBsm = new Data_Ord.Ord(function () {
      return eqBsm;
  }, function (l) {
      return function (r) {
          return Data_Ordering.invert(Data_Ord.compare(Data_Ord.ordInt)(bsmWeight(l))(bsmWeight(r)));
      };
  });
  var process = function (bes) {
      return function (sebs) {
          return function ($68) {
              return (function ($69) {
                  return (function ($70) {
                      return MyBc_Recs_Cohort.cohort(Data_Array.take(4)(Data_Array.sortWith(MyBc_Recs_Method.ordEffectiveness)(MyBc_Recs_Method.Effectiveness)($70)));
                  })(Data_Functor.map(Data_Functor.functorArray)(MyBc_Recs_Rank.toMethod(newtypeBsm))(select($69)));
              })(MyBc_Recs_Rank.rank(ordBsm)(Data_Functor.map(Data_List_Types.functorNonEmptyList)(getBsm(bes)(sebs))($68)));
          };
      };
  };
  var rankBenefitAndSideEffect = function (t) {
      return function (cs) {
          return function (ms) {
              return function (bes) {
                  return function (sebs) {
                      return Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(process(bes)(sebs))(methods(cs)(ms)(t)));
                  };
              };
          };
      };
  };
  exports["rankBenefitAndSideEffect"] = rankBenefitAndSideEffect;
  exports["methods"] = methods;
  exports["notperm"] = notperm;
  exports["notcontra"] = notcontra;
  exports["process"] = process;
  exports["select"] = select;
  exports["getBe"] = getBe;
  exports["getSkewBother"] = getSkewBother;
  exports["getSb"] = getSb;
  exports["Bsm"] = Bsm;
  exports["bsm"] = bsm;
  exports["bsmWeight"] = bsmWeight;
  exports["getBsm"] = getBsm;
  exports["Be"] = Be;
  exports["benefitWeight"] = benefitWeight;
  exports["Sb"] = Sb;
  exports["sb"] = sb;
  exports["sideEffectWeight"] = sideEffectWeight;
  exports["newtypeBsm"] = newtypeBsm;
  exports["eqBsm"] = eqBsm;
  exports["ordBsm"] = ordBsm;
  exports["newtypeBe"] = newtypeBe;
  exports["rfBe"] = rfBe;
  exports["eqBe"] = eqBe;
  exports["newtypeSb"] = newtypeSb;
})(PS["MyBc.Recs.BenefitSideEffect"] = PS["MyBc.Recs.BenefitSideEffect"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Identity = PS["Data.Identity"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Not = (function () {
      function Not() {

      };
      Not.value = new Not();
      return Not;
  })();
  var Somewhat = (function () {
      function Somewhat() {

      };
      Somewhat.value = new Somewhat();
      return Somewhat;
  })();
  var Very = (function () {
      function Very() {

      };
      Very.value = new Very();
      return Very;
  })();
  var importance = function (s) {
      var v = Data_String.toLower(s);
      if (v === "not") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Not.value);
      };
      if (v === "somewhat") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Somewhat.value);
      };
      if (v === "very") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Very.value);
      };
      return Data_Foreign.fail(Data_Foreign.ForeignError.create("Did not recognize" + (s + " as a value for Importance. Expecting [  \"not\" | \"somewhat\" | \"very\" ]")));
  };
  var rfImportance = new Simple_JSON.ReadForeign(Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(importance)(Data_Foreign.readString));
  var eqImportance = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Not && y instanceof Not) {
              return true;
          };
          if (x instanceof Somewhat && y instanceof Somewhat) {
              return true;
          };
          if (x instanceof Very && y instanceof Very) {
              return true;
          };
          return false;
      };
  });
  exports["Not"] = Not;
  exports["Somewhat"] = Somewhat;
  exports["Very"] = Very;
  exports["importance"] = importance;
  exports["eqImportance"] = eqImportance;
  exports["rfImportance"] = rfImportance;
})(PS["MyBc.Recs.Importance"] = PS["MyBc.Recs.Importance"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foldable = PS["Data.Foldable"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_List = PS["Data.List"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var MyBc_Recs_Cohort = PS["MyBc.Recs.Cohort"];
  var MyBc_Recs_Concern = PS["MyBc.Recs.Concern"];
  var MyBc_Recs_Importance = PS["MyBc.Recs.Importance"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var MyBc_Recs_Pick = PS["MyBc.Recs.Pick"];
  var MyBc_Recs_Rank = PS["MyBc.Recs.Rank"];
  var MyBc_Recs_Timing = PS["MyBc.Recs.Timing"];
  var Prelude = PS["Prelude"];
  var Etm = function (x) {
      return x;
  };
  var timingWeight = function (v) {
      return function (v1) {
          if (v instanceof MyBc_Recs_Timing.SubYear) {
              if (v1 instanceof MyBc_Recs_Method.IUD) {
                  return -2 | 0;
              };
              if (v1 instanceof MyBc_Recs_Method.Implant) {
                  return -2 | 0;
              };
              if (v1 instanceof MyBc_Recs_Method.Shot) {
                  return -2 | 0;
              };
              if (v1 instanceof MyBc_Recs_Method.Sterilization) {
                  return -3 | 0;
              };
              return 0;
          };
          if (v instanceof MyBc_Recs_Timing.SuperYear && v1 instanceof MyBc_Recs_Method.Sterilization) {
              return -3 | 0;
          };
          if (v instanceof MyBc_Recs_Timing.Never && v1 instanceof MyBc_Recs_Method.Sterilization) {
              return 3;
          };
          return 0;
      };
  };
  var select = function (t) {
      var toArray = function ($33) {
          return Control_Bind.join(Control_Bind.bindArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList))(Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)(Data_Newtype.unwrap(MyBc_Recs_Rank.newtypeRanked)($33))));
      };
      if (t instanceof MyBc_Recs_Timing.Never) {
          return function ($34) {
              return Data_Array.take(5)(toArray($34));
          };
      };
      return function ($35) {
          return Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)(MyBc_Recs_Pick.pickAtleast(3)(Data_Newtype.unwrap(MyBc_Recs_Rank.newtypeRanked)($35)));
      };
  };
  var notperm = function ($36) {
      return !MyBc_Recs_Method.isPermanent($36);
  };
  var notcontra = function (cs) {
      return function (ms) {
          return function ($37) {
              return !MyBc_Recs_Concern.concernsMethodVeto(cs)(ms)($37);
          };
      };
  };
  var newtypeEtm = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Etm);
  var methods = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof MyBc_Recs_Timing.Never) {
                  return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new Data_NonEmpty.NonEmpty(MyBc_Recs_Method.Sterilization.value, Data_List.fromFoldable(Data_Foldable.foldableArray)([ new MyBc_Recs_Method.IUD(MyBc_Recs_Method.Copper.value), new MyBc_Recs_Method.IUD(MyBc_Recs_Method.Hormonal.value), MyBc_Recs_Method.Implant.value ])));
              };
              return Data_List_NonEmpty.fromList(Data_List_NonEmpty.filter(Data_HeytingAlgebra.conj(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(notcontra(v)(v1))(notperm))(MyBc_Recs_Method.allMethods));
          };
      };
  };
  var etm = function (i) {
      return function (t) {
          return function (m) {
              return Etm({
                  effectiveness: i,
                  timing: t,
                  method: m
              });
          };
      };
  };
  var effectivenessWeight = function (v) {
      if (v instanceof MyBc_Recs_Importance.Not) {
          return function (v1) {
              if (v1 instanceof MyBc_Recs_Method.Condom) {
                  return 0;
              };
              if (v1 instanceof MyBc_Recs_Method.Diaphragm) {
                  return 0;
              };
              return 1;
          };
      };
      if (v instanceof MyBc_Recs_Importance.Somewhat) {
          return function (v1) {
              if (v1 instanceof MyBc_Recs_Method.IUD) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Implant) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Sterilization) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Pill) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Patch) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Ring) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Shot) {
                  return 1;
              };
              return 0;
          };
      };
      if (v instanceof MyBc_Recs_Importance.Very) {
          return function (v1) {
              if (v1 instanceof MyBc_Recs_Method.IUD) {
                  return 2;
              };
              if (v1 instanceof MyBc_Recs_Method.Implant) {
                  return 2;
              };
              if (v1 instanceof MyBc_Recs_Method.Sterilization) {
                  return 2;
              };
              if (v1 instanceof MyBc_Recs_Method.Pill) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Patch) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Ring) {
                  return 1;
              };
              if (v1 instanceof MyBc_Recs_Method.Shot) {
                  return 1;
              };
              return 0;
          };
      };
      throw new Error("Failed pattern match at MyBc.Recs.EffectivenessTiming line 84, column 1 - line 84, column 51: " + [ v.constructor.name ]);
  };
  var etmWeight = function (v) {
      return effectivenessWeight(v.effectiveness)(v.method) + timingWeight(v.timing)(v.method) | 0;
  };
  var eqEtm = new Data_Eq.Eq(function (l) {
      return function (r) {
          return etmWeight(l) === etmWeight(r);
      };
  });
  var ordEtm = new Data_Ord.Ord(function () {
      return eqEtm;
  }, function (l) {
      return function (r) {
          return Data_Ordering.invert(Data_Ord.compare(Data_Ord.ordInt)(etmWeight(l))(etmWeight(r)));
      };
  });
  var process = function (i) {
      return function (t) {
          return function ($38) {
              return (function ($39) {
                  return (function ($40) {
                      return Data_Array.take(4)(Data_Array.sortWith(MyBc_Recs_Method.ordEffectiveness)(MyBc_Recs_Method.Effectiveness)($40));
                  })(Data_Functor.map(Data_Functor.functorArray)(MyBc_Recs_Rank.toMethod(newtypeEtm))(select(t)($39)));
              })(MyBc_Recs_Rank.rank(ordEtm)(Data_Functor.map(Data_List_Types.functorNonEmptyList)(etm(i)(t))($38)));
          };
      };
  };
  var rankEffectivenessAndTiming = function (cs) {
      return function (ms) {
          return function (i) {
              return function (t) {
                  return Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(process(i)(t))(methods(cs)(ms)(t)));
              };
          };
      };
  };
  exports["rankEffectivenessAndTiming"] = rankEffectivenessAndTiming;
  exports["methods"] = methods;
  exports["process"] = process;
  exports["notperm"] = notperm;
  exports["notcontra"] = notcontra;
  exports["select"] = select;
  exports["Etm"] = Etm;
  exports["etm"] = etm;
  exports["etmWeight"] = etmWeight;
  exports["effectivenessWeight"] = effectivenessWeight;
  exports["timingWeight"] = timingWeight;
  exports["newtypeEtm"] = newtypeEtm;
  exports["eqEtm"] = eqEtm;
  exports["ordEtm"] = ordEtm;
})(PS["MyBc.Recs.EffectivenessTiming"] = PS["MyBc.Recs.EffectivenessTiming"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Eq = PS["Data.Eq"];
  var Data_Show = PS["Data.Show"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var Prelude = PS["Prelude"];
  var Oral = (function () {
      function Oral() {

      };
      Oral.value = new Oral();
      return Oral;
  })();
  var Topical = (function () {
      function Topical() {

      };
      Topical.value = new Topical();
      return Topical;
  })();
  var Vaginal = (function () {
      function Vaginal() {

      };
      Vaginal.value = new Vaginal();
      return Vaginal;
  })();
  var Penile = (function () {
      function Penile() {

      };
      Penile.value = new Penile();
      return Penile;
  })();
  var Injection = (function () {
      function Injection() {

      };
      Injection.value = new Injection();
      return Injection;
  })();
  var Uterine = (function () {
      function Uterine() {

      };
      Uterine.value = new Uterine();
      return Uterine;
  })();
  var Subcutaneous = (function () {
      function Subcutaneous() {

      };
      Subcutaneous.value = new Subcutaneous();
      return Subcutaneous;
  })();
  var Surgical = (function () {
      function Surgical() {

      };
      Surgical.value = new Surgical();
      return Surgical;
  })();
  var methodAdmin = function (v) {
      if (v instanceof MyBc_Recs_Method.Pill) {
          return Oral.value;
      };
      if (v instanceof MyBc_Recs_Method.Patch) {
          return Topical.value;
      };
      if (v instanceof MyBc_Recs_Method.Ring) {
          return Vaginal.value;
      };
      if (v instanceof MyBc_Recs_Method.Shot) {
          return Injection.value;
      };
      if (v instanceof MyBc_Recs_Method.IUD) {
          return Uterine.value;
      };
      if (v instanceof MyBc_Recs_Method.Implant) {
          return Subcutaneous.value;
      };
      if (v instanceof MyBc_Recs_Method.Sterilization) {
          return Surgical.value;
      };
      if (v instanceof MyBc_Recs_Method.Diaphragm) {
          return Vaginal.value;
      };
      if (v instanceof MyBc_Recs_Method.Condom && v.value0 instanceof MyBc_Recs_Method.Female) {
          return Vaginal.value;
      };
      if (v instanceof MyBc_Recs_Method.Condom && v.value0 instanceof MyBc_Recs_Method.Male) {
          return Penile.value;
      };
      throw new Error("Failed pattern match at MyBc.Recs.Admin line 25, column 15 - line 35, column 20: " + [ v.constructor.name ]);
  };
  exports["Oral"] = Oral;
  exports["Topical"] = Topical;
  exports["Vaginal"] = Vaginal;
  exports["Penile"] = Penile;
  exports["Injection"] = Injection;
  exports["Uterine"] = Uterine;
  exports["Subcutaneous"] = Subcutaneous;
  exports["Surgical"] = Surgical;
  exports["methodAdmin"] = methodAdmin;
})(PS["MyBc.Recs.Admin"] = PS["MyBc.Recs.Admin"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Data_Eq = PS["Data.Eq"];
  var Data_Show = PS["Data.Show"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var Prelude = PS["Prelude"];
  var Session = (function () {
      function Session() {

      };
      Session.value = new Session();
      return Session;
  })();
  var Day = (function () {
      function Day() {

      };
      Day.value = new Day();
      return Day;
  })();
  var Week = (function () {
      function Week() {

      };
      Week.value = new Week();
      return Week;
  })();
  var Month = (function () {
      function Month() {

      };
      Month.value = new Month();
      return Month;
  })();
  var Quarter = (function () {
      function Quarter() {

      };
      Quarter.value = new Quarter();
      return Quarter;
  })();
  var SuperYear = (function () {
      function SuperYear() {

      };
      SuperYear.value = new SuperYear();
      return SuperYear;
  })();
  var Permanent = (function () {
      function Permanent() {

      };
      Permanent.value = new Permanent();
      return Permanent;
  })();
  var methodFrequency = function (v) {
      if (v instanceof MyBc_Recs_Method.Condom) {
          return Session.value;
      };
      if (v instanceof MyBc_Recs_Method.Diaphragm) {
          return Session.value;
      };
      if (v instanceof MyBc_Recs_Method.Pill) {
          return Day.value;
      };
      if (v instanceof MyBc_Recs_Method.Patch) {
          return Week.value;
      };
      if (v instanceof MyBc_Recs_Method.Ring) {
          return Month.value;
      };
      if (v instanceof MyBc_Recs_Method.Shot) {
          return Quarter.value;
      };
      if (v instanceof MyBc_Recs_Method.IUD) {
          return SuperYear.value;
      };
      if (v instanceof MyBc_Recs_Method.Implant) {
          return SuperYear.value;
      };
      if (v instanceof MyBc_Recs_Method.Sterilization) {
          return Permanent.value;
      };
      throw new Error("Failed pattern match at MyBc.Recs.Frequency line 24, column 19 - line 33, column 20: " + [ v.constructor.name ]);
  };
  exports["Session"] = Session;
  exports["Day"] = Day;
  exports["Week"] = Week;
  exports["Month"] = Month;
  exports["Quarter"] = Quarter;
  exports["SuperYear"] = SuperYear;
  exports["Permanent"] = Permanent;
  exports["methodFrequency"] = methodFrequency;
})(PS["MyBc.Recs.Frequency"] = PS["MyBc.Recs.Frequency"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad_Except_Trans = PS["Control.Monad.Except.Trans"];
  var Data_Array = PS["Data.Array"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Identity = PS["Data.Identity"];
  var Data_List = PS["Data.List"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var MyBc_Recs_Admin = PS["MyBc.Recs.Admin"];
  var MyBc_Recs_Cohort = PS["MyBc.Recs.Cohort"];
  var MyBc_Recs_Concern = PS["MyBc.Recs.Concern"];
  var MyBc_Recs_Frequency = PS["MyBc.Recs.Frequency"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var MyBc_Recs_Pick = PS["MyBc.Recs.Pick"];
  var MyBc_Recs_Rank = PS["MyBc.Recs.Rank"];
  var MyBc_Recs_Timing = PS["MyBc.Recs.Timing"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Negative = (function () {
      function Negative() {

      };
      Negative.value = new Negative();
      return Negative;
  })();
  var Neutral = (function () {
      function Neutral() {

      };
      Neutral.value = new Neutral();
      return Neutral;
  })();
  var Positive = (function () {
      function Positive() {

      };
      Positive.value = new Positive();
      return Positive;
  })();
  var eqEnthusiasm = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Negative && y instanceof Negative) {
              return true;
          };
          if (x instanceof Neutral && y instanceof Neutral) {
              return true;
          };
          if (x instanceof Positive && y instanceof Positive) {
              return true;
          };
          return false;
      };
  });
  var enthusiasm = function (s) {
      var v = Data_String.toLower(s);
      if (v === "negative") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Negative.value);
      };
      if (v === "neutral") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Neutral.value);
      };
      if (v === "positive") {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Positive.value);
      };
      return Data_Foreign.fail(Data_Foreign.ForeignError.create("Did not recognize" + (s + " as a value for Enthusiasm. Expecting [  \"negative\" | \"neutral\" | \"positive\" ]")));
  };
  var rfEnthusiasm = new Simple_JSON.ReadForeign(Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(enthusiasm)(Data_Foreign.readString));
  exports["Negative"] = Negative;
  exports["Neutral"] = Neutral;
  exports["Positive"] = Positive;
  exports["enthusiasm"] = enthusiasm;
  exports["eqEnthusiasm"] = eqEnthusiasm;
  exports["rfEnthusiasm"] = rfEnthusiasm;
})(PS["MyBc.Recs.Enthusiasm"] = PS["MyBc.Recs.Enthusiasm"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Apply = PS["Control.Apply"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_List = PS["Data.List"];
  var Data_List_NonEmpty = PS["Data.List.NonEmpty"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_NonEmpty = PS["Data.NonEmpty"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Ring = PS["Data.Ring"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Semiring = PS["Data.Semiring"];
  var Data_Show = PS["Data.Show"];
  var Data_String = PS["Data.String"];
  var Data_Symbol = PS["Data.Symbol"];
  var MyBc_Recs_Admin = PS["MyBc.Recs.Admin"];
  var MyBc_Recs_Cohort = PS["MyBc.Recs.Cohort"];
  var MyBc_Recs_Concern = PS["MyBc.Recs.Concern"];
  var MyBc_Recs_Enthusiasm = PS["MyBc.Recs.Enthusiasm"];
  var MyBc_Recs_Frequency = PS["MyBc.Recs.Frequency"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var MyBc_Recs_Pick = PS["MyBc.Recs.Pick"];
  var MyBc_Recs_Rank = PS["MyBc.Recs.Rank"];
  var MyBc_Recs_Timing = PS["MyBc.Recs.Timing"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Type_Row = PS["Type.Row"];
  var Fe = function (x) {
      return x;
  };
  var FrequencyEnthusiasms = function (x) {
      return x;
  };
  var Ae = function (x) {
      return x;
  };
  var Fam = function (x) {
      return x;
  };
  var AdminEnthusiasms = function (x) {
      return x;
  };
  var rfFe = MyBc_Recs_Enthusiasm.rfEnthusiasm;
  var rfFrequencyEnthusiasms = Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "day";
  }))(rfFe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "month";
  }))(rfFe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "permanent";
  }))(rfFe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "quarter";
  }))(rfFe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "session";
  }))(rfFe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "superyear";
  }))(rfFe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "week";
  }))(rfFe)(Simple_JSON.readFieldsNil)(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))());
  var rfAe = MyBc_Recs_Enthusiasm.rfEnthusiasm;
  var rfAdminEnthusiasms = Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "injection";
  }))(rfAe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "oral";
  }))(rfAe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "penile";
  }))(rfAe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "subcutaneous";
  }))(rfAe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "surgical";
  }))(rfAe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "topical";
  }))(rfAe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "uterine";
  }))(rfAe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "vaginal";
  }))(rfAe)(Simple_JSON.readFieldsNil)(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))());
  var notperm = function ($110) {
      return !MyBc_Recs_Method.isPermanent($110);
  };
  var notcontra = function (cs) {
      return function (ms) {
          return function ($111) {
              return !MyBc_Recs_Concern.concernsMethodVeto(cs)(ms)($111);
          };
      };
  };
  var newtypeFreqEnth = new Data_Newtype.Newtype(function (n) {
      return n;
  }, FrequencyEnthusiasms);
  var newtypeFe = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Fe);
  var newtypeFam = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Fam);
  var newtypeAe = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Ae);
  var newtypeAdminEnths = new Data_Newtype.Newtype(function (n) {
      return n;
  }, AdminEnthusiasms);
  var methods = function (v) {
      return function (cs) {
          return function (ms) {
              if (v instanceof MyBc_Recs_Timing.Never) {
                  return Data_List_NonEmpty.fromList(Data_List_NonEmpty.filter(notcontra(cs)(ms))(MyBc_Recs_Method.allMethods));
              };
              return Data_List_NonEmpty.fromList(Data_List_NonEmpty.filter(Data_HeytingAlgebra.conj(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(notcontra(cs)(ms))(notperm))(MyBc_Recs_Method.allMethods));
          };
      };
  };
  var limitWeight = function (top) {
      var v = Data_Ord.compare(Data_Ord.ordInt)(top)(0);
      if (v instanceof Data_Ordering.GT) {
          return Data_Ord.max(Data_Ord.ordInt)(top - 3 | 0)(1);
      };
      if (v instanceof Data_Ordering.EQ) {
          return Data_Ord.max(Data_Ord.ordInt)(top - 3 | 0)(0);
      };
      if (v instanceof Data_Ordering.LT) {
          return top;
      };
      throw new Error("Failed pattern match at MyBc.Recs.FrequencyAdmin line 64, column 19 - line 68, column 12: " + [ v.constructor.name ]);
  };
  var getFreq = function (m) {
      return function ($112) {
          return (function () {
              var v = MyBc_Recs_Frequency.methodFrequency(m);
              if (v instanceof MyBc_Recs_Frequency.Session) {
                  return function (v1) {
                      return v1.session;
                  };
              };
              if (v instanceof MyBc_Recs_Frequency.Day) {
                  return function (v1) {
                      return v1.day;
                  };
              };
              if (v instanceof MyBc_Recs_Frequency.Week) {
                  return function (v1) {
                      return v1.week;
                  };
              };
              if (v instanceof MyBc_Recs_Frequency.Month) {
                  return function (v1) {
                      return v1.month;
                  };
              };
              if (v instanceof MyBc_Recs_Frequency.Quarter) {
                  return function (v1) {
                      return v1.quarter;
                  };
              };
              if (v instanceof MyBc_Recs_Frequency.SuperYear) {
                  return function (v1) {
                      return v1.superyear;
                  };
              };
              if (v instanceof MyBc_Recs_Frequency.Permanent) {
                  return function (v1) {
                      return v1.permanent;
                  };
              };
              throw new Error("Failed pattern match at MyBc.Recs.FrequencyAdmin line 175, column 24 - line 182, column 27: " + [ v.constructor.name ]);
          })()(Data_Newtype.unwrap(newtypeFreqEnth)($112));
      };
  };
  var getAdmin = function (m) {
      return function ($113) {
          return (function () {
              var v = MyBc_Recs_Admin.methodAdmin(m);
              if (v instanceof MyBc_Recs_Admin.Oral) {
                  return function (v1) {
                      return v1.oral;
                  };
              };
              if (v instanceof MyBc_Recs_Admin.Topical) {
                  return function (v1) {
                      return v1.topical;
                  };
              };
              if (v instanceof MyBc_Recs_Admin.Vaginal) {
                  return function (v1) {
                      return v1.vaginal;
                  };
              };
              if (v instanceof MyBc_Recs_Admin.Penile) {
                  return function (v1) {
                      return v1.penile;
                  };
              };
              if (v instanceof MyBc_Recs_Admin.Injection) {
                  return function (v1) {
                      return v1.injection;
                  };
              };
              if (v instanceof MyBc_Recs_Admin.Uterine) {
                  return function (v1) {
                      return v1.uterine;
                  };
              };
              if (v instanceof MyBc_Recs_Admin.Subcutaneous) {
                  return function (v1) {
                      return v1.subcutaneous;
                  };
              };
              if (v instanceof MyBc_Recs_Admin.Surgical) {
                  return function (v1) {
                      return v1.surgical;
                  };
              };
              throw new Error("Failed pattern match at MyBc.Recs.FrequencyAdmin line 219, column 25 - line 227, column 25: " + [ v.constructor.name ]);
          })()(Data_Newtype.unwrap(newtypeAdminEnths)($113));
      };
  };
  var fam = function (globalfe) {
      return function (frequency) {
          return function (globalae) {
              return function (admin) {
                  return function (method) {
                      return {
                          globalfe: globalfe,
                          frequency: frequency,
                          globalae: globalae,
                          admin: admin,
                          method: method
                      };
                  };
              };
          };
      };
  };
  var getFam = function (ae1) {
      return function (fe1) {
          return function (aes1) {
              return function (fes1) {
                  return function (m) {
                      return fam(fe1)(getFreq(m)(fes1))(ae1)(getAdmin(m)(aes1))(m);
                  };
              };
          };
      };
  };
  var eqFe = new Data_Eq.Eq(function (x) {
      return function (y) {
          return Data_Eq.eq(MyBc_Recs_Enthusiasm.eqEnthusiasm)(x)(y);
      };
  });
  var eqFrequencyEnthusiasms = new Data_Eq.Eq(function (x) {
      return function (y) {
          return Data_Eq.eq(eqFe)(x.day)(y.day) && Data_Eq.eq(eqFe)(x.month)(y.month) && Data_Eq.eq(eqFe)(x.permanent)(y.permanent) && Data_Eq.eq(eqFe)(x.quarter)(y.quarter) && Data_Eq.eq(eqFe)(x.session)(y.session) && Data_Eq.eq(eqFe)(x.superyear)(y.superyear) && Data_Eq.eq(eqFe)(x.week)(y.week);
      };
  });
  var eqAe = new Data_Eq.Eq(function (x) {
      return function (y) {
          return Data_Eq.eq(MyBc_Recs_Enthusiasm.eqEnthusiasm)(x)(y);
      };
  });
  var eqAdminEnthusiasms = new Data_Eq.Eq(function (x) {
      return function (y) {
          return Data_Eq.eq(eqAe)(x.injection)(y.injection) && Data_Eq.eq(eqAe)(x.oral)(y.oral) && Data_Eq.eq(eqAe)(x.penile)(y.penile) && Data_Eq.eq(eqAe)(x.subcutaneous)(y.subcutaneous) && Data_Eq.eq(eqAe)(x.surgical)(y.surgical) && Data_Eq.eq(eqAe)(x.topical)(y.topical) && Data_Eq.eq(eqAe)(x.uterine)(y.uterine) && Data_Eq.eq(eqAe)(x.vaginal)(y.vaginal);
      };
  });
  var enthusiasmWeight = function (v) {
      if (v instanceof MyBc_Recs_Enthusiasm.Negative) {
          return -1 | 0;
      };
      if (v instanceof MyBc_Recs_Enthusiasm.Neutral) {
          return 0;
      };
      if (v instanceof MyBc_Recs_Enthusiasm.Positive) {
          return 1;
      };
      throw new Error("Failed pattern match at MyBc.Recs.FrequencyAdmin line 79, column 1 - line 79, column 38: " + [ v.constructor.name ]);
  };
  var enthusiasmScale = function (v) {
      if (v instanceof MyBc_Recs_Enthusiasm.Negative) {
          return 1;
      };
      if (v instanceof MyBc_Recs_Enthusiasm.Neutral) {
          return 2;
      };
      if (v instanceof MyBc_Recs_Enthusiasm.Positive) {
          return 3;
      };
      throw new Error("Failed pattern match at MyBc.Recs.FrequencyAdmin line 86, column 19 - line 93, column 1: " + [ v.constructor.name ]);
  };
  var famWeight = function (v) {
      var globalfe = Data_Newtype.unwrap(newtypeFe)(v.globalfe);
      var globalae = Data_Newtype.unwrap(newtypeAe)(v.globalae);
      var freq = Data_Newtype.unwrap(newtypeFe)(v.frequency);
      var admin = Data_Newtype.unwrap(newtypeAe)(v.admin);
      return (enthusiasmScale(globalfe) * enthusiasmWeight(freq) | 0) + (enthusiasmScale(globalae) * enthusiasmWeight(admin) | 0) | 0;
  };
  var eqFam = new Data_Eq.Eq(function (l) {
      return function (r) {
          return famWeight(l) === famWeight(r);
      };
  });
  var ordFam = new Data_Ord.Ord(function () {
      return eqFam;
  }, function (l) {
      return function (r) {
          return Data_Ordering.invert(Data_Ord.compare(Data_Ord.ordInt)(famWeight(l))(famWeight(r)));
      };
  });
  var rowWeight = function ($114) {
      return famWeight(Data_List_NonEmpty.head($114));
  };
  var filterWeightGe = function (val) {
      return function (fams) {
          var scoreGe = function (i) {
              return function (fams1) {
                  return rowWeight(fams1) >= i;
              };
          };
          var filtertail = function (fn) {
              return function (aafams) {
                  return Data_Newtype.wrap(Data_List_Types.newtypeNonEmptyList)(new Data_NonEmpty.NonEmpty(Data_List_NonEmpty.head(aafams), Data_List.filter(fn)(Data_List_NonEmpty.tail(aafams))));
              };
          };
          return Data_Newtype.over(MyBc_Recs_Rank.newtypeRanked)(MyBc_Recs_Rank.newtypeRanked)(MyBc_Recs_Rank.Ranked)(filtertail(scoreGe(val)))(fams);
      };
  };
  var topWeight = function ($115) {
      return rowWeight(Data_List_NonEmpty.head(Data_Newtype.unwrap(MyBc_Recs_Rank.newtypeRanked)($115)));
  };
  var select = function (ranked) {
      return MyBc_Recs_Pick.pickAtleast(3)(Data_Newtype.unwrap(MyBc_Recs_Rank.newtypeRanked)(filterWeightGe(limitWeight(topWeight(ranked)))(ranked)));
  };
  var process = function (ae1) {
      return function (fe1) {
          return function (aes1) {
              return function (fes1) {
                  return function ($116) {
                      return (function ($117) {
                          return (function ($118) {
                              return Data_Array.take(4)(Data_Array.sortWith(MyBc_Recs_Method.ordEffectiveness)(MyBc_Recs_Method.Effectiveness)(Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)($118)));
                          })(Data_Functor.map(Data_List_Types.functorNonEmptyList)(MyBc_Recs_Rank.toMethod(newtypeFam))(select($117)));
                      })(MyBc_Recs_Rank.rank(ordFam)(Data_Functor.map(Data_List_Types.functorNonEmptyList)(getFam(ae1)(fe1)(aes1)(fes1))($116)));
                  };
              };
          };
      };
  };
  var rankFrequencyAndAdmin = function (t) {
      return function (cs) {
          return function (ms) {
              return function (ae1) {
                  return function (fe1) {
                      return function (aes1) {
                          return function (fes1) {
                              return Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(process(ae1)(fe1)(aes1)(fes1))(methods(t)(cs)(ms)));
                          };
                      };
                  };
              };
          };
      };
  };
  exports["rankFrequencyAndAdmin"] = rankFrequencyAndAdmin;
  exports["methods"] = methods;
  exports["notperm"] = notperm;
  exports["notcontra"] = notcontra;
  exports["process"] = process;
  exports["select"] = select;
  exports["topWeight"] = topWeight;
  exports["rowWeight"] = rowWeight;
  exports["limitWeight"] = limitWeight;
  exports["filterWeightGe"] = filterWeightGe;
  exports["enthusiasmWeight"] = enthusiasmWeight;
  exports["enthusiasmScale"] = enthusiasmScale;
  exports["Ae"] = Ae;
  exports["Fe"] = Fe;
  exports["Fam"] = Fam;
  exports["fam"] = fam;
  exports["famWeight"] = famWeight;
  exports["FrequencyEnthusiasms"] = FrequencyEnthusiasms;
  exports["getFreq"] = getFreq;
  exports["AdminEnthusiasms"] = AdminEnthusiasms;
  exports["getAdmin"] = getAdmin;
  exports["getFam"] = getFam;
  exports["newtypeAe"] = newtypeAe;
  exports["eqAe"] = eqAe;
  exports["rfAe"] = rfAe;
  exports["newtypeFe"] = newtypeFe;
  exports["eqFe"] = eqFe;
  exports["rfFe"] = rfFe;
  exports["newtypeFam"] = newtypeFam;
  exports["eqFam"] = eqFam;
  exports["ordFam"] = ordFam;
  exports["newtypeFreqEnth"] = newtypeFreqEnth;
  exports["eqFrequencyEnthusiasms"] = eqFrequencyEnthusiasms;
  exports["rfFrequencyEnthusiasms"] = rfFrequencyEnthusiasms;
  exports["newtypeAdminEnths"] = newtypeAdminEnths;
  exports["eqAdminEnthusiasms"] = eqAdminEnthusiasms;
  exports["rfAdminEnthusiasms"] = rfAdminEnthusiasms;
})(PS["MyBc.Recs.FrequencyAdmin"] = PS["MyBc.Recs.FrequencyAdmin"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Function = PS["Data.Function"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var MyBc_Recs_Concern = PS["MyBc.Recs.Concern"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var MyBc_Recs_Timing = PS["MyBc.Recs.Timing"];
  var Prelude = PS["Prelude"];
  var recommended = function (et) {
      return function (fa) {
          return function (bs) {
              return Data_Array.nub(MyBc_Recs_Method.eqMethod)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(et)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(fa)(bs)));
          };
      };
  };
  var notperm = function ($5) {
      return !MyBc_Recs_Method.isPermanent($5);
  };
  var notcontra = function (cs) {
      return function (ms) {
          return function ($6) {
              return !MyBc_Recs_Concern.concernsMethodVeto(cs)(ms)($6);
          };
      };
  };
  var removed = function (v) {
      return function (cs) {
          return function (ms) {
              if (v instanceof MyBc_Recs_Timing.Never) {
                  return notcontra(cs)(ms);
              };
              return Data_HeytingAlgebra.conj(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(notcontra(cs)(ms))(notperm);
          };
      };
  };
  var methods = function (cs) {
      return function (ms) {
          var $4 = MyBc_Recs_Concern.estrogen(cs)(ms);
          if ($4) {
              return Data_List_Types.nelCons(new MyBc_Recs_Method.Pill(MyBc_Recs_Method.Progestin.value))(MyBc_Recs_Method.allMethods);
          };
          return MyBc_Recs_Method.allMethods;
      };
  };
  var others = function (t) {
      return function (cs) {
          return function (ms) {
              return function (et) {
                  return function (fa) {
                      return function (bs) {
                          return Data_Array.sortWith(MyBc_Recs_Method.ordEffectiveness)(MyBc_Recs_Method.Effectiveness)(Data_Array.filter(removed(t)(cs)(ms))(Data_Array.difference(MyBc_Recs_Method.eqMethod)(Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)(methods(cs)(ms)))(recommended(et)(fa)(bs))));
                      };
                  };
              };
          };
      };
  };
  exports["others"] = others;
  exports["methods"] = methods;
  exports["recommended"] = recommended;
  exports["removed"] = removed;
  exports["notcontra"] = notcontra;
  exports["notperm"] = notperm;
})(PS["MyBc.Recs.Others"] = PS["MyBc.Recs.Others"] || {});
(function(exports) {
  // Generated by purs version 0.11.7
  "use strict";
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Array = PS["Data.Array"];
  var Data_Either = PS["Data.Either"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Foreign = PS["Data.Foreign"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_HeytingAlgebra = PS["Data.HeytingAlgebra"];
  var Data_List_Types = PS["Data.List.Types"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Symbol = PS["Data.Symbol"];
  var MyBc_Recs_BenefitSideEffect = PS["MyBc.Recs.BenefitSideEffect"];
  var MyBc_Recs_Concern = PS["MyBc.Recs.Concern"];
  var MyBc_Recs_EffectivenessTiming = PS["MyBc.Recs.EffectivenessTiming"];
  var MyBc_Recs_FrequencyAdmin = PS["MyBc.Recs.FrequencyAdmin"];
  var MyBc_Recs_Importance = PS["MyBc.Recs.Importance"];
  var MyBc_Recs_Method = PS["MyBc.Recs.Method"];
  var MyBc_Recs_Others = PS["MyBc.Recs.Others"];
  var MyBc_Recs_SkewBother = PS["MyBc.Recs.SkewBother"];
  var MyBc_Recs_Timing = PS["MyBc.Recs.Timing"];
  var Prelude = PS["Prelude"];
  var Simple_JSON = PS["Simple.JSON"];
  var Type_Row = PS["Type.Row"];
  var Output = function (x) {
      return x;
  };
  var Input = function (x) {
      return x;
  };
  var rfOutput = Simple_JSON.recordWriteForeign()(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
      return "benefitAndSideEffect";
  }))(Simple_JSON.writeForeignArray(MyBc_Recs_Method.writeForeign))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
      return "effectivenessAndTiming";
  }))(Simple_JSON.writeForeignArray(MyBc_Recs_Method.writeForeign))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
      return "frequencyAndAdmin";
  }))(Simple_JSON.writeForeignArray(MyBc_Recs_Method.writeForeign))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
      return "healthConcerns";
  }))(Simple_JSON.writeForeignArray(MyBc_Recs_Method.writeForeign))(Simple_JSON.consWriteForeignFields(new Data_Symbol.IsSymbol(function () {
      return "others";
  }))(Simple_JSON.writeForeignArray(MyBc_Recs_Method.writeForeign))(Simple_JSON.nilWriteForeignFields)()(Type_Row.rowLacks()()()(Type_Row.rowLacking))())()(Type_Row.rowLacks()()()(Type_Row.rowLacking))())()(Type_Row.rowLacks()()()(Type_Row.rowLacking))())()(Type_Row.rowLacks()()()(Type_Row.rowLacking))())()(Type_Row.rowLacks()()()(Type_Row.rowLacking))());
  var rfInput = Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "admin";
  }))(MyBc_Recs_FrequencyAdmin.rfAe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "adminEnthusiasms";
  }))(MyBc_Recs_FrequencyAdmin.rfAdminEnthusiasms)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "benefitEnthusiasms";
  }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "decreasedAcne";
  }))(MyBc_Recs_BenefitSideEffect.rfBe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "decreasedCramping";
  }))(MyBc_Recs_BenefitSideEffect.rfBe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "decreasedPeriod";
  }))(MyBc_Recs_BenefitSideEffect.rfBe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "noPeriod";
  }))(MyBc_Recs_BenefitSideEffect.rfBe)(Simple_JSON.readFieldsNil)(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "concerns";
  }))(Simple_JSON.readArray(MyBc_Recs_Concern.rfConcern))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "effectiveness";
  }))(MyBc_Recs_Importance.rfImportance)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "frequency";
  }))(MyBc_Recs_FrequencyAdmin.rfFe)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "frequencyEnthusiasms";
  }))(MyBc_Recs_FrequencyAdmin.rfFrequencyEnthusiasms)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "migraine";
  }))(Simple_JSON.readArray(MyBc_Recs_Concern.rfSymptomMigraine))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "sideEffectBothers";
  }))(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "heavierPeriod";
  }))(MyBc_Recs_SkewBother.rfSkewBother)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "noPeriod";
  }))(MyBc_Recs_SkewBother.rfSkewBother)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "spotting";
  }))(MyBc_Recs_SkewBother.rfSkewBother)(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "weightGain";
  }))(MyBc_Recs_SkewBother.rfSkewBother)(Simple_JSON.readFieldsNil)(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))()))(Simple_JSON.readFieldsCons(new Data_Symbol.IsSymbol(function () {
      return "timing";
  }))(MyBc_Recs_Timing.rfTiming)(Simple_JSON.readFieldsNil)(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))())(Type_Row.rowLacks()()()(Type_Row.rowLacking))());
  var recs = (function () {
      var process = function (v) {
          var frequencyAndAdmin = MyBc_Recs_FrequencyAdmin.rankFrequencyAndAdmin(v.timing)(v.concerns)(v.migraine)(v.admin)(v.frequency)(v.adminEnthusiasms)(v.frequencyEnthusiasms);
          var effectivenessAndTiming = MyBc_Recs_EffectivenessTiming.rankEffectivenessAndTiming(v.concerns)(v.migraine)(v.effectiveness)(v.timing);
          var benefitAndSideEffect = MyBc_Recs_BenefitSideEffect.rankBenefitAndSideEffect(v.timing)(v.concerns)(v.migraine)(v.benefitEnthusiasms)(v.sideEffectBothers);
          return {
              effectivenessAndTiming: effectivenessAndTiming,
              frequencyAndAdmin: frequencyAndAdmin,
              benefitAndSideEffect: benefitAndSideEffect,
              others: MyBc_Recs_Others.others(v.timing)(v.concerns)(v.migraine)(effectivenessAndTiming)(frequencyAndAdmin)(benefitAndSideEffect),
              healthConcerns: Data_Array.filter(MyBc_Recs_Concern.concernsMethodVeto(v.concerns)(v.migraine))(Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)(MyBc_Recs_Method.allMethods))
          };
      };
      return function ($20) {
          return Data_Either.either(Simple_JSON.write(Simple_JSON.writeForeignNonEmptyList(Simple_JSON.writeForeignForeignError)))(Simple_JSON.write(rfOutput))(Data_Functor.map(Data_Either.functorEither)(process)(Simple_JSON.read(rfInput)($20)));
      };
  })();
  var newtypeOutput = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Output);
  var newtypeInput = new Data_Newtype.Newtype(function (n) {
      return n;
  }, Input);
  var eqOutput = new Data_Eq.Eq(function (x) {
      return function (y) {
          return Data_Eq.eq(Data_Eq.eqArray(MyBc_Recs_Method.eqMethod))(x.benefitAndSideEffect)(y.benefitAndSideEffect) && Data_Eq.eq(Data_Eq.eqArray(MyBc_Recs_Method.eqMethod))(x.effectivenessAndTiming)(y.effectivenessAndTiming) && Data_Eq.eq(Data_Eq.eqArray(MyBc_Recs_Method.eqMethod))(x.frequencyAndAdmin)(y.frequencyAndAdmin) && Data_Eq.eq(Data_Eq.eqArray(MyBc_Recs_Method.eqMethod))(x.healthConcerns)(y.healthConcerns) && Data_Eq.eq(Data_Eq.eqArray(MyBc_Recs_Method.eqMethod))(x.others)(y.others);
      };
  });
  var eqInput = new Data_Eq.Eq(function (x) {
      return function (y) {
          return Data_Eq.eq(MyBc_Recs_FrequencyAdmin.eqAe)(x.admin)(y.admin) && Data_Eq.eq(MyBc_Recs_FrequencyAdmin.eqAdminEnthusiasms)(x.adminEnthusiasms)(y.adminEnthusiasms) && (Data_Eq.eq(MyBc_Recs_BenefitSideEffect.eqBe)(x.benefitEnthusiasms.decreasedAcne)(y.benefitEnthusiasms.decreasedAcne) && Data_Eq.eq(MyBc_Recs_BenefitSideEffect.eqBe)(x.benefitEnthusiasms.decreasedCramping)(y.benefitEnthusiasms.decreasedCramping) && Data_Eq.eq(MyBc_Recs_BenefitSideEffect.eqBe)(x.benefitEnthusiasms.decreasedPeriod)(y.benefitEnthusiasms.decreasedPeriod) && Data_Eq.eq(MyBc_Recs_BenefitSideEffect.eqBe)(x.benefitEnthusiasms.noPeriod)(y.benefitEnthusiasms.noPeriod)) && Data_Eq.eq(Data_Eq.eqArray(MyBc_Recs_Concern.eqConcern))(x.concerns)(y.concerns) && Data_Eq.eq(MyBc_Recs_Importance.eqImportance)(x.effectiveness)(y.effectiveness) && Data_Eq.eq(MyBc_Recs_FrequencyAdmin.eqFe)(x.frequency)(y.frequency) && Data_Eq.eq(MyBc_Recs_FrequencyAdmin.eqFrequencyEnthusiasms)(x.frequencyEnthusiasms)(y.frequencyEnthusiasms) && Data_Eq.eq(Data_Eq.eqArray(MyBc_Recs_Concern.eqSymptomMigraine))(x.migraine)(y.migraine) && (Data_Eq.eq(MyBc_Recs_SkewBother.eqSkewBother)(x.sideEffectBothers.heavierPeriod)(y.sideEffectBothers.heavierPeriod) && Data_Eq.eq(MyBc_Recs_SkewBother.eqSkewBother)(x.sideEffectBothers.noPeriod)(y.sideEffectBothers.noPeriod) && Data_Eq.eq(MyBc_Recs_SkewBother.eqSkewBother)(x.sideEffectBothers.spotting)(y.sideEffectBothers.spotting) && Data_Eq.eq(MyBc_Recs_SkewBother.eqSkewBother)(x.sideEffectBothers.weightGain)(y.sideEffectBothers.weightGain)) && Data_Eq.eq(MyBc_Recs_Timing.eqTiming)(x.timing)(y.timing);
      };
  });
  exports["Input"] = Input;
  exports["Output"] = Output;
  exports["recs"] = recs;
  exports["newtypeInput"] = newtypeInput;
  exports["eqInput"] = eqInput;
  exports["rfInput"] = rfInput;
  exports["newtypeOutput"] = newtypeOutput;
  exports["eqOutput"] = eqOutput;
  exports["rfOutput"] = rfOutput;
})(PS["MyBc.Recs"] = PS["MyBc.Recs"] || {});
module.exports = PS["MyBc.Recs"];

},{}]},{},[1])(1)
});
