// @flow

import React from 'react';
import { connect } from 'react-redux';
import Instruction from '../note/Instruction';
import { isMyPath } from '../../../lib/appMode';
import type { Action, State } from '../../../lib/types';
import './Topic.scss';

type Props = {
  selected: boolean,
  topic: string,
  shortForm: boolean, // true = don't include the sentence around topic, e.g. "added to my summary"
  onClick: () => void,
  hideInstructions: () => void,
  ariaHidden?: boolean,
  isFirst: boolean,
  isShowingTopicModal: boolean,
};

const Topic = ({
  topic,
  selected,
  shortForm,
  hideInstructions,
  ariaHidden = false,
  isFirst,
  isShowingTopicModal,
  onClick,
}: Props): React.Element<'div'> => {
  let before = '';
  let after = '';
  if (!shortForm) {
    if (selected) {
      after = 'added to my summary';
    } else {
      before = 'Add';
      after = 'to my summary';
    }
  }

  const clickTopic = () => { onClick(topic, selected); };

  const isShowingTopicInstructions = (isMyPath && isShowingTopicModal && isFirst);

  return (
    <div className={`topic-container ${selected ? 'selected' : ''}`}>
      {
        isShowingTopicInstructions && (
          <Instruction
            body="Tap to add this to your summary page to discuss with your provider."
            key="instructions"
            onCloseClick={hideInstructions}
            visible
            isTopicInstruction
          />
        )
      }
      <button
        aria-checked={selected}
        role="checkbox"
        tabIndex={ariaHidden ? null : '0'}
        aria-hidden={ariaHidden}
        aria-label={`${before} ${topic} ${after}`}
        className="topic-button"
        onClick={clickTopic}
        type="button"
      >
        {before} <em>{topic}</em> {after}
      </button>
    </div>
  );
};

const mapStateToProps = (state: State, ownProps: Props): TopicState => ({
  selected: state.topicReducer.topics.includes(ownProps.topic),
  isShowingTopicModal: state.isShowingTopicModal
});

const mapDispatchToProps = {
  onClick: (topic: string): Action => ({ type: 'TOGGLE_TOPIC', topic }),
  hideInstructions: (): Action => ({ type: 'HIDE_TOPIC_MODAL' })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Topic);
