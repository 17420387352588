// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import NavButton from '../../shared/nav/NavButton';
import MenuItem from '../../shared/menuItem/MenuItem';
import afterBabyActions from '../../../actions/afterBabyActions';
import type { PageProps } from '../../../lib/types';
import './Breastfeeding.scss';

type BreastfeedingProps = PageProps & {
  afterBabyTopics: {},
  navLinks: Array<string>,
};

class Breastfeeding extends React.Component<BreastfeedingProps, State> {
  constructor(props) {
    super(props);
    this.state = { visibleModal: null };
  }

  menuItemRef = (c: HTMLButtonElement) => { this._menuItem = c; }

  showModal = (item: string) => {
    const { onModalShow } = this.props;
    this.setState({ visibleModal: item });
    if (onModalShow) { onModalShow(item); }
  };

  hideModal = () => {
    this.setState({ visibleModal: null });
  }

  render() {
    const { content, locale, afterBabyTopics, isAnyModalVisible, navLinks } = this.props;
    const { visibleModal } = this.state;

    const menuItemProps = (item: string): {} => ({
      question: content[`${item}_topic`],
      modalCopy: content[`${item}_modal_copy`],
      modalVisible: visibleModal === item,
      ariaHidden: isAnyModalVisible,
      onModalShow: () => { this.showModal(item); },
      onModalHide: this.hideModal,
      visited: !!afterBabyTopics[item],
      buttonText: content.buttonText,
      content,
      locale,
    });

    return (
      <>
        <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
        <div className="flex-group">
          <div className="image-space">
            {/* <div className="nav-buttons-container">
              <NavButton
                str={content.navigation.backText}
                to={navLinks[0]}
                isHidden={isAnyModalVisible}
              />
              <NavButton
                str={content.navigation.nextText}
                to={navLinks[1]}
                isHidden={isAnyModalVisible}
              />
            </div> */}
          </div>
          <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
          <MenuItem {...menuItemProps('breastfeeding_resources')} className="first" />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ afterBabyTopics }: StateType): State => ({ afterBabyTopics });

const mapDispatchToProps = afterBabyActions;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Breastfeeding);
