// @flow

const initialState = true;

const summaryReminderReducer = (state: boolean = initialState, action: Object): NotesState => {
  switch (action.type) {
    case 'SET_SUMMARY_REMINDER_HIDDEN':
      return false;
    case 'SECRET_PREFILL_SURVEY':
      return true;
    default:
      return state;
  }
};

export default summaryReminderReducer;
