/* eslint-disable */
// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import ifvisible from 'ifvisible';
import NavButton from './nav/NavButton';
import SurveyActions from '../../actions/SurveyActions';
import NavActions from '../../actions/navActions';
import SecretPrefillActions from '../../actions/secretPrefillActions';
import './SecretDeveloperPage.scss';
import { isMyBCPeripartum, isMyPath, isSuperDev } from '../../lib/appMode';

const sharedRoutes = [
  'main_nav',
  'effectiveness',
  'iud_info',
  'side_effects',
  'side_effects_per_method',
  'how_to_women',
  'how_to_men',
  'emergency_contraception',
  'fertility_awareness',
  'how_often',
  'fertility_future',
  'survey1_mbc',
  'survey2_mbc',
  'survey3_mbc',
  'survey4_mbc',
  'survey5_mbc',
  'recommendations',
  'compare_methods',
  'summary'
];

const routeKeys = {
  myPathRoutes: [
    'intro_mp',
    'main_nav_mp',
    'survey_mp',
    'current_methods',
    'current_methods_survey',
    'fertility',
    'health_before_pregnancy',
    'health_before_pregnancy_topics',
    ...sharedRoutes
  ],
  myBCRoutes: [
    'intro',
    'side_effect_preface',
    'got_questions',
    ...sharedRoutes
  ],
  perpartumRoutes: [
    'intro',
    'sex_after_baby',
    'pregnant_again_first',
    'pregnant_again_second',
    'breastfeeding',
    'important_information',
    'considerations',
    'side_effect_preface',
    'got_questions',
    ...sharedRoutes
  ],
};

let routes = routeKeys.myBCRoutes;
if (isMyPath) routes = routeKeys.myPathRoutes;
else if (isMyBCPeripartum) routes = routeKeys.perpartumRoutes;
else if (isSuperDev) {
  routes = [...routeKeys.perpartumRoutes, ...routeKeys.myPathRoutes].filter((v: string, i: number, a: [string]): boolean => a.indexOf(v) === i);
}

let timeoutFunc;
let isTimeoutRunning = false;

class SecretDeveloperPage extends React.Component {
  state = {
    counter: 0,
    minimizeArr: [],
    keyNum: null,
  }

  timeoutClickHandler = () => {
    isTimeoutRunning = !isTimeoutRunning;
    if (isTimeoutRunning) {
      timeoutFunc = setInterval(() => {
        const { counter } = this.state;
        this.setState({ counter: counter + 1 });
      }, 1000);
    } else clearInterval(timeoutFunc);
  };

  keyPressHandler = evt => {
    const keyNum = (evt.which) ? evt.which : evt.keyCode;
    this.setState({ keyNum });
  }

  minimizerCheck = () => {
    this.minimizerCheckFunc = setInterval(() => {
      if (ifvisible.now('hidden')) this.setState({ minimizeArr: [...this.state.minimizeArr, ' hidden'] });
      else this.setState({ minimizeArr: [...this.state.minimizeArr, ' not-hidden'] });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.minimizerCheckFunc);
  }

  render() {
    const { secretPrefillSurvey, prefillTopics, prefillActiveRecs, prefillCurrentMethods, secretVisitAllMBC, locale } = this.props;

    return (
      <>
        <div className="dev-options">
          <button type="button" onClick={() => { secretPrefillSurvey(); prefillTopics(); prefillCurrentMethods(); prefillActiveRecs(); secretVisitAllMBC(); }} className="prefill-button" tabIndex="0">
            Prefill both MyBC and MyPath survey, ActiveRecs, MyPath topics, MyPath current methods, visit all MBC main navs
          </button>

          <div>
            <button type="button" onClick={this.timeoutClickHandler} className="timeout-button">Start timeout</button>
            <div>{`counter is now ${this.state.counter}`}</div>
            <button type="button" onKeyDown={this.keyPressHandler} className="timeout-button">Key Press Test</button>
            <div>{`keyNum is now ${this.state.keyNum}`}</div>
          </div>

          {/* <div>
            <button type="button" onClick={this.minimizerCheck} className="timeout-button">Start rendering minimizeArr</button>
            {
              this.state.minimizeArr.map(value => <span>{value}</span>)
            }
          </div> */}

          {/* <div>
            { JSON.stringify(localStorage) }
          </div> */}
        </div>
        <div className="nav-buttons-container" id="secret-nav-buttons-container">
          <NavButton str="splash" to="/" />
          {
            routes.map((route: string): React.Element<NavButton> => (
              <NavButton str={route} to={`/${locale}/${route}`} key={route} />
            ))
          }
        </div>
      </>
    );
  }
}

const mapDispatchToProps = { ...SurveyActions, ...SecretPrefillActions, ...NavActions };

export default connect(null, mapDispatchToProps)(SecretDeveloperPage);
