// @flow
import type { Action, VisitedStateMP } from '../lib/types';

const visitedReducerMP = (state: VisitedStateMP = [], action: Action): VisitedStateMP => {
  const newVisited = state.slice();
  const index = state.indexOf(action.value);

  switch (action.type) {
    case 'VISIT_MAIN_NAV_MP':
      if (index !== -1) { newVisited.splice(index, 1); }
      newVisited.push(action.value);
      return newVisited;
    default:
      return state;
  }
};

export default visitedReducerMP;
