// @flow

const initialState = { userInteraction: [], };

const analyticsReducer = (state: Object = initialState, action: string): Object => {
  const tstamp = new Date().toISOString();
  switch (action.type) {
    case 'SET_NOTES':
      return {
        userInteraction: [...state.userInteraction, {
          timestamp: tstamp,
          interaction: action.type,
          notes: action.notes
        }]
      };
    case 'TOGGLE_TOPIC':
      return {
        userInteraction: [...state.userInteraction, {
          timestamp: tstamp,
          interaction: action.type,
          topic: action.topic
        }]
      };
    case 'NAV_CLICK':
      return {
        userInteraction: [...state.userInteraction, {
          timestamp: tstamp,
          path: action.path,
          interaction: action.type,
          label: action.label
        }]
      };
    case 'MODAL_BUTTON_CLICK':
      return {
        userInteraction: [...state.userInteraction, {
          timestamp: tstamp,
          path: action.path,
          interaction: action.type,
          label: action.label
        }]
      };
    case 'SURVEY_CLICK':
      return {
        userInteraction: [...state.userInteraction, {
          timestamp: tstamp,
          path: action.path,
          interaction: action.type,
          question: action.question,
          answer: action.answer
        }]
      };
    default:
      //  console.log(` * * * * unlogged action ${action.type}`)
      return state;
  }
};

export default analyticsReducer;
