// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import StarLegend from './StarLegend';
import RecSection from './RecSection';
import { RegularTable, MobileTable } from './Tables';
import ConditionalTableTab from './ConditionalTableTab';
import { RegularRibbon, MobileFakeRibbon, RegularFakeRibbon } from '../../shared/ribbon/Ribbon';
import Rainbow from '../../shared/rainbow/Rainbow';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import { FakeNavButtonContainer } from '../sideEffects/SideEffects';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import type { PageProps } from '../../../lib/types';
import './CompareMethods.scss';

type CompareMethodsState = {
  method1s: [string | null],
  isModalOn: boolean,
  screenSize: string,
  activeTab: null | number,
};

type CompareMethodsProps = PageProps & {
  setModalInvisible: () => {},
  setModalVisible: () => {},
};

const bcNamesEnglish = [
  'sterilization',
  'hormonalIud',
  'copperIud',
  'implant',
  'shot',
  'ring',
  'patch',
  'pill',
  'diaphragm',
  'maleCondom',
  'femaleCondom'
];

class CompareMethods extends React.Component<CompareMethodsProps, CompareMethodsState> {
  _modal_heading: ?HTMLHeadingElement;
  _regular_tab0: ?HTMLTableCellElement;
  _regular_tab1: ?HTMLTableCellElement;
  _mobile_tab0: ?HTMLTableCellElement;
  _mobile_tab1: ?HTMLTableCellElement;

  constructor(props: CompareMethodsProps) {
    super(props);
    this.state = {
      isModalOn: false,
      activeTab: null,
      methods: [null, null]
    };
  }

  modalHeadingRef = (c: ?HTMLHeadingElement) => { this._modal_heading = c; }
  regularTabButton0Ref = (c: ?HTMLTableCellElement) => { this._regular_tab0 = c; }
  regularTabButton1Ref = (c: ?HTMLTableCellElement) => { this._regular_tab1 = c; }
  mobileTabButton0Ref = (c: ?HTMLTableCellElement) => { this._mobile_tab0 = c; }
  mobileTabButton1Ref = (c: ?HTMLTableCellElement) => { this._mobile_tab1 = c; }

  modalClickHandler = (callback: void => void) => {
    const { isModalOn } = this.state;
    const { setModalVisible, setModalInvisible } = this.props;
    if (isModalOn) setModalInvisible();
    else setModalVisible();
    this.setState({ isModalOn: !isModalOn }, callback);
  }

  selectMethodHandler = (activeTab: number) => {
    this.setState({ activeTab });
    this.modalClickHandler(() => { this._modal_heading.focus(); });
  }

  removeMethodHandler = (currentTab: number) => {
    const { methods } = this.state;
    const newMethodState = [...methods];
    newMethodState[currentTab] = null;
    this.setState({ methods: newMethodState });
  }

  iconClickHandler = (name: string) => {
    const { setModalInvisible } = this.props;
    const { activeTab, methods } = this.state;
    const newMethodState = [...methods];
    newMethodState[activeTab] = name;
    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    const currentTab = activeTab;

    setModalInvisible();
    this.setState({
      methods: newMethodState,
      isModalOn: false,
    }, () => {
      if (width < 600) this[`_mobile_tab${currentTab}`].focus();
      else this[`_regular_tab${currentTab}`].focus();
    });
  }

  render() {
    const { isModalOn, methods, activeTab } = this.state;
    const { content, navLinks, isAnyModalVisible, locale } = this.props;
    const iconNames = bcNamesEnglish.map((name: string): string => (content.methodNames[name]));
    const commonConditionalTabsProps = {
      isButtonInTabDisabled: isModalOn,
      selectMethodHandler: this.selectMethodHandler,
      removeMethodHandler: this.removeMethodHandler,
      methods,
      content,
      locale,
    };

    return (
      <>
        <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
        <p className="screen-reader-only">{content.deselect}</p>
        <RegularRibbon
          str={content.navigation.backToRecsText}
          to={navLinks[0]}
          isHidden={isModalOn}
        />
        <RegularTable
          methods={methods}
          content={content}
          isAriaHiddenOn={isModalOn}
        >
          <ConditionalTableTab
            index={0}
            thRef={this.regularTabButton0Ref}
            {...commonConditionalTabsProps}
          />
          <ConditionalTableTab
            index={1}
            thRef={this.regularTabButton1Ref}
            {...commonConditionalTabsProps}
          />
        </RegularTable>
        <MobileTable
          methods={methods}
          content={content}
          isAriaHiddenOn={isModalOn}
        >
          <ConditionalTableTab
            index={0}
            thRef={this.mobileTabButton0Ref}
            {...commonConditionalTabsProps}
          />
          <ConditionalTableTab
            index={1}
            thRef={this.mobileTabButton1Ref}
            {...commonConditionalTabsProps}
          />
        </MobileTable>
        <div className="nav-buttons-container">
          <MobileFakeRibbon
            str={content.navigation.backToRecsText}
            to={navLinks[0]}
            isHidden={isModalOn}
          />
          <RegularFakeRibbon
            str={content.navigation.backToRecsText}
            to={navLinks[0]}
            isHidden={isModalOn}
          />
        </div>
        <ModalWithOverlay
          closeBox={content.closeBox}
          isModalOn={isModalOn}
          id="compare-methods-select"
          clickHandler={() => {
            const currentTab = activeTab;
            this.modalClickHandler(() => {
              this[`_regular_tab${currentTab}`].focus();
            });
          }}
        >
          <Rainbow />
          <div className="modal-content">
            <Headings str={content.modal.headings} externalHeading1Ref={this.modalHeadingRef} id="compare-methods-select-modal-title" />
            <MarkdownBlock str={content.modal.introText} ariaHidden />
            <RecSection
              iconNamesEnglish={bcNamesEnglish}
              iconNames={iconNames}
              iconClickHandler={this.iconClickHandler}
              content={content}
            />
            <StarLegend
              headingText={content.modal.explanation_title}
              listArr={content.modal.explanations}
              ariaHidden
            />
            <FakeNavButtonContainer
              navigationContent={content.navigation}
              clickHandler={() => {
                const currentTab = activeTab;
                this.modalClickHandler(() => {
                  this[`_mobile_tab${currentTab}`].focus();
                });
              }}
            />
          </div>
        </ModalWithOverlay>
      </>
    );
  }
}

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(null, mapDispatchToProps)(CompareMethods);
