// @flow
import type { Action, VisitedStateMBC } from '../lib/types';

const visitedReducerMBC = (state: VisitedStateMBC = [], action: Action): VisitedStateMBC => {
  const newVisited = state.slice();
  const index = state.indexOf(action.value);

  switch (action.type) {
    case 'VISIT_MAIN_NAV':
      if (index !== -1) { newVisited.splice(index, 1); }
      newVisited.push(action.value);
      return newVisited;
    case 'PREVISIT_ALL_MBC':
      return [1, 2, 3, 4, 5, 6, 7];
    default:
      return state;
  }
};

export default visitedReducerMBC;
