const myPathModes = ['mypathGeneral', 'mypathVA', 'mypathVAStudy', 'mypath4you'];
export const isMyPath = myPathModes.includes(process.env.REACT_APP_MODE);

const myPathVAModes = ['mypathVA', 'mypathVAStudy'];
export const isMyPathVA = myPathVAModes.includes(process.env.REACT_APP_MODE);

export const isMyPathVAStudy = process.env.REACT_APP_MODE === 'mypathVAStudy';

const myPathGeneralModes = ['mypathGeneral', 'mypath4you'];
export const isMyPathGeneral = myPathGeneralModes.includes(process.env.REACT_APP_MODE);

export const isMyPath4you = process.env.REACT_APP_MODE === 'mypath4you';

const myBCModes = ['mybc', 'peripartum', 'peripartumUTA'];
export const isMyBC = myBCModes.includes(process.env.REACT_APP_MODE);

const myBCPeripartumModes = ['peripartum', 'peripartumUTA'];
export const isMyBCPeripartum = myBCPeripartumModes.includes(process.env.REACT_APP_MODE);

export const isMyBCPeripartumUTA = process.env.REACT_APP_MODE === 'peripartumUTA';

export const isSuperDev = process.env.REACT_APP_MODE === 'superDev';

export const isStudy = process.env.REACT_APP_STUDY === 'study';
