// @flow

import React, { useState, useRef, createRef } from 'react';
import { connect } from 'react-redux';
import InfoBox from '../infoBox/InfoBox';
import type { State } from '../../../lib/types';
import './SilhouetteInterface.scss';

type SilhouetteInterfaceProps = {
  iconNamesEnglish: Array<string>,
  iconNames: Array<string>,
  iconExplanations: Array<string>,
  config: Object,
  toggleOff: boolean,
  categoryName: string,
  content: { closeBox: string },
  isAnyModalVisible: boolean,
};

const iconStateGenerator = (activeNum: number | null, index: number): string => {
  if (activeNum === index) return 'color';
  if (activeNum === null) return 'normal';
  return 'dim';
};

const SilhouetteInterface = ({
  iconNamesEnglish,
  iconNames,
  iconExplanations,
  config,
  toggleOff = false,
  categoryName = '',
  content,
  isAnyModalVisible,
}: SilhouetteInterfaceProps): React.Element<'div'> => {
  const refs = useRef(new Array(iconNamesEnglish.length).fill(0).map((): {} => createRef()));

  const [activeNum, setActiveNum] = useState(null);

  const iconClickHandler = (num: number) => {
    if (activeNum === num) setActiveNum(null);
    else setActiveNum(num);
  };

  const closeClickHandler = () => {
    const prevNum = activeNum;
    setActiveNum(null);
    setTimeout(() => { refs.current[prevNum].current.focus(); }, 15);
  };

  return (
    <div className={`icon-column-container ${categoryName} ${toggleOff ? 'mobile-no-display' : ''}`}>
      {
        iconNamesEnglish.map((name: string, i: number): React.Element<'div'> => {
          const configArr = config[name] || [];
          const positionStr = `${configArr[0]} ${configArr[2]}`;
          const iconClassName = `${iconStateGenerator(activeNum, i)} icon-button`;

          return (
            <div
              className={`${name} icon-and-map-container`}
              key={name}
            >
              <button
                className={iconClassName}
                onClick={() => { iconClickHandler(i); }}
                aria-hidden={isAnyModalVisible}
                aria-label={`${name}-${categoryName}`}
                type="button"
                tabIndex="0"
                ref={refs.current[i]}
              >
                <div className="screen-reader-only">
                  { iconNames[i] }
                </div>
              </button>
              <div aria-hidden className={`map ${configArr[1]} ${configArr[2]} ${i === activeNum ? '' : 'no-display'}`} />
              <InfoBox
                isActive={i === activeNum}
                iconName={iconNames[i]}
                iconNameEnglish={name}
                iconExplanation={iconExplanations[i]}
                clickHandler={closeClickHandler}
                positionStr={positionStr}
                closeButton={content.closeBox}
                categoryName={categoryName}
              />
            </div>
          );
        })
      }
    </div>
  );
};

const mapStateToProps = ({ isAnyModalVisible }: State): Object => ({ isAnyModalVisible });

export default connect(mapStateToProps)(SilhouetteInterface);
