// @flow

import React from 'react';
import CheckMark from './CheckMark';

type Props = {
  caption: string,
  headings: Array<string>,
  rows: Array<Object>,
  noneText: string,
};

const Table = ({ rows, headings, caption, noneText = '' }: Props): React.Element<'table'> => {
  if (rows.length === 0) {
    return (
      <>
        <table className="item check-table empty-table">
          <caption key="caption">{caption}</caption>
          <thead>
            <tr className="empty">
              <td>» {noneText}</td>
            </tr>
          </thead>
        </table>
      </>
    );
  }

  return (
    <table className={`item columns-${headings.length} check-table`}>
      <caption key="caption">{caption}</caption>
      <thead>
        <tr>
          <th />
          {headings.map((heading: string): React.Element<'th'> => (
            <th scope="col" key={heading}>{heading}</th>
          ))}
        </tr>
      </thead>
      <tbody key="body">
        {
          rows.map((row: Object): React.Element<'tr'> => (
            <tr key={row.category}>
              <th scope="row">{row.category}</th>
              {headings.map((heading: string, index: number): React.Element<'td'> => (
                <td key={heading}>
                  <CheckMark checked={row.answer === index} uncheckedAsNull />
                </td>
              ))}
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default Table;
