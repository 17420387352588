// @flow

import showdown from 'showdown';
import Recs from './mybc_recs';

const showdownConverter = new showdown.Converter({ noHeaderId: true });
export default (text: string): string => showdownConverter.makeHtml(text);

export const classNameGenerator = (str: string | null): string => (
  typeof str === 'string' ? str.toLowerCase().replace(/ /gi, '-').replace(/\n/gi, '') : ''
);

export const isNextButtonHiddenFunc = (arr: Array<number>, surveyState: Array<number | null | Array<null | number>>): boolean => !arr.every(
  (index: number): boolean => {
    if (Array.isArray(surveyState[index])) return surveyState[index].every((choice: number | null): boolean => (choice !== null));
    return surveyState[index] !== null;
  }
);

export const isQuestionFullyAnsweredFuncMP = (value: null | number | [null, number]): boolean => {
  // console.log('isQuestionFullyAnsweredFuncMBC value', value)
  if (value === null) return false;
  if (Array.isArray(value) && !value.every((subvalue: number | null): boolean => subvalue !== null)) return false;
  return true;
};

export const isQuestionFullyAnsweredFuncMBC = (value: null | string | [null, string] | Object): boolean => {
  // console.log('isQuestionFullyAnsweredFuncMBC value', value)
  if (value === null) return false;
  if (typeof value === 'string') return true;
  if (Array.isArray(value)) return true;
  return (Object.values(value).every((value2: null | string): boolean => value2 !== null));
};

const keyMap = {
  'pill estrogen': 'pill',
  'pill progestin': 'progestinOnlyPill',
  implant: 'implant',
  patch: 'patch',
  'iud hormonal': 'hormonalIud',
  'iud copper': 'copperIud',
  diaphragm: 'diaphragm',
  shot: 'shot',
  sterilization: 'sterilization',
  ring: 'ring',
  'condom female': 'femaleCondom',
  'condom male': 'maleCondom',
};

const isMbcSurveyFullyAnsweredFunc = (input: Object): boolean => {
  let output = true;

  const recursiveHelper = (obj: Object) => {
    // write some specs and then replace this for..in loop
    for (const key in obj) {
      if ({}.hasOwnProperty.call(obj, key)) {
        if (obj[key] === null) output = false;
        else if (typeof obj[key] === 'object') recursiveHelper(obj[key]);
      }
    }
    // with this forEach block:
    // Object.keys(obj).forEach( key => {
    //   if (obj[key] === null) output = false;
    //   else if (typeof obj[key] === 'object') recursiveHelper(obj[key]);
    // })
  };

  recursiveHelper(input);
  return output;
};

export const RecsObjTransformFunc = (obj: Object): Object => {
  if (isMbcSurveyFullyAnsweredFunc(obj)) {
    const newObj = {};
    const transformedRecs = Recs.recs(obj);
    //  mybc_recs only returns an error if it errors out
    if (Array.isArray(transformedRecs)) throw transformedRecs;

    // console.log(transformedRecs)
    // write some specs and then replace this for..in loop
    for (const key in transformedRecs) {
      if ({}.hasOwnProperty.call(transformedRecs, key)) newObj[key] = transformedRecs[key].map((name: string): string => keyMap[name]);
    }
    // with this forEach block:
    // Object.keys(transformedRecs).forEach( key => {
    //   newObj[key] = transformedRecs[key].map((name: string): string => keyMap[name]);
    // })

    return newObj;
  }

  return {
    effectivenessAndTiming: [],
    frequencyAndAdmin: [],
    benefitAndSideEffect: [],
    healthConcerns: [],
  };
};

export const MarkdownSyntaxRemoverFunc = (str: string): string => str.replace(/[_\-*+]/g, '');

export const hyphenReplacerFunc = (str: string, locale: string): string => {
  const regex = /([0-9])-([0-9])/g;
  if (locale === 'es') return str.replace(regex, '$1 a $2');
  return str.replace(regex, '$1 to $2');
};

export const deviceOSstr = (): string => {
  let str = '';
  if (/Android/i.test(window.navigator.userAgent) && (/Mobile/i.test(window.navigator.userAgent))) str += ' android';
  if (/iP(od|hone|ad)/i.test(window.navigator.userAgent)) str += ' iOS';
  if (/Safari/i.test(window.navigator.userAgent) && !/Chrome/i.test(window.navigator.userAgent)) str += ' safari';
  if (/Firefox/i.test(window.navigator.userAgent)) str += ' firefox';
  if (!navigator.standalone) str += ' not-standalone';
  return str;
};

export const validEmail = (em: string): boolean => {
  const emailNameRegex = '[A-Z0-9_.%+-]+';
  const domainHeadRegex = '(?:[A-Z0-9-]+.)+';
  const domainTldRegex = '(?:[A-Z]{2,4}|museum|travel)';
  const emailRegex = new RegExp(`^${emailNameRegex}@${domainHeadRegex}${domainTldRegex}$`, 'i');

  return emailRegex.test(em);
};
