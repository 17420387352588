// @flow

import type { Action } from '../lib/types';

type userIdState = null | number;

const initialState = null;

const userIdReducer = (state: userIdState = initialState, action: Action): number => {
  switch (action.type) {
    case 'SET_USER_ID':
      return action.value;
    default:
      return state;
  }
};

export default userIdReducer;
