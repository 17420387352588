// @flow
import type { Action, VisitedArrState } from '../lib/types';

const initialState = new Array(3).fill(false);

const categoryVisitedReducer = (state: VisitedArrState = initialState, action: Action): Array<boolean> => {
  const newState = state.slice();

  switch (action.type) {
    case 'VISIT_SIDE_EFFECT_CATEGORY':
      newState[action.value] = true;
      return newState;
    default:
      return state;
  }
};

export default categoryVisitedReducer;
