// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './Survey4MBC.scss';

export const questionConfig4 = [
  {
    type: 'multi',
    questionKey: 'feelAboutMethods',
    subkeys: ['Sterilization', 'Hormonal IUD', 'Copper IUD', 'Implant', 'Shot', 'Ring', 'Patch', 'Pill', 'Diaphragm', 'Male Condom', 'Internal Condom'],
    choiceKeys: ['like', 'neutral', 'negative', 'never'],
  },
];

const Survey4MBC = ({
  locale, navLinks, surveyStateMBC, answerSurveyMultiMBC, isAnyModalVisible, prefillSurvey4,
  content: { headings, paragraphs, notes, navigation, survey4MbcQuestions }
}: SurveyMBCProps): React.Node<*> => {
  useEffect(() => {
    prefillSurvey4();
  }, []);

  const isNextButtonHidden = !questionConfig4.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));

  return (
    <>
      <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
      <div className="survey-questions-container">
        <MultiPartSurveyQuestion
          texts={survey4MbcQuestions[0].texts}
          choices={survey4MbcQuestions[0].choices}
          categories={survey4MbcQuestions[0].categories}
          ariaHidden={isAnyModalVisible}
          subkeys={['Sterilization', 'Hormonal IUD', 'Copper IUD', 'Implant', 'Shot', 'Ring', 'Patch', 'Pill', 'Diaphragm', 'Male Condom', 'Internal Condom']}
          questionKey="feelAboutMethods"
          choiceKeys={['like', 'neutral', 'negative', 'never']}
          answerSurveyMultiMBC={answerSurveyMultiMBC}
          currentChoice={surveyStateMBC.feelAboutMethods}
        />
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        notesContent={notes}
        navigationContent={navigation}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMBC }: State): Object => ({ surveyStateMBC });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey4MBC);
