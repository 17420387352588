// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './Survey3MBC.scss';

export const questionConfig3 = [
  {
    type: 'multi',
    questionKey: 'sideEffectBothers',
    subkeys: ['spotting', 'noPeriod', 'heavierPeriod', 'weightGain'],
    choiceKeys: ['positive', 'neutral', 'negativeWeak', 'negative']
  },
  {
    type: 'multi',
    questionKey: 'benefitEnthusiasms',
    subkeys: ['decreasedAcne', 'noPeriod', 'decreasedCramping', 'decreasedPeriod'],
    choiceKeys: ['negative', 'neutral', 'positiveWeak', 'positive']
  },
];

const Survey3MBC = (props: SurveyMBCProps): React.Element<*> => {
  const {
    locale, navLinks, surveyStateMBC, answerSurveyMultiMBC, isAnyModalVisible,
    content: { headings, paragraphs, notes, navigation, survey3MbcQuestions }
  } = props;
  const isNextButtonHidden = !questionConfig3.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));

  return (
    <>
      <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
      <div className="survey-questions-container">
        <MultiPartSurveyQuestion
          texts={survey3MbcQuestions[0].texts}
          choices={survey3MbcQuestions[0].choices}
          categories={survey3MbcQuestions[0].categories}
          ariaHidden={isAnyModalVisible}
          subkeys={['spotting', 'noPeriod', 'heavierPeriod', 'weightGain']}
          questionKey="sideEffectBothers"
          choiceKeys={['positive', 'neutral', 'negativeWeak', 'negative']}
          answerSurveyMultiMBC={answerSurveyMultiMBC}
          currentChoice={surveyStateMBC.sideEffectBothers}
        />
        {
          isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig3[0].questionKey]) && (
            <MultiPartSurveyQuestion
              texts={survey3MbcQuestions[1].texts}
              choices={survey3MbcQuestions[1].choices}
              categories={survey3MbcQuestions[1].categories}
              ariaHidden={isAnyModalVisible}
              subkeys={['decreasedAcne', 'noPeriod', 'decreasedCramping', 'decreasedPeriod']}
              questionKey="benefitEnthusiasms"
              choiceKeys={['negative', 'neutral', 'positiveWeak', 'positive']}
              answerSurveyMultiMBC={answerSurveyMultiMBC}
              currentChoice={surveyStateMBC.benefitEnthusiasms}
            />
          )
        }
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        notesContent={notes}
        navigationContent={navigation}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMBC }: State): Object => ({ surveyStateMBC });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey3MBC);
