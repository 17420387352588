// @flow

import type { Action } from '../lib/types';

export default {
  categoryVisit: (value: number): Action => ({
    type: 'VISIT_SIDE_EFFECT_CATEGORY',
    value,
  }),
};
