// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SingleRadioSurveyQuestion from '../../shared/survey/SingleRadioSurveyQuestion';
import { numOfQuestionToUnlockMainNavs } from '../../../reducers/surveyReducerMP';
import { isQuestionFullyAnsweredFuncMP } from '../../../lib/utils';
import type { SurveyMPProps, State, MapStateToProps, pageProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './CurrentMethodsSurvey.scss';

type BirthControlArraySurveyProps = pageProps & SurveyMPProps;

const BirthControlArraySurvey = (props: BirthControlArraySurveyProps): React.Element<*> => {
  const {
    locale, navLinks, surveyStateMP, answerSurveySingleMP, surveyLog, isAnyModalVisible,
    content: { headings, paragraphs, closingTexts, notes, navigation, mypathSurveyQuestions }
  } = props;
  const isNextButtonHidden = !isQuestionFullyAnsweredFuncMP(surveyStateMP[numOfQuestionToUnlockMainNavs]);

  const surveyClosingText = <div className="closing-text" aria-hidden={isAnyModalVisible}><MarkdownBlock str={closingTexts} /></div>;

  return (
    <>
      <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
      <div className="survey-questions-container">
        <SingleRadioSurveyQuestion
          questionIndex={7}
          texts={mypathSurveyQuestions[7].texts}
          choices={mypathSurveyQuestions[7].choices}
          ariaHidden={isAnyModalVisible}
          isVertical
          selectedChoiceIndex={surveyStateMP[numOfQuestionToUnlockMainNavs]}
          clickHandler={(choiceIndex: number) => {
            answerSurveySingleMP(7, choiceIndex);
            surveyLog(mypathSurveyQuestions[7].texts, window.location.pathname);
          }}
        />
      </div>
      {!isNextButtonHidden && surveyClosingText}
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        backPage={navLinks[0]}
        notesContent={notes}
        navigationContent={navigation}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({ surveyStateMP }: State): Object => ({ surveyStateMP });
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(BirthControlArraySurvey);
