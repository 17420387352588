// @flow

import * as React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { store } from '../../../lib/store';
import type { PageProps } from '../../../lib/types';

const StartMP = (props: PageProps): React.Element<*> => {
  const { friendly_id } = useParams();

  store.dispatch({
    type: 'SET_USER_ID',
    value: friendly_id,
  });

  return (
    <Redirect to="/splash" />
  );
};

export default StartMP;
