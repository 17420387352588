// @flow

import type { NotesState } from '../lib/types';

const initialState = {
  notes: '',
  showNotesInstructions: true,
};

const prefilledInitialState = {
  notes: 'test1\n\ntest2\n\ntest3',
  showNotesInstructions: false
};

const notesReducer = (state: NotesState = initialState, action: Object): NotesState => {
  switch (action.type) {
    case 'SET_NOTES':
      return { ...state, notes: action.notes };
    case 'SET_NOTES_INSTRUCTIONS_HIDDEN':
      return { ...state, showNotesInstructions: false };
    case 'SECRET_PREFILL_SURVEY':
      return prefilledInitialState;
    default:
      return state;
  }
};

export default notesReducer;
