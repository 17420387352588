// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import Animal from './Animal';
import NavButton from '../../shared/nav/NavButton';
import EmailModal from '../../shared/emailModal/EmailModal';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import { validEmail } from '../../../lib/utils';
import './StudyMP.scss';
import StudyIdImage from './StudyIdImage';

type studyMPProps = {
  content: { hiddenNavText: string, emailModal: [], closeBox: string, emailButton: string },
  userId: string,
  setModalInvisible: () => {},
  setModalVisible: () => {},
  locale: string,
};

type studyMPState = {
  isModalOn: boolean,
  isSendEmailButtonDisabled: boolean,
  isShowingEmailSuccessMessage: boolean,
  isShowingEmailErrorMessage: boolean,
  email: string,
};

class StudyMP extends React.Component<studyMPProps, studyMPState> {
  _emailButton: HTMLButtonElement;
  _modalBody: HTMLParagraphElement;

  constructor(props) {
    super(props);
    this.state = {
      isModalOn: false,
      isSendEmailButtonDisabled: true,
      isShowingEmailSuccessMessage: false,
      isShowingEmailErrorMessage: false,
      email: ''
    };
  }

  emailButtonRef = (c: HTMLButtonElement) => { this._emailButton = c; }
  modalBodyRef = (c: HTMLButtonElement) => { this._modalBody = c; }

  printClickHandler = () => {
    try {
      setTimeout(window.print, 1000);
    } catch (e1) {
      try {
        document.execCommand('print', false, null);
      } catch (e2) {
        alert(`neither Javascript print function worked; ${e1}; ${e2}`);
      }
    }
  }

  emailInputHandler = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    this.setState({
      email: event.target.value,
      isSendEmailButtonDisabled: !validEmail(event.target.value)
    });
  };

  modalClickHandler = () => {
    const { setModalInvisible, setModalVisible } = this.props;
    const { isModalOn } = this.state;
    if (isModalOn) {
      setModalInvisible();
      this.setState({
        isModalOn: false,
        isShowingEmailSuccessMessage: false,
        isShowingEmailErrorMessage: false
      }, () => { this._emailButton.focus(); });
    } else {
      setModalVisible();
      this.setState({
        isModalOn: true,
        isShowingEmailSuccessMessage: false,
        isShowingEmailErrorMessage: false
      }, () => { this._modalBody.focus(); });
    }
  };

  sendEmailId = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    const { locale, userId } = this.props;
    const { email } = this.state;
    this.setState({
      isSendEmailButtonDisabled: true,
      isShowingEmailSuccessMessage: false,
      isShowingEmailErrorMessage: false
    });

    fetch('/emailer/send_study_id', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({ userId, email, locale })
    })
      .then((response: string): Object => response.json())
      .then(() => {
        this.setState({
          isSendEmailButtonDisabled: false,
          isShowingEmailSuccessMessage: true
        });
      })
      .catch((err: string) => {
        this.setState({
          isSendEmailButtonDisabled: false,
          isShowingEmailErrorMessage: true
        });

        console.warn(err);
      });
  };

  render() {
    const { content, userId, } = this.props;

    const {
      isModalOn,
      isSendEmailButtonDisabled,
      isShowingEmailSuccessMessage,
      isShowingEmailErrorMessage,
      email,
      isAnyModalVisible,
    } = this.state;

    const [color, animal] = userId.split('-');

    return (
      <>
        <main>
          <StudyIdImage userId={userId} />
          <p>
            We are assigning you a unique ID for the study you’re enrolled in.
          </p>
          <p>
            Please save this ID to share with the study staff (by emailing it to yourself or saving a screenshot of this page).
          </p>
        </main>

        <div className="nav-buttons-container">
          <button
            className="nav-button email"
            type="button"
            aira-label={content.emailButton}
            onClick={this.modalClickHandler}
            ref={this.emailButtonRef}
            tabIndex={isAnyModalVisible ? null : '0'}
          >
            <p>Email ID</p>
          </button>

          <NavButton
            str="continue"
            hiddenText={content.hiddenNavText}
            to="/en/intro_mp"
            tabIndex="0"
          />
        </div>
        <EmailModal
          closeModalHandler={this.modalClickHandler}
          isModalOn={isModalOn}
          submitHandler={this.sendEmailId}
          emailInputHandler={this.emailInputHandler}
          email={email}
          isShowingEmailErrorMessage={isShowingEmailErrorMessage}
          isShowingEmailSuccessMessage={isShowingEmailSuccessMessage}
          emailModalText={content.emailModal}
          isSendEmailButtonDisabled={isSendEmailButtonDisabled}
          closeBox={content.closeBox}
          modalHeadingRef={this.modalBodyRef}
        />
      </>
    );
  }
}

const mapStateToProps = ({ userId }: {}): {} => ({ userId });

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(mapStateToProps, mapDispatchToProps)(StudyMP);
