// @flow

import * as React from 'react';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PageProps } from '../../../lib/types';

const FertilityAwareness = ({ content, isAnyModalVisible }: PageProps): React.Element<*> => (
  <>
    <Headings isAutoFocusing str={content.headings} ariaHidden={isAnyModalVisible} />
    <div className="flex-group">
      <div aria-hidden className="ellipse-space" />
      <MarkdownBlock str={content.paragraphs} ariaHidden={isAnyModalVisible} />
    </div>
  </>
);

export default FertilityAwareness;
