// @flow
import type { Action, AfterBabyTopicsState } from '../lib/types';

const initialState = {};

export default (state: AfterBabyTopicsState = initialState, action: Action): AfterBabyTopicsState => {
  const newState = { ...state };

  switch (action.type) {
    case 'VISIT_AFTER_BABY_TOPIC':
      newState[action.item] = true;
      return newState;
    default:
      return state;
  }
};
