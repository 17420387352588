// @flow

import React from 'react';

type Props = {
  methods: Array<string>,
  title: string,
  noMethods: string,
  methodNames: Object,
};

const Methods = ({ methods, methodNames, title, noMethods }: Props): React.Element<'div'> => {
  let body;
  if (methods.length === 0) {
    body = (<p className="empty">» {noMethods}</p>);
  } else {
    body = (
      <ul>
        {methods.map((method: string): React.Element<'li'> => (
          <li key={method} className={method}>
            {methodNames[method]}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="methods">
      <h2>{title}</h2>
      {body}
    </div>
  );
};

export default Methods;
