// @flow

import React from 'react';

type Props = {
  topics: Array<string>,
  title: string,
  emptyText: string,
};

const Topics = ({ topics, title, emptyText }: Props): React.Element<'p'> => {
  let body = null;
  if (topics.length === 0) {
    body = (
      <p className="empty">{emptyText}</p>
    );
  } else {
    const groupedTopics = [0, 1].map((column: number): Array<string> => topics.filter((t: string, i: number): boolean => i % 2 === column));
    body = groupedTopics.map((column: Array<string>): React.Element<'ul'> => (
      <ul key={column.join('')}>
        {column.map((topic: string): React.Element<'li'> => (
          <li key={topic}>
            <p>
              {topic}
            </p>
          </li>
        ))}
      </ul>
    ));
  }

  return (
    <div className="item topics">
      <h2>{title}</h2>
      {body}
    </div>
  );
};

export default Topics;
