// @flow

import * as React from 'react';
import CategoryBar from './CategoryBar';
import SilhouetteInterface from '../../shared/silhouetteInterface/SilhouetteInterface';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import NavContainer from '../../shared/nav/NavContainer';
import Note from '../../shared/note/Note';
import markdown from '../../../lib/utils';
import type { SideEffectsProps } from '../../../lib/types';

import './SideEffects.scss';
import '../../shared/HowToPage.scss';

type bcEffectObjType = {
  category: string,
  introText: string,
  effectsTitle: Array<string>,
  explanations: Array<string>,
  setModalInvisible: () => void,
};

type SideEffectState = {
  categoryNum: number | null,
};

type FakeNavButtonProps = {
  clickHandler: number => void,
  children: React.Node,
  navigationContent: Object,
};

type SideEffectsNavContainerProps = SideEffectState & {
  locale: string,
  content: Object,
  navLinks: Array<string>,
  fakeClickHandler: number => void,
};

const bcEffectObjEnglish = [
  {
    iconNames: [
      'decreasedAcne',
      'cancerPrevention',
      'decreasedCramping',
      'noPeriod',
      'decreasedPeriod'
    ]
  },
  {
    iconNames: [
      'depression',
      'decreasedInterestInSex',
      'cramping',
      'weightGain',
      'noPeriod',
      'spotting',
      'heavierPeriod'
    ]
  },
  {
    iconNames: [
      'depression',
      'nausea',
      'breastTenderness',
      'weightGain'
    ]
  }
];

const sideEffectCategories = ['benefits', 'side-effects', 'other-considerations'];

const SideEffectsConfig = [
  {
    decreasedAcne: ['up', 'bottom', 'left'],
    cancerPrevention: ['up', 'top', 'left'],
    decreasedCramping: ['down', 'bottom', 'left'],
    noPeriod: ['up', 'top', 'right'],
    decreasedPeriod: ['down', 'bottom', 'right'],
  },
  {
    depression: ['up', 'bottom', 'left'],
    decreasedInterestInSex: ['up', 'bottom', 'left'],
    cramping: ['down', 'bottom', 'left'],
    weightGain: ['down', 'bottom', 'left'],
    noPeriod: ['up', 'top', 'right'],
    spotting: ['down', 'top', 'right'],
    heavierPeriod: ['down', 'bottom', 'right'],
  },
  {
    depression: ['up', 'bottom', 'left'],
    nausea: ['down', 'bottom', 'left'],
    breastTenderness: ['up', 'bottom', 'right'],
    weightGain: ['down', 'bottom', 'right'],
  },
];

export const FakeNavButtonContainer = ({ clickHandler, children = null, navigationContent }: FakeNavButtonProps): React.Element<'div'> => (
  <div className="nav-buttons-container fake">
    <button onClick={clickHandler} type="button" className="nav-button">{navigationContent.backText}</button>
    {children}
  </div>
);

const SideEffectsNavContainer = ({ content, locale, fakeClickHandler, categoryNum, navLinks }: SideEffectsNavContainerProps): React.Element<'div'> => {
  const mobileNavigation = (
    <FakeNavButtonContainer
      clickHandler={fakeClickHandler}
      navigationContent={content.navigation}
    >
      <Note content={content.notes} />
    </FakeNavButtonContainer>
  );

  const navigation = (
    <NavContainer
      nextPage={navLinks[1]}
      backPage={navLinks[0]}
      locale={locale}
      notesContent={content.notes}
      navigationContent={content.navigation}
    />
  );

  return (
    <div className="side-effects-navigation-container">
      <div className="regular">{navigation}</div>
      <div className="mobile">
        {categoryNum !== null ? mobileNavigation : navigation}
      </div>
    </div>
  );
};

class SideEffects extends React.Component<SideEffectsProps, SideEffectState> {
  _mobileHeading: HTMLHeadingElement;

  constructor(props) {
    super(props);
    this.state = {
      categoryNum: null,
      prevCategoryNum: null,
    };
  }

  // componentDidMount() { this.props.setModalInvisible(); }

  mobileHeadingRef = (c: ?HTMLHeadingElement) => { this._mobileHeading = c; }

  categoryButtonHandler = (categoryNum: number | null) => {
    this.setState({ categoryNum }, () => {
      if (categoryNum !== null) this._mobileHeading.focus();
    });
  }

  render() {
    const { categoryNum, prevCategoryNum } = this.state;
    const {
      content,
      navLinks,
      locale,
      isAnyModalVisible,
      content: {
        bcEffectObj,
        headings,
        paragraphs,
        closeBox,
        navigation: { newModal }
      },
    } = this.props;

    const categoryNames = bcEffectObj.map((obj: bcEffectObjType): string => obj.category);
    const introText = categoryNum === null ? '' : bcEffectObj[categoryNum].introText;
    const mobileCategoryPageHeading = categoryNum === null ? '' : `# ${categoryNames[categoryNum]}`;
    const overlayClassName = categoryNum === null ? '' : 'no-display';
    const categoryPageClassName = categoryNum === null ? 'no-display' : 'mobile-category-page-top';

    const fakeClickHandler = () => {
      this.setState({ prevCategoryNum: categoryNum });
      this.categoryButtonHandler(null);
    };

    return (
      <>
        <div className="flexbox-group regular">
          <Headings isAutoFocusing str={headings} ariaHidden={isAnyModalVisible} />
          <MarkdownBlock str={paragraphs} ariaHidden={isAnyModalVisible} />
          <div className="category-bar-container">
            <CategoryBar
              categoryNames={categoryNames}
              clickHandler={this.categoryButtonHandler}
              categoryNum={categoryNum}
            />
            {
              categoryNum !== null && categoryNames.map((str: string, i: number): React.Element<'div'> => (
                <div
                  key={bcEffectObj[i].introText}
                  role="tabpanel"
                  id="category-intro-text"
                  hidden={i === categoryNum ? null : 'hidden'}
                  tabIndex={isAnyModalVisible ? null : '0'}
                  dangerouslySetInnerHTML={{ __html: markdown(bcEffectObj[i].introText) }}
                />
              ))
            }
          </div>
        </div>

        <div className={`mobile mobile-flex-group mobile-intro-overlay ${overlayClassName}`}>
          <Headings str={headings} isAutoFocusing />
          <MarkdownBlock str={paragraphs} />
          {
            categoryNum === null && (
              <CategoryBar
                categoryNames={categoryNames}
                clickHandler={this.categoryButtonHandler}
                categoryNum={categoryNum}
                prevCategoryNum={prevCategoryNum}
                mobileButtonLabel={newModal}
                isMobile
              />
            )
          }
        </div>
        <div className={`mobile ${categoryPageClassName}`}>
          <Headings str={mobileCategoryPageHeading} externalHeading1Ref={this.mobileHeadingRef} />
          <MarkdownBlock str={introText} />
        </div>
        {
          categoryNum === 0 && (
            <SilhouetteInterface
              config={SideEffectsConfig[0]}
              iconNamesEnglish={bcEffectObjEnglish[0].iconNames}
              categoryName={sideEffectCategories[0]}
              iconExplanations={bcEffectObj[0].explanations}
              toggleOff={categoryNum !== 0}
              iconNames={bcEffectObjEnglish[0].iconNames.map((str: string): string => (content.sideEffectNames[str]))}
              content={{ closeBox }}
            />
          )
        }
        {
          categoryNum === 1 && (
            <SilhouetteInterface
              config={SideEffectsConfig[1]}
              iconNamesEnglish={bcEffectObjEnglish[1].iconNames}
              categoryName={sideEffectCategories[1]}
              iconExplanations={bcEffectObj[1].explanations}
              toggleOff={categoryNum !== 1}
              iconNames={bcEffectObjEnglish[1].iconNames.map((str: string): string => (content.sideEffectNames[str]))}
              content={{ closeBox }}
            />
          )
        }
        {
          categoryNum === 2 && (
            <SilhouetteInterface
              config={SideEffectsConfig[2]}
              iconNamesEnglish={bcEffectObjEnglish[2].iconNames}
              categoryName={sideEffectCategories[2]}
              iconExplanations={bcEffectObj[2].explanations}
              toggleOff={categoryNum !== 2}
              iconNames={bcEffectObjEnglish[2].iconNames.map((str: string): string => (content.sideEffectNames[str]))}
              content={{ closeBox }}
            />
          )
        }

        <SideEffectsNavContainer
          locale={locale}
          content={content}
          fakeClickHandler={fakeClickHandler}
          categoryNum={categoryNum}
          navLinks={navLinks}
        />
      </>
    );
  }
}

export default SideEffects;
