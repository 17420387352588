// @flow

type RecAction = {
  type: string,
  item: string,
};

export default {
  toggleActiveRec: (item: string): RecAction => ({
    type: 'TOGGLE_ACTIVE_REC',
    item,
  }),
};
