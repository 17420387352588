// @flow

import React from 'react';
import { QuestionText } from '../textComponents/TextComponents';
import MultiPartCategory from './MultiPartCategory';

type MultiPartSurveyQuestionProps = {
  texts: Array<string>,
  choices: Array<string>,
  choiceKeys: Array<string>,
  questionKey: string,
  currentChoice: null | Array<number | null>,
  answerSurveyMultiMBC: (string, string, string) => void,
  subkeys: Array<string>,
  categories: Array<string>,
  ariaHidden: boolean,
};

const MultiPartSurveyQuestion = ({
  texts,
  choices,
  ariaHidden,
  categories,
  subkeys,
  questionKey,
  choiceKeys,
  answerSurveyMultiMBC,
  currentChoice,
}: MultiPartSurveyQuestionProps): React.Element<'div'> => (
  <form aria-hidden={ariaHidden}>
    <div className="survey-question-container multi" role="group" aria-labelledby={`question-${questionKey}`}>
      <QuestionText textArr={texts} id={`question-${questionKey}`} />
      <div className="survey-categories-container" >
        {
          categories.map((category: string, categoryIndex: number): React.Element<'div'> => (
            // created <MultiPartCategory> specifically to leverage useEffect hooks since they cant be in callbacks
            <MultiPartCategory
              choices={choices}
              currentChoiceIndex={currentChoice ? choiceKeys.indexOf(currentChoice[subkeys[categoryIndex]]) : null}
              category={category}
              key={category}
              ariaHidden={ariaHidden}
              clickHandler={(choiceIndex: number) => {
                answerSurveyMultiMBC(questionKey, subkeys[categoryIndex], choiceKeys[choiceIndex]);
              }}
            />
          ))
        }
      </div>
    </div>
  </form>
);

export default MultiPartSurveyQuestion;
