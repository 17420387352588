// @flow

import type { Action } from '../lib/types';

const initialState = true;

const prefilledInitialState = true;

const topicModalReducer = (state: boolean = initialState, action: Action): boolean => {
  switch (action.type) {
    case 'HIDE_TOPIC_MODAL':
      return false;
    case 'SECRET_PREFILL_TOPICS':
      return prefilledInitialState;
    default:
      return state;
  }
};

export default topicModalReducer;
