// @flow
import type { Action } from '../lib/types';

const initialState = [];
const prefilledInitialState = ['shot', 'implant', 'diaphragm'];

export default (state: Array<string> = initialState, action: Action): Array<string> => {
  const newState = state.slice();
  let indexOfItem;

  switch (action.type) {
    case 'TOGGLE_ACTIVE_REC':
      indexOfItem = newState.indexOf(action.item);
      if (indexOfItem !== -1) newState.splice(indexOfItem, 1);
      else newState.push(action.item);

      return newState;
    case 'SECRET_PREFILL_ACTIVE_RECS':
      return prefilledInitialState;
    default:
      return state;
  }
};
