// @flow

import React from 'react';
import CheckMark from './CheckMark';

type Props = {
  title: string,
  rows: Array<Object>,
};

const ImportantThings = (props: Props): React.Element<'div'> => {
  const {
    title,
    rows
  } = props;

  return (
    <div className="item important questions">
      <h2>{title}</h2>
      <ul>
        {
          rows.map(({ category, answer }: Object): React.Element<'li'> => (
            <li key={category}>
              <div className="question">
                {category}
              </div>
              <div className="answer">
                <CheckMark checked={answer} />
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default ImportantThings;
