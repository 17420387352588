// @flow

import * as React from 'react';
import NavButton from '../../shared/nav/NavButton';
import type { PageProps } from '../../../lib/types';
import FooterMP from '../../shared/footers/FooterMP';
import MyReproductiveHealthJourney from './MyReproductiveHealthJourney';
import './SplashMP.scss';
import { isMyPathVAStudy } from '../../../lib/appMode';

const SplashMP = ({ content }: PageProps): React.ChildrenArray<*> => (
  <>
    <div className="spacer-1" />
    <div className="flexbox-group">
      <MyReproductiveHealthJourney />
      <div className="nav-buttons-container">
        <NavButton
          str={content.MyPathSplash}
          hiddenText={content.hiddenNavText}
          to={isMyPathVAStudy ? '/en/study_mp' : '/en/intro_mp'}
          tabIndex="0"
        />
      </div>
    </div>
    <div className="spacer-2" />
    <FooterMP id="" />
  </>
);

export default SplashMP;
