// @flow

import * as React from 'react';
import ModalWithOverlay from './modal/ModalWithOverlay';
import './InactivityModal.scss';

type InactivityModalProps = {
  clickHandler: * => void,
  handleTurningOffModal: * => void,
};
class InactivityModal extends React.Component<InactivityModalProps> {
  constructor(props) {
    super(props);
    this.state = { secondsLeft: 60 };
  }

  componentDidMount() {
    this.intervalFunc = setInterval(() => {
      const { secondsLeft } = this.state;
      const { handleTurningOffModal } = this.props;
      if (window.location.pathname === '/') {
        handleTurningOffModal();
      } else if (secondsLeft === 0) window.location.pathname = '/';
      else this.setState({ secondsLeft: secondsLeft - 1 });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalFunc);
  }

  render() {
    const { secondsLeft } = this.state;
    const { clickHandler } = this.props;

    return (
      <ModalWithOverlay
        showCloseButton
        isModalOn
        id="inactivity-modal"
        closeBox="close"
        clickHandler={clickHandler}
      >
        <div>
          {`You've been inactive for a while.  This app will reset in ${secondsLeft} seconds`}
        </div>
      </ModalWithOverlay>
    );
  }
}

export default InactivityModal;
