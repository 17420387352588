// @flow

import React, { useRef, createRef, useEffect } from 'react';

type Props = {
  choices: [string],
  currentChoiceIndex: number | null,
  category: string,
  ariaHidden: boolean,
  clickHandler: (number) => void,
};

const MultiPartCategory = ({
  choices,
  currentChoiceIndex,
  category,
  ariaHidden,
  clickHandler,
}: Props): React.Element<'div'> => {
  const refs = useRef(new Array(choices.length).fill(0).map((): {} => createRef()));

  useEffect(() => {
    if (currentChoiceIndex !== -1) refs.current[currentChoiceIndex].current.focus();
  }, [currentChoiceIndex]);

  return (
    <div className="survey-category-container" role="radiogroup" aria-labelledby={`category-${category}`}>
      <h3 className="category-name" id={`category-${category}`}>{category}</h3>
      {
        choices.map((choice: string, choiceIndex: number): React.Element<'button'> => {
          let active = false;
          if (currentChoiceIndex === choiceIndex) {
            active = true;
          }

          const tabIndex = ariaHidden ? null : '0';

          const keyPressHandler = (evt: React.SyntheticEvent) => {
            const keyNum = (evt.which) ? evt.which : evt.keyCode;

            switch (keyNum) {
              case 40: // down (same as right)
                if (choiceIndex === choices.length - 1) clickHandler(0);
                else clickHandler(choiceIndex + 1);
                break;
              case 39: // right (same as down)
                if (choiceIndex === choices.length - 1) clickHandler(0);
                else clickHandler(choiceIndex + 1);
                break;
              case 38: // up (same as left)
                if (choiceIndex === 0) clickHandler(choices.length - 1);
                else clickHandler(choiceIndex - 1);
                break;
              case 37: // left (same as up)
                if (choiceIndex === 0) clickHandler(choices.length - 1);
                else clickHandler(choiceIndex - 1);
                break;
              case 32: // space (same as enter)
                clickHandler(choiceIndex);
                break;
              case 13: // enter (same as enter)
                clickHandler(choiceIndex);
                break;
              default:
                break;
            }
          };

          return (
            <div
              role="radio"
              aria-label={choice}
              className={`survey-choice ${active ? 'active' : ''}`}
              aria-checked={active}
              key={choice}
              tabIndex={tabIndex}
              onClick={() => { clickHandler(choiceIndex); }}
              onKeyDown={keyPressHandler}
              ref={refs.current[choiceIndex]}
            >
              {choice}
            </div>
          );
        })
      }
    </div>
  );
};

export default MultiPartCategory;
