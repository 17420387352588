// @flow

type State = boolean;

const initialState = false;

const showRecsReducer = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case 'SHOW_RECS':
      return true;
    default:
      return state;
  }
};

export default showRecsReducer;
