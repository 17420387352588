// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import NavButton from '../../shared/nav/NavButton';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import type { PageProps, State, MapStateToProps, VisitedStateMBC } from '../../../lib/types';
import { isMyPath } from '../../../lib/appMode';
import '../../shared/main/MainPage.scss';
import './Main.scss';

type MainPropsMBC = PageProps & {
  visitedState: VisitedStateMBC,
  isAnyModalVisible: boolean,
  setModalInvisible: () => void,
};

const NavStyle = { backgroundColor: '#006477' };

class MainMBC extends React.Component<MainPropsMBC> {
  constructor(props: MainPropsMBC) {
    super(props);

    this.headerRef = React.createRef();
    this.navRefs = props.content.navs.map((): Object => React.createRef());
  }

  componentDidMount() {
    const { visitedState, setModalInvisible } = this.props;
    if (visitedState.length === 0 && this.headerRef.currents) {
      this.headerRef.current.focus();
    } else if (this.navRefs[visitedState[visitedState.length - 1]]) {
      this.navRefs[visitedState[visitedState.length - 1]].current.focus();
    }
    setModalInvisible();
  }

  render() {
    const {
      locale,
      visitedState,
      navLinks,
      content,
      isAnyModalVisible
    } = this.props;
    const showLastNav: boolean = visitedState.length >= content.navs.length - 1;

    const wigglePosition = content.navs.findIndex(
      (n: string, i: number): boolean => !visitedState.includes(i)
    );

    return (
      <>
        <Headings
          str={content.headings}
          ariaHidden={isAnyModalVisible}
          externalHeading1Ref={this.headerRef}
        />
        <MarkdownBlock
          str={content.paragraphs}
          ariaHidden={isAnyModalVisible}
        />
        <div className="main-nav-buttons-container" role="menu">
          {content.navs.map(
            (
              nav: string,
              i: number,
              arr: Array<string>
            ): React$Element<*> | null => {
              if (i === arr.length - 1 && !showLastNav) return null;
              return (
                <NavButton
                  str={nav}
                  to={`/${locale}/${navLinks[i]}`}
                  key={nav}
                  isMainNavMBC
                  index={i}
                  isVisited={visitedState.includes(i)}
                  isHidden={isAnyModalVisible}
                  isWiggling={i === wigglePosition}
                  linkRef={this.navRefs[i]}
                  style={isMyPath ? NavStyle : null}
                />
              );
            }
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<State> = ({ visitedStateMBC }: State): Object => ({ visitedState: visitedStateMBC });
const mapDispatchToProps = isAnyModalVisibleActions;
export default connect(mapStateToProps, mapDispatchToProps)(MainMBC);
