// @flow

type State = { visible: boolean };

const initialState = false;

const isAnyModalVisibleReducer = (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case 'SET_IS_ANY_MODAL_VISIBLE':
      return action.visibility;
    default:
      return state;
  }
};

export default isAnyModalVisibleReducer;
