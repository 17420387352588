// @flow

import * as React from 'react';
import { QuestionText } from '../textComponents/TextComponents';

type SingleCheckboxSurveyQuestionProps = CommonSurveyQuestionProps & {
  texts: Array<string>,
  choices: Array<string>,
  ariaHidden: boolean,
  isVertical: boolean,
  selectedChoiceArr: [boolean],
  clickHandler: (number) => void,
};

const SingleCheckboxSurveyQuestion = ({
  texts,
  ariaHidden,
  choices,
  questionIndex,
  clickHandler,
  selectedChoiceArr,
  isVertical = false,
}: SingleCheckboxSurveyQuestionProps): React.Element<'div'> => (
  <form aria-hidden={ariaHidden}>
    <div className={`survey-question-container ${isVertical ? 'vertical' : ''}`} role="group" aria-labelledby={`question-${questionIndex}`}>
      <QuestionText textArr={texts} id={`question-${questionIndex}`} />
      <div className="survey-choices-container">
        {
          choices.map((choice: string, choiceIndex: number): React.Element<'label'> => {
            const key = `question-${questionIndex}-option-${choiceIndex}`;

            const keyPressHandler = (evt: React.SyntheticEvent<HTMLSpanElement>) => {
              const keyNum = (evt.which) ? evt.which : evt.keyCode;

              switch (keyNum) {
                case 13: // enter
                  clickHandler(choiceIndex);
                  break;
                case 32: // space
                  clickHandler(choiceIndex);
                  break;
                default:
                  break;
              }
            };

            const isSelected = selectedChoiceArr[choiceIndex];

            return (
              <button
                type="button"
                role="checkbox"
                className={`survey-choice ${isSelected ? 'active' : ''}`}
                aria-checked={isSelected}
                name={`question-${questionIndex}`}
                key={key}
                tabIndex={ariaHidden ? null : '0'}
                onClick={() => { clickHandler(choiceIndex); }}
                onKeyDown={keyPressHandler}
              >
                {choice}
              </button>
            );
          })
        }
      </div>
    </div>
  </form>
);

export default SingleCheckboxSurveyQuestion;
