// @flow

import * as React from 'react';

type ConditionalTableTabProps = {
  index: number,
  methods: Array<number | null>,
  content: Object,
  isButtonInTabDisabled: boolean,
  removeMethodHandler: number => void,
  selectMethodHandler: number => void,
  thRef: * => void,
};

const ConditionalTableTab = ({ content, methods, index, removeMethodHandler, selectMethodHandler, isButtonInTabDisabled, thRef }: ConditionalTableTabProps): React.Element<'th'> => {
  const { tabs } = content;
  const extraTabClass = methods[index] ? 'active' : 'inactive';
  const extraParagraphClass = methods[index];
  const iconName = content.methodNames[methods[index]];

  return (
    <th className={`table-tab ${extraTabClass}`} scope="col">
      {
        methods[index] ? (
          <button
            aria-label={iconName}
            type="button"
            className="active-tab-button"
            onClick={() => { removeMethodHandler(index); }}
          >
            <p
              className={`active-tab-paragraph focus-start ${extraParagraphClass}`}
              tabIndex="-1"
              ref={thRef}
            >
              {iconName}
            </p>
          </button>
        ) : (
          <button
            className="select-button"
            type="button"
            tabIndex="0"
            ref={thRef}
            onClick={() => { selectMethodHandler(index); }}
            disabled={isButtonInTabDisabled}
          >
            {tabs[index]}
          </button>
        )
      }
    </th>
  );
};

export default ConditionalTableTab;
