// @flow

import React from 'react';

import { ReactComponent as GreenCheck } from './green-check.svg';

type Props = {
  checked: boolean,
  uncheckedAsNull: boolean,
};

const CheckMarkOrHiddenSpan = ({ checked, uncheckedAsNull }: Props): React.Element<'img'> | React.Element<'span'> => {
  if (checked) {
    return (
      <>
        <span className="screen-reader-only">selected</span>
        <GreenCheck />
      </>
    );
  }
  if (uncheckedAsNull) return (null);
  return <span className="hidden">Not selected</span>;
};

export default CheckMarkOrHiddenSpan;
